import React, { useEffect, useState } from 'react';
import { Spinner, Container, Col, Row, Button } from "react-bootstrap";
import { Link } from 'react-router-dom';

//CSS
import "./style.scss";

//dummy images 
import dummyProduct from '../../components/shared/assets/images/dummyProduct.jpg'

//Images
import BannerImage from "../shared/assets/images/bannerImg.png";
import DummyLogo from "../shared/assets/images/dummyLogo.png";
import Logo from "../shared/assets/images/foodBuddyLogo.svg";
import ChefImg from "../shared/assets/images/chefImg.png";
import ratingImg from "../shared/assets/images/starImg.png";
import qualityImg from "../shared/assets/images/qualityImg.png";
import timingImg from "../shared/assets/images/timingIcon.png";
import PlateImg from "../shared/assets/images/plateImg.png";

//parther program images 
import orderAppsImg1 from "../shared/assets/template/image-1.png";
import orderAppsImg2 from "../shared/assets/template/image-2.png";
import orderAppsImg3 from "../shared/assets/template/image-3.png";
import orderAppsImg4 from "../shared/assets/template/image-4.png";

//Cards
import card1 from "./images/card-img1.png";
import card2 from "./images/card-img2.png";
import card3 from "./images/card-img3.png";

//Special offers Images
import slideImg1 from "./images/slide-img1.png";
import slideImg2 from "./images/slide-img2.png";
import slideImg3 from "./images/slide-img3.png";
import slideImg4 from "./images/slide-img4.png";

//Explore Us Images
import exploreSlideImg1 from "./images/exploreSlide-img1.png";
import exploreSlideImg2 from "./images/exploreSlide-img2.png";
import exploreSlideImg3 from "./images/exploreSlide-img3.png";
import exploreSlideImg4 from "./images/exploreSlide-img4.png";
import exploreSlideImg5 from "./images/exploreSlide-img5.png";
import exploreSlideImg6 from "./images/exploreSlide-img6.png";
import exploreSlideImg7 from "./images/exploreSlide-img7.png";
import exploreSlideImg8 from "./images/exploreSlide-img8.png";

//Awards And Batches Images
import awardsAndBatches1 from "./images/awardsSlide-img1.png";
import awardsAndBatches2 from "./images/awardsSlide-img2.png";
import awardsAndBatches3 from "./images/awardsSlide-img3.png";
import awardsAndBatches4 from "./images/awardsSlide-img4.png";
import awardsAndBatches5 from "./images/awardsSlide-img5.png";
import awardsAndBatches6 from "./images/awardsSlide-img6.png";

//Authentic Mouthwatering Dishes Images
import authenticMouthwatering1 from "./images/authenticSlide-img1.png";
import authenticMouthwatering2 from "./images/authenticSlide-img2.png";
import authenticMouthwatering3 from "./images/authenticSlide-img3.png";
import authenticMouthwatering4 from "./images/authenticSlide-img4.png";
import authenticMouthwatering5 from "./images/authenticSlide-img5.png";

import galleryImage1 from "../shared/assets/template/gallery/1.jpeg"
import galleryImage2 from "../shared/assets/template/gallery/2.jpeg"
import galleryImage3 from "../shared/assets/template/gallery/3.jpeg"
import galleryImage4 from "../shared/assets/template/gallery/4.jpeg"
import galleryImage5 from "../shared/assets/template/gallery/5.jpeg"
import onlineOrdering from '../shared/assets/images/onlineOrderBanner.png'
import Slider from './slider'
import queryString from 'query-string';
import Modal from 'react-bootstrap/Modal';
import { apiGetMethod, apiPostMethod } from '../../api/rest';
import { allApiUrl } from '../../api/apiRoute';
import { useSelector } from 'react-redux';
import useEmblaCarousel from 'embla-carousel-react'
import LoadMap from "../mapComponent/Map"
import { titleCase } from '../../features/helper';

function LoadingPage() {
    return (
        <>
            <div className="loadingPageWrap pt-5">
                <Container>
                    <Row className="justify-content-md-center">
                        <Col lg="8" md="10" xs="12">
                            <div className="text-center">
                                <Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}
export const EmblaCarousel = (props) => {
    const { slides, options } = props
    const [emblaRef] = useEmblaCarousel(options)

    return (
        <div className="embla">
            <div className="embla__viewport" ref={emblaRef}>
                <div className="embla__container">
                    {slides.map((item, index) => (
                        <div className="embla__slide" key={index}>
                            <img
                                className="embla__slide__img"
                                src={item || dummyProduct}
                                alt="Your alt text"
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
export const PreviewTemplate = (props) => {
    const [template, setTemplate] = useState({ loading: true, token: '' })
    const [apiWebsiteData, setApiWebsiteData] = useState({})
    const branchArray = useSelector((state) => state.counter.branchArray)
    let branches = branchArray.map((item) => item.value)
    const header = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        branchid: JSON.stringify(branches)
    };
    useEffect(() => {
        getWebstiteBuilder()
    }, [])
    async function getWebstiteBuilder() {
        try {
            const response = await apiGetMethod(allApiUrl.getWebsiteTemplate, "", header);
            if (response.status === 200) {
                setTemplate({ ...template, loading: false })
                setApiWebsiteData(response.data.data[0])
            }
        } catch (err) {
            console.log("err", err);
        }
    }
    console.log('----109---', apiWebsiteData)
    console.log('props', props.type)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    let url = ''
    const handleShow = () => setShow(true)
    if (process.env.REACT_APP_ENVIRONMENT == "development") {
        url = 'http://192.168.1.133:3000/'
    }
    else if (process.env.REACT_APP_ENVIRONMENT == "local") {
        // url = 'https://cloudpos-modifier.softuvo.click/'
        url = 'https://cloudpos.softuvo.click/'
    }
    else {
        url = 'https://portal.cloudpos.app/'
    }

    const images = [slideImg1, slideImg2, slideImg3, slideImg4, slideImg2, slideImg3]
    const exploreImages = [exploreSlideImg1, exploreSlideImg2, exploreSlideImg3, exploreSlideImg4, exploreSlideImg5, exploreSlideImg6, exploreSlideImg7, exploreSlideImg8]
    const awardsAndBatchesImages = [awardsAndBatches1, awardsAndBatches2, awardsAndBatches3, awardsAndBatches4, awardsAndBatches5, awardsAndBatches6]
    const authenticMouthwateringImages = [authenticMouthwatering1, authenticMouthwatering2, authenticMouthwatering3, authenticMouthwatering4, authenticMouthwatering5]
    const galleryPhotosStatic = [galleryImage1, galleryImage2, galleryImage3, galleryImage4, galleryImage5]
    const OPTIONS = { loop: true, dragFree: true, containScroll: 'trimSnaps' }
    if (template.loading) {
        return <LoadingPage />
    }
    const renderSwitch = (value) => {
        console.log('value', value)
        switch (value) {
            case 'header':
                return <HeaderPreview />;
            case 'contact':
                return <ContactPreview />;
            case 'additional':
                return <AdditionalInformation />;
            case 'partners':
                return <FoodDeliveryPartner />;
            case 'location':
                return <LocationPreview />;
            case 'footer':
                return <FooterPreview />;
            case 'gallery':
                return <GalleryPreview />;
            case 'announcement':
                return <AnnoucementPreview />;
            case 'hygiene':
                return <HygienePreview />;
            default:
                return '';
        }
    }
    // variable assigned for displaying the data 
    const headerDetails = apiWebsiteData.headerDetails
    const contactDetails = apiWebsiteData.contactDetail
    const locationDetails = apiWebsiteData.locations
    const footerDetails = apiWebsiteData.footer
    const foodDeliveryPartnerDetails = apiWebsiteData.foodDeliveryPartners.partners
    const annoucementDetails = apiWebsiteData.announcement
    const hygieneStandardDetail = apiWebsiteData.hygieneStandard
    // 
    console.log('hygieneStandardDetail', hygieneStandardDetail)
    const HeaderPreview = () => {
        return (
            <React.Fragment>
                <header className="topBar">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="topBar__Logo">
                                    <Link><img src={headerDetails?.logo?.banner || DummyLogo} /></Link>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                {/* <div className="topBar__Button">
                                    <Link className="commonButton commonButton--orange commonButton--lgSize" onClick={handleShow}>Order Now</Link>
                                    <Button className="commonButton commonButton--orange commonButton--lgSize" onClick={handleShow}>Order Now</Button>
                                </div> */}
                            </div>
                            <Modal className="commonModal restaurantMenuModal" centered backdrop="static" keyboard={false} show={show} onHide={handleClose}>
                                <Modal.Header closeButton></Modal.Header>
                                <Modal.Body>
                                    <iframe src={`${url}menu-order?token=${template.token}`} title=""></iframe>
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                </header>
                <section style={{
                    backgroundImage: `url(${headerDetails?.bannerImage?.banner ? headerDetails?.bannerImage?.banner : BannerImage})`
                }} className="bannerSection commonPY">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="bannerSection__content">
                                    <h1>{titleCase(headerDetails?.branchDetails[0]?.label)}</h1>
                                    <p>{headerDetails?.DescriptionOfRestaurant}</p>
                                    <h4>We offer Takeout & Food Delivery</h4>
                                    {/* <Link className="commonButton commonButton--orange commonButton--lgSize" to="">Order Now</Link> */}
                                    <Button className="commonButton commonButton--orange commonButton--lgSize" onClick={handleShow}>Order Now</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
    const ContactPreview = () => {
        const [showMore, setShowMore] = useState(false)
        function findConsecutiveDays(arr) {
            const consecutiveDays = [];
            let startDay = null;
            let endDay = null;

            for (let i = 0; i < arr.length; i++) {
                const currentDay = arr[i];
                const nextDay = arr[(i + 1) % arr.length]; // To handle Sunday to Monday transition
                if (startDay === null) {
                    startDay = currentDay;
                    endDay = currentDay;
                }
                if (nextDay === getNextDay(currentDay)) { endDay = nextDay; } else {
                    if (startDay === endDay) {
                        consecutiveDays.push('-');
                        consecutiveDays.push(startDay);
                    } else { consecutiveDays.push(`${startDay}-${endDay}`); } startDay = null;
                }
            }
            return consecutiveDays.length > 0 ? consecutiveDays : arr;
        }
        function getNextDay(currentDay) {
            const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
            const currentIndex = daysOfWeek.indexOf(currentDay);
            return daysOfWeek[(currentIndex + 1) % daysOfWeek.length];
        }
        let openingDays = contactDetails.openingDays.map((item) => item.value)
        const sequences = findConsecutiveDays(openingDays);
        return (
            <React.Fragment>
                <section className="aboutSection commonPY">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="aboutSection__img">
                                    <img src={ChefImg} />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="aboutSection__content">
                                    <h2>About {titleCase(headerDetails?.branchDetails[0]?.label)}</h2>
                                    <p>{showMore ? contactDetails.aboutTheRestaurant : `${contactDetails.aboutTheRestaurant.substring(0, 500)}`}</p>
                                    {contactDetails.aboutTheRestaurant.length > 500 &&
                                        <span style={{ cursor: "pointer" }} onClick={() => setShowMore(!showMore)}>{showMore ? "show less" : 'show more'}</span>
                                    }
                                    <ul>
                                        {contactDetails?.review ?
                                            <li><span><img src={ratingImg} /></span> {contactDetails?.review}</li> :
                                            ''}
                                        <li><span><img src={qualityImg} /></span> Quality Foods with discounts</li>
                                        {contactDetails?.openingDays.length == 1 ?
                                            <li><span><img src={timingImg} />Opening Hours -</span> <strong>{contactDetails?.openingDays[0]?.label}- {contactDetails?.openingHours?.startTiming} {contactDetails?.openingHours.startPostMeridiem?.label} to {contactDetails?.openingHours?.endTiming} {contactDetails?.openingHours?.endPostMeridiem?.label}</strong></li>
                                            : <li><span><img src={timingImg} />Opening Hours -</span> <strong>{contactDetails?.openingDays[0]?.label} to {contactDetails?.openingDays[contactDetails.openingDays.length - 1]?.label} - {contactDetails?.openingHours?.startTiming} {contactDetails?.openingHours.startPostMeridiem?.label} to {contactDetails?.openingHours?.endTiming} {contactDetails?.openingHours?.endPostMeridiem?.label}</strong></li>
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
    const LocationPreview = () => {
        let client
        if (locationDetails && locationDetails?.enterLocation) {
            client =
                `center=${locationDetails?.enterLocation?.latitude ? locationDetails?.enterLocation?.latitude : 39.86223173133187},
            ${locationDetails?.enterLocation?.longitude ? locationDetails?.enterLocation?.longitude : -101.64156251308714}`
        }
        console.log('client', client)
        return (
            <section className="contactInfo commonPT">
                <div className="container">
                    <div className="row g-0 align-items-center">
                        {
                            locationDetails.describeTheLocation &&
                                locationDetails.deliveryFees || locationDetails.deliveryFees.length > 0 ?
                                <div className="col-lg-5 contactInfo__content">
                                    <div className="contactInfo__contentInner">
                                        <h4>Food Delivery In {titleCase(headerDetails?.branchDetails[0]?.label)}</h4>
                                        <p>{locationDetails.describeTheLocation}</p>
                                        {locationDetails.deliveryFees && locationDetails.deliveryFees.length > 0 ?
                                            <>
                                                <h4>Delivery fee</h4>
                                                <ul>
                                                    {
                                                        locationDetails.deliveryFees.map((item, index) => {
                                                            return (
                                                                <li key={index}>{item.zone}, Minimum Order Amount - {item.min}, Delivery Fee - {item.fee}</li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </> : ''
                                        }
                                    </div>
                                </div> :
                                <div></div>
                        }
                        <div className="col-lg-7 contactInfo__map">
                            {/* {
                                locationDetails.enterLocation
                            } */}
                            {/* <DisplayMapLocation latitude={latitude} longitude={longitude} /> */}
                            {/* <LoadMap data={locationDetails.enterLocation} /> */}
                            <small>{locationDetails?.enterLocation?.formatted_address}</small>
                            {client &&
                                <iframe
                                    src={`https://www.google.com/maps/embed/v1/view?key=${process.env.REACT_APP_GOOGLE_API_KEY}&${client}&zoom=18&maptype=satellite`}
                                    allowfullscreen=""
                                    loading="lazy"
                                    referrerpolicy="no-referrer-when-downgrade">
                                </iframe>}
                        </div>
                    </div>
                </div>
            </section>
        )
    }
    const FooterPreview = () => {
        console.log('footerDetails', footerDetails)
        return (
            <footer className="footer">
                <div className="container">
                    <div className="footer__inner">
                        <div className="socialMediaIcons">
                            {footerDetails.social.map((item) => {
                                return (
                                    <div>{
                                        item &&
                                            item.socialImage &&
                                            item.socialImage[0] &&
                                            item.socialImage[0].banner ?
                                            <a href={`${item.label.includes('https') ? item.label : `https://${item.label}`}`} target='blank'>
                                                <img
                                                    src={item.socialImage[0].banner}
                                                    alt="Testing"
                                                />
                                            </a>
                                            : ''
                                    }
                                    </div>
                                )
                            })
                            }
                        </div>
                        {
                            footerDetails.footerText ?
                                <div className="copyright">
                                    <span>© {footerDetails.footerText}</span>
                                </div>
                                : ''
                        }
                        <div className="bankCards">
                            <Button className="bankCards__button" type="button"><img src={card1} alt="card1" /></Button>
                            <Button className="bankCards__button" type="button"><img src={card2} alt="card2" /></Button>
                            <Button className="bankCards__button" type="button"><img src={card3} alt="card3" /></Button>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
    const FoodDeliveryPartner = () => {
        let FoodDeliuveryPartnerImages = [orderAppsImg1, orderAppsImg2, orderAppsImg3, orderAppsImg4]
        return (
            <section className="cusomerSafeSection commonPY">
                <div className="container">
                    <div className="orderApps commonPT">
                        <div className="row justify-content-center">
                            {foodDeliveryPartnerDetails && foodDeliveryPartnerDetails.length > 0 ? foodDeliveryPartnerDetails.map((item, index) => {
                                console.log('item->', item)
                                let partnerImage
                                if (item.partnerLogo && item.partnerLogo[0] && item.partnerLogo[0].banner) {
                                    partnerImage = item.partnerLogo[0].banner
                                }
                                console.log('partnerImage', partnerImage)
                                return (
                                    <div className="col-xl-3 col-lg-6 orderApps__grid">
                                        <div className="orderApps__box">
                                            <img src={partnerImage} />
                                            <p>{item.description}</p>
                                            <a className="textButton" href={`https://${item.link}`} target='blank'>Order Now</a>
                                        </div>
                                    </div>

                                )
                            }) :
                                <div className="col-xl-3 col-lg-6 orderApps__grid">
                                    <div className="orderApps__box">
                                        <div>No delivery partner added</div>
                                    </div></div>
                            }{/* <div className="col-xl-3 col-lg-6 orderApps__grid">
                                <div className="orderApps__box">
                                    <img src={orderAppsImg2} />
                                    <p>Enjoy the deals, discounts and various offers at Grubhub.</p>
                                    <Link className="textButton" to="">Order Now</Link>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-6 orderApps__grid">
                                <div className="orderApps__box">
                                    <img src={orderAppsImg3} />
                                    <p>Enjoy the free delivery, deals and discounts for all customers.</p>
                                    <Link className="textButton" to="">Order Now</Link>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-6 orderApps__grid">
                                <div className="orderApps__box">
                                    <img src={orderAppsImg4} />
                                    <p>Free delivery, many deals and discounts</p>
                                    <Link className="textButton" to="">Order Now</Link>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>
        )
    }
    const AdditionalInformation = () => {
        // const [setTokenAuth,setTokenAuth]= useState('')
        const [productList, setProductList] = useState([])
        const [awardsList, setAwardsList] = useState([])
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        let restaurant = userInfo?.organizationKey ? userInfo?.organizationKey.split("#")[1] : userInfo?.PK.split("#")[1]
        let branchArray = useSelector((state) => state.counter.branchArray)
        let branches = branchArray.map((item) => item.value)
        const header = {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            branchid: JSON.stringify(branches)
        };
        useEffect(() => {
            getAllProductsImages()
            let award = []
            apiWebsiteData.additionalInformation.awards_and_batches.forEach((item) => {
                award.push(item.banner)
            })
            setAwardsList(award)
        }, [])
        // async function getStripeKeys() {
        //     try {
        //         let data = {
        //             branchId: branches,
        //             restaurant: restaurant
        //         }
        //         const response = await apiPostMethod(`${allApiUrl.keys}`, data, header)
        //         if (response.status === 200) {
        //             try {
        //                 const response = await apiGetMethod(`${allApiUrl.linkCreate}`, "", header);
        //                 if (response.status === 200) {
        //                     setIsLoading(false)
        //                     let token = response.data.data.split('token=')[1]
        //                     setTokenAuth(token)
        //                 }
        //             } catch (err) {
        //                 setIsLoading(false)
        //                 console.log("err", err);
        //             }
        //         }
        //     } catch (err) {
        //         setIsLoading(false)
        //         setShowBlurText(true)
        //     }
        // }

        async function getAllProductsImages() {
            try {
                const response = await apiGetMethod(`${allApiUrl.view_all_product}`, "", header);
                if (response.status === 200) {
                    if (response.data.categories.Items.length > 0) {
                        let images = []
                        response.data.categories.Items.forEach((item) => {
                            console.log('items', item)
                            if (Object.keys(item.image).length) {
                                images.push(item.image.banner)
                            }
                        })
                        setProductList(images)
                    }
                    else {
                        setProductList(exploreImages)
                    }
                }
            } catch (err) {
                console.log("err", err);
            }
        }


        // async function getAllProducts() {
        //     try {
        //         const response = await apiGetMethod(`${allApiUrl.view_all_product}`, "", header);
        //         if (response.status === 200) {
        //             let images = []
        //             response.data.categories.Items.forEach((item) => {
        //                 images.push(item.image.banner)
        //             })

        //             setProductList(images)
        //         }
        //     } catch (err) {
        //         console.log("err", err);
        //     }
        // }

        console.log('productList', productList)
        // let renderData = productList && productList.length > 0 && productList?.map((item, index) => (
        //     <div key={index} >
        //         <img src={item.image?.banner || dummyProduct} alt="" />
        //     </div>
        // ))
        return (
            <React.Fragment>
                {/* <section className="specialOffers commonPY">
            //         <div className="container">
            //             <div className="commonHeading commonHeading--left">
            //                 <h2>Special offers</h2>
            //             </div>
            //             <div className="flickitySlider">
            //                 <Slider
            //                     options={{
            //                         // pauseAutoPlayOnHover: true,
            //                         // adaptiveHeight: true
            //                         // fullscreen: true,
            //                         autoPlay: false,
            //                         freeScroll: true,
            //                         cellAlign: 'left',
            //                         wrapAround: true,
            //                         contain: true,
            //                         prevNextButtons: true,
            //                         pageDots: false,
            //                         // arrowShape: 'M10.212 12.007 7.645 9.414h15.124v-4H7.62l2.585-2.586L7.377 0 0 7.378l7.37 7.443 2.842-2.814z'
            //                     }}>
            //                     {images.map((image, index) => (
            //                         <div key={index}>
            //                             <div className="upperText">
            //                                 <h5>Get Flat</h5>
            //                                 <h3>20% Discount</h3>
            //                                 <h4>on <strong>super delicious burger</strong></h4>
            //                                 <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
            //                                 <Link className="commonButton commonButton--orange commonButton--lgSize" to="">Order Now</Link>
            //                             </div>
            //                             <img src={image} alt="" />
            //                         </div>
            //                     ))}
            //                 </Slider>
            //             </div>
            //         </div>
            //     </section> */}
                <section className="exploreUs commonPB">
                    <div className="container">
                        <div className="commonHeading commonHeading--left">
                            <h2>Explore Us</h2>
                        </div>
                        <div className="flickitySlider">
                            <EmblaCarousel slides={productList} options={OPTIONS} />
                            {/* <div>
                            {renderData}
                        </div> */}
                            {/* <Slider
                                options={{
                                    // pauseAutoPlayOnHover: true,
                                    // adaptiveHeight: true
                                    // fullscreen: true,
                                    autoPlay: false,
                                    freeScroll: true,
                                    cellAlign: 'left',
                                    wrapAround: true,
                                    contain: true,
                                    prevNextButtons: true,
                                    pageDots: false,
                                }}>
                                {renderData}
                            </Slider> */}
                        </div>
                    </div>
                </section>
                <section className="specialOffers awardsAndBatches commonPB">
                    <div className="container">
                        <div className="commonHeading commonHeading--center">
                            <h2>Awards And Batches</h2>
                        </div>
                        <div className="flickitySlider">
                            <EmblaCarousel slides={awardsList && awardsList.length ? awardsList : awardsAndBatchesImages} options={OPTIONS} />
                            {/* <div>
                                <div> {awardsAndBatchesImages.map((item) => {
                                    return <img src={item} alt="" />
                                })} </div>
                            </div> */}
                            {/* <Slider
                                options={{
                                    // pauseAutoPlayOnHover: true,
                                    // adaptiveHeight: true
                                    // fullscreen: true,
                                    autoPlay: false,
                                    freeScroll: true,
                                    cellAlign: 'left',
                                    wrapAround: true,
                                    contain: true,
                                    prevNextButtons: false,
                                    pageDots: false,
                                }}>
                                {awardsAndBatchesImages.map((image, index) => (
                                    <div key={index}>
                                        <img src={image} alt="" />
                                    </div>
                                ))}
                            </Slider> */}
                        </div>
                    </div>
                </section>
            </React.Fragment >
        )
    }
    const GalleryPreview = () => {
        const [galleryPhotos, setGaleryPhoto] = useState([])
        let branchArray = useSelector((state) => state.counter.branchArray)
        let branches = branchArray.map((item) => item.value)
        const header = {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            branchid: JSON.stringify(branches)
        };
        useEffect(() => {
            let award = []
            apiWebsiteData &&
                apiWebsiteData.galleryInfo &&
                apiWebsiteData.galleryInfo.galleryPhotos.forEach((item) => {
                    award.push(item.banner)
                })
            setGaleryPhoto(award)
        }, [])
        console.log('galleryPhotos', galleryPhotos)
        return (
            <React.Fragment>
                <section className="specialOffers awardsAndBatches commonPB">
                    <div className="container">
                        <div className="commonHeading commonHeading--center">
                            <h2>Gallery</h2>{!galleryPhotos.length && <span>These are demo images.</span>}
                        </div>
                        <div className="flickitySlider">
                            <EmblaCarousel slides={galleryPhotos && galleryPhotos.length ? galleryPhotos : galleryPhotosStatic} options={OPTIONS} />
                        </div>
                    </div>
                </section>
            </React.Fragment >
        )
    }
    const AnnoucementPreview = () => {
        return (
            <section
                style={{ backgroundImage: `url(${annoucementDetails?.announcementImage?.banner ? annoucementDetails?.announcementImage?.banner : onlineOrdering})` }}
                className="confirmSection onlineOrderSection smallCommonPY announcementImgSection">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="confirmSection__content">
                                <h2>{annoucementDetails.announcementTitle}</h2>
                                <p>{annoucementDetails.announcementDescription}</p>
                                {annoucementDetails.announcementTitle && annoucementDetails.announcementDescription &&
                                    <Button className="commonButton commonButton--orange commonButton--lgSize" type="button" onClick={handleShow}>Order Now</Button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
    const HygienePreview = () => {
        return (
            <section className="cusomerSafeSection commonPY">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="cusomerSafeSection__img">
                                <img src={hygieneStandardDetail?.hygieneStandardImages?.banner ?
                                    hygieneStandardDetail?.hygieneStandardImages.banner :
                                    PlateImg} />
                            </div>
                        </div>
                        <div className="col-lg-6 ps-lg-5">
                            <div className="cusomerSafeSection__content">
                                <h2>{hygieneStandardDetail.hygieneStandardTitle}</h2>
                                <p>{hygieneStandardDetail.hygieneStandardDescription}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
    return (
        <main className="mainWrapper">
            {renderSwitch(props.type)}
        </main>
    )
}

export default PreviewTemplate