import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import counterReducer from '../features/todoSlice';

const persistConfig = {
key: 'root',
storage,
};

const persistedReducer = persistReducer(persistConfig, counterReducer);

const store = configureStore({
reducer: {
counter: persistedReducer,
},
middleware: [ thunk],
});

export const persistor = persistStore(store);

export default store;