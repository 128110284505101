import { v4 as uuidv4 } from "uuid";
import React, { useState, useEffect } from "react";
import {
  Button,
  Table,
  Container,
  Spinner,
  Row,
  Col,
  Form,
  FloatingLabel,
  InputGroup,
} from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import { BsArrowDown, BsArrowUp } from "react-icons/bs";
import "react-phone-input-2/lib/bootstrap.css";
//Header
import { changeTableManagement, changeWebsiteTemplate, saveBranchArray } from "../../features/todoSlice";
import dummyProduct from "../../components/shared/assets/images/dummyProduct.jpg";
import Header from "../common/header";
import Sidebar from "../common/sidebar";
import {
  apiDeleteMethod,
  apiGetMethod,
  apiPostMethod,
  apiPutMethod,
} from "../../api/rest";
import { allApiUrl } from "../../api/apiRoute";
import toastMessages from "../toastMessages";
import CommonButton from "../../components/common-ui/Button";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector, useDispatch } from "react-redux";
import { countNumberInPhoneNumber } from "../../features/helper";
import Loader from "../shared/spinner";
import { smallStyle } from "../../features/helper";
import Select from "react-select";
import { countries } from "../../features/constant";
import SidebarTwo from "../common/sidebarTwo";

function Branches() {
  let dispatch = useDispatch();
  const [isPageRefreshed, setIsPageRefreshed] = useState(false);
  let sidebarToggle = useSelector((state) => state.counter.sidebarToggle);
  const [loader, setLoader] = useState(false);
  const [preview, setPreview] = useState([]);
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);
  const [phoneValidatError, setPhoneValidError] = useState(false);
  const [deletedId, setDeletedId] = useState("");
  //   useEffect(() => {
  //     setIsLoading(true)
  //     getAllModifier()
  //     if ((branchArray.length > 1) || (branchArray && branchArray[0].value == "All")) {
  //         history.push('/dashboard')
  //     }
  // }, [pageRefresh, branchArray])
  const [form, setForm] = useState({
    branchname: "",
    address: "",
    city: "",
    stateId: "",
    country: "",
    zipcode: "",
    countrycode: "+1",
    phone: "",
    file: "",
    permissionsgranted: {
      tablemanagement: 0,
      digitalmenu: 0,
      allowedordertype: [],
      kitchendisplayapp: 0,
      sendtokitchen: 0,
      manualsettlement: 0,
      printer: 0,
      cardreader: 0,
      paymenttype: [],
      cashdrawer: 0,
      websitetemplate: 0
    }
  });
  const [errorInput, setErrorInput] = useState({
    branchname: false,
    city: false,
    stateId: false,
    country: false,
    zipcode: false,
    phone: false,
    address: false,
  });
  const [permissionData, setPermissionData] = useState({})
  useEffect(() => {
    getPermission()
    getOrderData()
    getDisableStatus()
  }, [permissionData])
  //   admin/view-payment-types
  // /view-order-types
  const [permissionType, setPermissionType] = useState([])
  const [orderType, setOrderType] = useState([])
  async function getOrderData() {
    const header = {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    };
    try {
      const response = await apiGetMethod(`${allApiUrl.orderTypes}`, "", header);
      if (response.status == 200) {
        let orderType = response.data.data.map((item) => {
          return {
            label: item.orderType,
            value: item.orderTypeId,
          }
        })
        setOrderType(orderType)
      }
    } catch (err) {
      console.log('err', err)
    }
  }
  async function getPermission() {
    const header = {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    };
    try {
      const response = await apiGetMethod(`${allApiUrl.paymentTypes}`, "", header);
      if (response.status == 200) {
        let permissionType = response.data.data.map((item) => {
          return {
            label: item.paymentType,
            value: item.paymentTypeId,
          }
        })
        setPermissionType(permissionType)
      }
    } catch (err) {
      console.log('err', err)
    }
  }
  console.log('permissionType', permissionType)
  console.log('orderType', orderType)
  console.log('permissionData', permissionData)
  let branchArray = useSelector((state) => state.counter.branchArray);
  let branches = branchArray.map((item) => item.value);
  async function getDisableStatus() {
    const header = {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      branchid: JSON.stringify(branches),
    };
    try {
      const response = await apiGetMethod(allApiUrl.viewSetting, "", header);
      if (response.status == 200) {
        if (response.data.Items.length != 0) {
          //for table managment sidebar toggle functionality.
          if (permissionData.tablemanagement == 0) {
            dispatch(changeTableManagement(false));
          }
          else {
            if (response.data.Items[0].GeneralSettings.tablemanagement == 1) {
              dispatch(changeTableManagement(true));
            } else {
              dispatch(changeTableManagement(false));
            }
          }
          // for website builder
          if (permissionData.websitetemplate == 0) {
            dispatch(changeWebsiteTemplate(false));
          }
          else {
            if (response.data.Items[0].WebsiteTemplate.enableWebsiteTemplate == 1) {
              //eslint-disable-line
              dispatch(changeWebsiteTemplate(true));
            } else {
              dispatch(changeWebsiteTemplate(false));
            }
          }

        }
      }
    } catch (err) {
      console.log("err", err);
    }
  }
  async function addBranchHandler(e) {
    setLoader(true);
    e.preventDefault();
    const header = {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    };
    try {
      if (
        form.branchname &&
        form.address &&
        form.city &&
        form.zipcode &&
        form.phone &&
        form.country
      ) {
        const formData = new FormData();
        formData.append("branchname", form.branchname);
        formData.append("address", form.address);
        formData.append("city", form.city.toLowerCase());
        formData.append("stateId", form.stateId);
        formData.append("country", form.country.value);
        formData.append("zipcode", form.zipcode);
        formData.append("countrycode", form.countrycode);
        formData.append("phone", form.phone);
        formData.append('permissionsgranted', JSON.stringify(form.permissionsgranted))
        if (form.file) {
          formData.append("file", form.file);
        }
        // form.permissionsgranted
        if (isPhoneNumberValid) {
          //eslint-disable-line
          const response = await apiPostMethod(
            allApiUrl.addBranch,
            formData,
            header
          );
          if (response.status === 200) {
            setPreview([]);
            setLoader(false);
            setForm({
              branchname: "",
              address: "",
              city: "",
              stateId: "",
              country: "",
              zipcode: "",
              countrycode: "+1",
              phone: "",
              file: "",
              permissionsgranted: {
                tablemanagement: 0,
                digitalmenu: 0,
                allowedordertype: [],
                kitchendisplayapp: 0,
                sendtokitchen: 0,
                manualsettlement: 0,
                paymenttype: [],
                cashdrawer: 0,
                websitetemplate: 0
              }
            });
            setIsPageRefreshed(!isPageRefreshed);
            toastMessages("success", "Store Created Successfully");
          }
        } else if (isPhoneNumberValid === false) {
          setLoader(false);
          toastMessages("error", "Please enter a valid phone number");
        }
      } else {
        setLoader(false);
        const emptyFields = [];
        for (const property in form) {
          if (form[property] === "") {
            emptyFields.push(property);
          }
        }
        const errorInputCopy = { ...errorInput };
        emptyFields.forEach((field) => (errorInputCopy[field] = true));
        setErrorInput(errorInputCopy);
        toastMessages("error", "Please fill the required fields");
      }
    } catch (err) {
      setLoader(false);
      setIsPageRefreshed(!isPageRefreshed);
      toastMessages("error", err.data.message);
    }
  }
  const handleOnChange = (value, country) => {
    setForm({ ...form, phone: value });
    setErrorInput({ ...errorInput, phone: false });
    const result = countNumberInPhoneNumber(country.format, ".");
    // console.log("value", result, value, value.includes(0));
    console.log('result',)
    var zeroCount = value.toString().replace("0.", "").split("0").length - 1;
    console.log('value', value)
    console.log('zeroCount', zeroCount)
    if (zeroCount > 9) {
      setIsPhoneNumberValid(false);
      setPhoneValidError(
        `Enter a valid phone number`
      );
    }
    else if (zeroCount > 10) {
      console.log('231')
      if (value.length - country.dialCode.length == result - country.dialCode.length
      ) {
        setIsPhoneNumberValid(true);
        setPhoneValidError('');
      } else {
        setIsPhoneNumberValid(false);
        setPhoneValidError(
          `The Phone Number should be of ${result - country.dialCode.length
          } Digit`
        );
      }
    }
    else {
      console.log('245')
      if (value.length - country.dialCode.length == result - country.dialCode.length
      ) {
        setIsPhoneNumberValid(true);
        setPhoneValidError('');
      } else {
        setIsPhoneNumberValid(false);
        setPhoneValidError(
          `The Phone Number should be of ${result - country.dialCode.length
          } Digit`
        );
      }
    }


  };
  let props = {
    form,
    setForm,
    smallStyle,
    errorInput,
    setErrorInput,
    handleOnChange,
    setPreview,
    preview,
    phoneValidatError,
    isPhoneNumberValid,
    setDeletedId,
    deletedId,
    dispatch,
    orderType,
    permissionType
  };
  const [sidebarType, setSidebarType] = useState("singleSidebar");
  let sidebarTypeRedux = useSelector((state) => state.counter.sidebarType);
  console.log('form---->', form)
  return (
    <React.Fragment>
      <Header
        // setActive={setActive}
        active={sidebarToggle}
        isPageRefreshed={isPageRefreshed}
        page="stores"
      />
      <div className="interFaceWrap">
        <Sidebar
          permissionData={permissionData}
          active={sidebarToggle}
          activePage="stores"
        />
        <div
          className={`interFaceWrap__inner ${sidebarTypeRedux == false ? "doubleSidebar" : "singleSidebar"
            }`}
        >
          <div className="commonOuter">
            <div className="pageHeader">
              <Container fluid className="px-0">
                <Row className="align-items-center">
                  <div className="col">
                    <div className="headingText headingText--textLeft mb-0">
                      <h1>Add Store</h1>
                    </div>
                  </div>
                  <div className="col-auto ms-auto onlyButtons">
                    <div className="pageHeader__elements">
                      <CommonButton
                        buttonName="Save"
                        type="Width"
                        loader={loader}
                        onClick={addBranchHandler}
                      />
                    </div>
                  </div>
                </Row>
              </Container>
            </div>
            <div className="selectEmployeeOpetions">
              <Row>
                <Col xxl={7} xl={8} lg={12}>
                  <div className="whiteBg">
                    <BranchFormField {...props} />
                  </div>
                </Col>
                <Col xl={6} lg={4}></Col>
              </Row>
            </div>
            <BranchTable
              setIsPageRefreshed={setIsPageRefreshed}
              setDeletedId={setDeletedId}
              deletedId={deletedId}
              isPageRefreshed={isPageRefreshed}
              dispatch={dispatch}
            />
          </div>
        </div>
        <SidebarTwo
          activePage="stores"
          sidebarType={sidebarType}
          setSidebarType={setSidebarType}
          isPageRefreshed={isPageRefreshed}
          setPermissionData={setPermissionData}
        />
      </div>
    </React.Fragment>
  );
}
export default Branches;
export { BranchFormField };
const BranchFormField = ({
  form,
  setForm,
  smallStyle,
  errorInput,
  setErrorInput,
  handleOnChange,
  setPreview,
  preview,
  phoneValidatError,
  isPhoneNumberValid,
  orderType,
  permissionType
}) => {
  console.log('orderTypes', orderType)
  return (
    <Form>
      <Row>
        <Col md={6}>
          <Form.Group className="form-group">
            <Form.Label>
              Store Name<sup>*</sup>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Store Name"
              required
              value={form.branchname}
              className={`${errorInput.branchname ? "error" : ""}`}
              onChange={(e) => {
                const alphabetRegex = /^[a-zA-Z 0-9&,_-]*$/;
                if (e.target.value.length <= 61) {
                  if (e.target.value.match(alphabetRegex)) {
                    setForm({
                      ...form,
                      branchname: e.target.value,
                    });
                    setErrorInput({ ...errorInput, branchname: false });
                  }
                }
              }}
            />
            {errorInput.branchname && <small>Store is required!</small>}
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group className="form-group">
            <Form.Label>
              City<sup>*</sup>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              required
              value={form.city}
              autoComplete="off"
              className={`${errorInput.city ? "error" : ""}`}
              onChange={(e) => {
                const alphabetRegex = /^[a-zA-Z@_., ]*$/;
                if (e.target.value.match(alphabetRegex)) {
                  setForm({
                    ...form,
                    city: e.target.value,
                  });
                  setErrorInput({ ...errorInput, city: false });
                }
              }}
            />
            {errorInput.city && <small>City is required!</small>}
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group className="form-group">
            <Form.Label>
              State<sup>*</sup>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              required
              value={form.stateId}
              className={`${errorInput.stateId ? "error" : ""}`}
              onChange={(e) => {
                const alphabetRegex = /^[a-zA-Z@_., ]*$/;
                if (e.target.value.match(alphabetRegex)) {
                  setForm({
                    ...form,
                    stateId: e.target.value,
                  });
                  setErrorInput({ ...errorInput, stateId: false });
                }
              }}
            />
            {errorInput.stateId && <small>State is required!</small>}
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group className="form-group">
            <Form.Label>
              Country<sup>*</sup>
            </Form.Label>
            <Select
              className="select2 "
              placeholder="Please Select Country"
              options={countries}
              value={form.country}
              labelledBy="Select"
              onChange={(e) => {
                setForm({
                  ...form,
                  country: e,
                });
                setErrorInput({ ...errorInput, country: false });
              }}
            />
            {errorInput.country && (
              <small
                style={{
                  display: "block",
                  fontSize: "13px",
                  color: "#E02424",
                }}
              >
                Country is required!
              </small>
            )}
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group className="form-group">
            <Form.Label>
              Zip Code<sup>*</sup>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              required
              value={form.zipcode}
              onKeyDown={(evt) =>
                (evt.key === "e" || evt.key === "E") && evt.preventDefault()
              }
              className={`${errorInput.zipcode ? "error" : ""}`}
              onChange={(e) => {
                const alphabetRegex = /^[0-9 a-zA-Z]*$/;
                if (e.target.value.match(alphabetRegex)) {
                  if (e.target.value.length <= 10) {
                    setForm({
                      ...form,
                      zipcode: e.target.value,
                    });
                    setErrorInput({ ...errorInput, zipcode: false });
                  }
                }

              }}
            />
            {errorInput.zipcode && <small>Zipcode is required!</small>}
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group className="form-group">
            <Form.Label>
              Phone Number<sup>*</sup>
            </Form.Label>
            <PhoneInput
              country="us"
              value={form.phone}
              onChange={handleOnChange}
              enableSearch={true}
              inputStyle={{ width: "100%" }}
            />
            {errorInput.phone && (
              <small style={smallStyle}>Phone is required!</small>
            )}
            {isPhoneNumberValid === false && form.phone && (
              <small style={{ color: "red" }}>{phoneValidatError}</small>
            )}
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group className="form-group text-start">
            <Form.Label>Image</Form.Label>
            <div className="imagePreview">
              <Form.Control
                type="file"
                placeholder=""
                accept="image/*"
                required
                onChange={(e) => {
                  if (e) {
                    const file = e.target.files;
                    if (file) {
                      if (file[0].type.split('/')[1] == "gif") {
                        toastMessages('error', "Gif format is not supported")
                      } else {
                        setPreview(URL.createObjectURL(file[0]));
                        setForm({ ...form, file: file[0] })
                      }
                    }
                  }
                }}
                style={{ display: "none" }}
                id="file-upload"
              />
              <label htmlFor="file-upload" className="custom-file-upload">
                Upload Image
              </label>
              <div className="imageView">
                {preview && preview.length > 0 && (
                  <img src={preview} alt="branchImage" />
                )}
                {preview && preview.length > 0 && (
                  <i
                    className="fa-solid fa-trash-can pointer"
                    onClick={() => {
                      setPreview([]);
                      setForm({ ...form, deleteImage: 1, file: "" });
                    }}
                  ></i>
                )}
              </div>
            </div>
          </Form.Group>
        </Col>
        <Col md={12}>
          <Form.Group className="form-group">
            <Form.Label>
              Address<sup>*</sup>
            </Form.Label>
            <Form.Control
              as="textarea"
              placeholder=""
              className={`${errorInput.address && "error"}`}
              required
              value={form.address}
              onChange={(e) => {
                setForm({
                  ...form,
                  address: e.target.value,
                });
                setErrorInput({ ...errorInput, address: false });
              }}
            />
            {errorInput.address && <small>Address is required!</small>}
          </Form.Group>
          <h3 style={{ textAlign: "left", marginBottom: '12px' }}>Permissions</h3>
          <div className="outerBox">
            <Form.Group className="form-group">
              <Row>
                <Col lg={3}>
                  <Form.Label>Table Management</Form.Label>
                </Col>
                <Col xxl={3} xl={6} lg={6} className="extraPX">
                  <Form.Check
                    onClick={(e) => {
                      setForm((prevData) => {
                        prevData.permissionsgranted.tablemanagement =
                          e.target.checked == true ? 1 : 0; //eslint-disable-line
                        return {
                          ...prevData,
                        };
                      });
                    }}
                    checked={
                      form.permissionsgranted.tablemanagement ==
                        1
                        ? true
                        : false
                    } //eslint-disable-line
                    onChange={() => { }}
                    type="switch"
                    id="custom-switch"
                    className="enabled"
                    label=""
                  />
                </Col>
                <Col
                  xxl={6}
                  xl={3}
                  lg={3}
                  className="d-lg-block d-none"
                >
                  &nbsp;
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="form-group">
              <Row>
                <Col lg={3}>
                  <Form.Label>Digital Menu</Form.Label>
                </Col>
                <Col xxl={3} xl={6} lg={6} className="extraPX">
                  <Form.Check
                    onClick={(e) => {
                      setForm((prevData) => {
                        prevData.permissionsgranted.digitalmenu =
                          e.target.checked == true ? 1 : 0; //eslint-disable-line
                        return {
                          ...prevData,
                        };
                      });
                    }}
                    checked={
                      form.permissionsgranted.digitalmenu ==
                        1
                        ? true
                        : false
                    } //eslint-disable-line
                    onChange={() => { }}
                    type="switch"
                    id="custom-switch"
                    className="enabled"
                    label=""
                  />
                </Col>
                <Col
                  xxl={6}
                  xl={3}
                  lg={3}
                  className="d-lg-block d-none"
                >
                  &nbsp;
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="form-group">
              <Row>
                <Col lg={3}>
                  <Form.Label>KDA</Form.Label>
                </Col>
                <Col xxl={3} xl={6} lg={6} className="extraPX">
                  <Form.Check
                    onClick={(e) => {
                      setForm((prevData) => {
                        prevData.permissionsgranted.kitchendisplayapp =
                          e.target.checked == true ? 1 : 0; //eslint-disable-line
                        return {
                          ...prevData,
                        };
                      });
                    }}
                    checked={
                      form.permissionsgranted.kitchendisplayapp ==
                        1
                        ? true
                        : false
                    } //eslint-disable-line
                    onChange={() => { }}
                    type="switch"
                    id="custom-switch"
                    className="enabled"
                    label=""
                  />
                </Col>
                <Col
                  xxl={6}
                  xl={3}
                  lg={3}
                  className="d-lg-block d-none"
                >
                  &nbsp;
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="form-group">
              <Row>
                <Col lg={3}>
                  <Form.Label>Send To Kitchen</Form.Label>
                </Col>
                <Col xxl={3} xl={6} lg={6} className="extraPX">
                  <Form.Check
                    onClick={(e) => {
                      setForm((prevData) => {
                        prevData.permissionsgranted.sendtokitchen =
                          e.target.checked == true ? 1 : 0; //eslint-disable-line
                        return {
                          ...prevData,
                        };
                      });
                    }}
                    checked={
                      form.permissionsgranted.sendtokitchen ==
                        1
                        ? true
                        : false
                    } //eslint-disable-line
                    onChange={() => { }}
                    type="switch"
                    id="custom-switch"
                    className="enabled"
                    label=""
                  />
                </Col>
                <Col
                  xxl={6}
                  xl={3}
                  lg={3}
                  className="d-lg-block d-none"
                >
                  &nbsp;
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="form-group">
              <Row>
                <Col lg={3}>
                  <Form.Label>Manual Settlement</Form.Label>
                </Col>
                <Col xxl={3} xl={6} lg={6} className="extraPX">
                  <Form.Check
                    onClick={(e) => {
                      setForm((prevData) => {
                        prevData.permissionsgranted.manualsettlement =
                          e.target.checked == true ? 1 : 0; //eslint-disable-line
                        return {
                          ...prevData,
                        };
                      });
                    }}
                    checked={
                      form.permissionsgranted.manualsettlement ==
                        1
                        ? true
                        : false
                    } //eslint-disable-line
                    onChange={() => { }}
                    type="switch"
                    id="custom-switch"
                    className="enabled"
                    label=""
                  />
                </Col>
                <Col
                  xxl={6}
                  xl={3}
                  lg={3}
                  className="d-lg-block d-none"
                >
                  &nbsp;
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="form-group">
              <Row>
                <Col lg={3}>
                  <Form.Label>Cash Drawer</Form.Label>
                </Col>
                <Col xxl={3} xl={6} lg={6} className="extraPX">
                  <Form.Check
                    onClick={(e) => {
                      setForm((prevData) => {
                        prevData.permissionsgranted.cashdrawer =
                          e.target.checked == true ? 1 : 0; //eslint-disable-line
                        return {
                          ...prevData,
                        };
                      });
                    }}
                    checked={
                      form.permissionsgranted.cashdrawer ==
                        1
                        ? true
                        : false
                    } //eslint-disable-line
                    onChange={() => { }}
                    type="switch"
                    id="custom-switch"
                    className="enabled"
                    label=""
                  />
                </Col>
                <Col
                  xxl={6}
                  xl={3}
                  lg={3}
                  className="d-lg-block d-none"
                >
                  &nbsp;
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="form-group">
              <Row>
                <Col lg={3}>
                  <Form.Label>Website template</Form.Label>
                </Col>
                <Col xxl={3} xl={6} lg={6} className="extraPX">
                  <Form.Check
                    onClick={(e) => {
                      setForm((prevData) => {
                        prevData.permissionsgranted.websitetemplate =
                          e.target.checked == true ? 1 : 0; //eslint-disable-line
                        return {
                          ...prevData,
                        };
                      });
                    }}
                    checked={
                      form.permissionsgranted.websitetemplate ==
                        1
                        ? true
                        : false
                    } //eslint-disable-line
                    onChange={() => { }}
                    type="switch"
                    id="custom-switch"
                    className="enabled"
                    label=""
                  />
                </Col>
                <Col
                  xxl={6}
                  xl={3}
                  lg={3}
                  className="d-lg-block d-none"
                >
                  &nbsp;
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="form-group">
              <Row>
                <Col lg={3}>
                  <Form.Label>Printer</Form.Label>
                </Col>
                <Col xxl={3} xl={6} lg={6} className="extraPX">
                  <Form.Check
                    onClick={(e) => {
                      setForm((prevData) => {
                        prevData.permissionsgranted.printer =
                          e.target.checked == true ? 1 : 0; //eslint-disable-line
                        return {
                          ...prevData,
                        };
                      });
                    }}
                    checked={
                      form.permissionsgranted.printer ==
                        1
                        ? true
                        : false
                    } //eslint-disable-line
                    onChange={() => { }}
                    type="switch"
                    id="custom-switch"
                    className="enabled"
                    label=""
                  />
                </Col>
                <Col
                  xxl={6}
                  xl={3}
                  lg={3}
                  className="d-lg-block d-none"
                >
                  &nbsp;
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="form-group">
              <Row>
                <Col lg={3}>
                  <Form.Label>Card Reader</Form.Label>
                </Col>
                <Col xxl={3} xl={6} lg={6} className="extraPX">
                  <Form.Check
                    onClick={(e) => {
                      setForm((prevData) => {
                        prevData.permissionsgranted.cardreader =
                          e.target.checked == true ? 1 : 0; //eslint-disable-line
                        return {
                          ...prevData,
                        };
                      });
                    }}
                    checked={
                      form.permissionsgranted.cardreader ==
                        1
                        ? true
                        : false
                    } //eslint-disable-line
                    onChange={() => { }}
                    type="switch"
                    id="custom-switch"
                    className="enabled"
                    label=""
                  />
                </Col>
                <Col
                  xxl={6}
                  xl={3}
                  lg={3}
                  className="d-lg-block d-none"
                >
                  &nbsp;
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="form-group">
              <Row>
                <Col lg={3}>
                  <Form.Label>Allowed Order Type<sup>*</sup></Form.Label>
                </Col>
                <Col xxl={7} xl={7} lg={7} className="extraPX">
                  <Form.Group className="form-group">
                    {/* <Form.Label>
                      <sup>*</sup>
                    </Form.Label> */}
                    <Select
                      isMulti
                      className="select2"
                      placeholder="Order Type"
                      options={orderType}
                      value={form.permissionsgranted.allowedordertype}
                      labelledBy="Select"
                      onChange={(e) => {
                        // e.forEach((item) => item.permissionGranted = 1)
                        console.log('e--->', e)
                        setForm((prev) => {
                          prev.permissionsgranted.allowedordertype = e
                          return { ...prev }
                        });
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col
                  xxl={6}
                  xl={3}
                  lg={3}
                  className="d-lg-block d-none"
                >
                  &nbsp;
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="form-group">
              <Row>
                <Col lg={3}>
                  <Form.Label>Payments Type<sup>*</sup></Form.Label>
                </Col>
                <Col xxl={7} xl={7} lg={7} className="extraPX">
                  <Form.Group className="form-group">
                    {/* <Form.Label>
                      <sup>*</sup>
                    </Form.Label> */}
                    <Select
                      isMulti
                      className="select2"
                      placeholder="Payment Type"
                      options={permissionType}
                      value={form.permissionsgranted.paymenttype}
                      labelledBy="Select"
                      onChange={(e) => {
                        console.log('e--->', e)
                        setForm((prev) => {
                          prev.permissionsgranted.paymenttype = e
                          return { ...prev }
                        });
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col
                  xxl={6}
                  xl={3}
                  lg={3}
                  className="d-lg-block d-none"
                >
                  &nbsp;
                </Col>
              </Row>
            </Form.Group>
          </div>

        </Col>
      </Row>

    </Form>
  );
};
const BranchTable = (props) => {
  const history = useHistory();
  let dispatch = useDispatch();
  let currBranch = useSelector((state) => state.counter.currBranch);
  let branchArray = useSelector((state) => state.counter.branchArray);
  let branches = branchArray.map((item) => item.value);
  const header = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    branchid: JSON.stringify(branches),
  };
  // console.log('Delte')
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState("");
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [searchedValue, setSearchedValue] = useState("");
  const [sortingTypeIndex, setSortingTypeIndex] = useState("Up");
  const [sortingTypeName, setSortingTypeName] = useState("Up");
  const [sortingTypeCity, setSortingTypeCity] = useState("Up");
  const [loaderForFetchMore, setLoaderForFetchMore] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  async function onDeleteHandler(id) {
    props.setDeletedId(id);
    // await props.dispatch(getSelectedBranch(id))
    setLoader(true);
    const header = {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    };
    try {
      const response = await apiDeleteMethod(
        allApiUrl.delete_branch,
        id,
        header
      );
      if (response.status === 200) {
        //Removing the store from redux store
        let updatedStore = branchArray.filter((item) => item.value !== id);
        if (!updatedStore.length) {
          updatedStore = [{ value: "All", label: "All" }];
        }
        await dispatch(saveBranchArray(updatedStore));
        setLoader(false);
        props.setIsPageRefreshed(!props.isPageRefreshed);
        toastMessages("success", response.data.message);
        // setDeletedId('')
      }
    } catch (error) {
      console.log("error", error);
      setLoader(false);
      toastMessages("error", error.data.message);
      props.setIsPageRefreshed(!props.isPageRefreshed);
    }
  }
  // console.log('-----423--------', props.deletedId)
  function titleCase(str) {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  }
  useEffect(() => {
    setIsLoading(true);
    getAllBranch();
    setLoaderForFetchMore(true);
    // if ((branchArray.length > 1) || (branchArray && branchArray[0].value == "All")) {
    //   history.push('/dashboard')
    // }
    // getDisableStatus()
    //branchArray
  }, [props.isPageRefreshed,]);

  useEffect(() => {
    setSortingTypeIndex("Up");
    setSortingTypeName("Up");
    setSortingTypeCity("Up");
  }, [currBranch.value]);
  async function getAllBranch() {
    const header = {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    };
    try {
      const response = await apiGetMethod(
        `${allApiUrl.view_all_branches}`,
        "",
        header
      );
      if (response.status === 200) {
        setData(response.data.data.Items);
        setIsLoading(false);
        if (response.data.data.Items.length < 5) {
          setLoaderForFetchMore(false);
        } else {
          setLoaderForFetchMore(true);
        }
        if (response.data.data.LastEvaluatedKey) {
          setLastEvaluatedKey(response.data.data.LastEvaluatedKey.SK);
        }
        toastMessages("success", response.data.message);
      }
    } catch (err) {
      toastMessages("error", err.data.message);
      setIsLoading(false);
      console.log("err", err);
    }
  }
  const fetchMoreData = async () => {
    if (lastEvaluatedKey) {
      setLoaderForFetchMore(true);
      const header = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      };
      try {
        let start = "";
        if (lastEvaluatedKey) {
          start = `&startKey=${lastEvaluatedKey}`;
        }
        const response = await apiGetMethod(
          `${allApiUrl.view_all_branches}?${start}`,
          "",
          header
        );
        if (response.status === 200) {
          if (response.data.data.Items.length < 1) {
            setLoaderForFetchMore(false);
          }
          setData((prevItems) => prevItems.concat(response.data.data.Items));
          if (response.data.data.LastEvaluatedKey) {
            setLastEvaluatedKey(response.data.data.LastEvaluatedKey.SK);
          } else {
            setLastEvaluatedKey("");
          }
          toastMessages("success", response.data.message);
        }
      } catch (err) {
        console.log("err", err);
      }
    } else {
      setLoaderForFetchMore(false);
    }
  };

  async function updateActiveStatus(e, value) {
    props.setDeletedId(value);
    // await props.dispatch(getSelectedBranch(value))
    const header = {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    };
    try {
      const data = {
        branchstatus: e.target.checked == true ? 1 : 0, //eslint-disable-line
      };
      const response = await apiPutMethod(
        allApiUrl.update_branch,
        data,
        header,
        value
      );
      if (response.status === 200) {
        props.setIsPageRefreshed(!props.isPageRefreshed);
        let updatedStore = branchArray.filter((item) => item.value !== value);
        // console.log('----546---', updatedStore.length)
        if (!updatedStore.length) {
          updatedStore = [{ value: "All", label: "All" }];
        }
        await dispatch(saveBranchArray(updatedStore));
        toastMessages("success", response.data.message);
        getAllBranch();
        setLoaderForFetchMore(true);
      }
    } catch (err) {
      getAllBranch();
      toastMessages("error", err.data.message);
    }
  }
  async function onSearchHandler(e) {
    setSearchedValue(e.target.value);
    let branches = branchArray.map((item) => item.value);
    const header = {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      branchid: JSON.stringify(branches),
    };
    try {
      let data = {
        branchname: e.target.value,
      };
      const response = await apiPostMethod(
        allApiUrl.search_branch,
        data,
        header
      );
      if (response.status === 200) {
        if (response.data.message == "No records found.") {
          //eslint-disable-line
          setData("");
          setLoaderForFetchMore(false);
          setLastEvaluatedKey("");
        } else {
          setData(response.data.data.Items);
          setLastEvaluatedKey(response.data.data.LastEvaluatedKey.SK);
        }
      }
    } catch (err) {
      console.log("err", err);
    }
  }
  const SortingComponent = ({ field, sortingType }) => {
    return (
      <div className="pointer">
        {sortingType === "Up" ? (
          <BsArrowUp onClick={() => handleSorting("Down", field)} />
        ) : (
          <BsArrowDown onClick={() => handleSorting("Up", field)} />
        )}
      </div>
    );
  };
  function handleSorting(arrow, field) {
    if (field === "index") {
      setSortingTypeIndex(arrow);
      const sortedData = data.slice().reverse();
      setData(sortedData);
    } else if (field === "name") {
      const newSortingType = sortingTypeName === "Down" ? "Up" : "Down";
      setSortingTypeName(newSortingType);
      const sortedItems = [...data].sort((a, b) => {
        if (a.branchname < b.branchname) {
          return newSortingType === "Up" ? -1 : 1;
        }
        if (a.branchname > b.branchname) {
          return newSortingType === "Up" ? 1 : -1;
        }
        return 0;
      });
      setData(sortedItems);
    } else if (field === "city") {
      const newSortingTypeCity = sortingTypeCity === "Down" ? "Up" : "Down";
      setSortingTypeCity(newSortingTypeCity);
      const sortedItems = [...data].sort((a, b) => {
        if (a.city < b.city) {
          return newSortingTypeCity === "Up" ? -1 : 1;
        }
        if (a.city > b.city) {
          return newSortingTypeCity === "Up" ? 1 : -1;
        }
        return 0;
      });
      setData(sortedItems);
    }
  }
  return (
    <div>
      <div className="headingText headingText--dflex headingText--textLeft headingText--border">
        <h4>All Stores</h4>
        <Form className="searchBarForm">
          <InputGroup className="searchBar">
            <InputGroup.Text id="searchOrders">
              <i className="fa-solid fa-magnifying-glass"></i>
            </InputGroup.Text>
            <FloatingLabel controlId="floatingInput" label="Search">
              <Form.Control
                type="search"
                placeholder="Search"
                value={searchedValue}
                onChange={onSearchHandler}
                onKeyDown={(e) => {
                  if (e.code === "Enter") {
                    e.preventDefault();
                  }
                }}
              />
            </FloatingLabel>
          </InputGroup>
        </Form>
      </div>
      <div className="commonTable">
        <div className="whiteBg">
          <InfiniteScroll
            dataLength={data.length}
            next={fetchMoreData}
            hasMore={loaderForFetchMore}
            // loader={<Spinner />}
            height={400}
          >
            <div className="tableFixHead">
              {!data.length && isLoading ? (
                <Loader />
              ) : (
                <Table>
                  <thead>
                    <tr key={uuidv4()}>
                      <th>
                        S.No.{" "}
                        {/* <SortingComponent field='index' sortingType={sortingTypeIndex} /> */}
                      </th>
                      <th>Store Image</th>
                      <th>
                        <div className="display_flex">
                          Store Name{" "}
                          <SortingComponent
                            field="name"
                            sortingType={sortingTypeName}
                          />
                        </div>
                      </th>
                      <th>
                        <div className="display_flex">
                          City{" "}
                          <SortingComponent
                            field="city"
                            sortingType={sortingTypeCity}
                          />
                        </div>
                      </th>
                      <th>Zip Code</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data && data.length > 0 ? (
                      data.map((item, index) => {
                        return (
                          <tr key={item.SK}>
                            <td>{`#${index + 1}`} </td>
                            <td>
                              {item?.image?.thumbnail ? (
                                <img
                                  src={item?.image?.thumbnail}
                                  height={40}
                                  width={50}
                                  className="images_thumbnail productImg"
                                />
                              ) : (
                                <img
                                  src={dummyProduct}
                                  height={40}
                                  width={50}
                                  className="images_thumbnail"
                                />
                              )}
                            </td>
                            <td>{titleCase(item.branchname)}</td>
                            <td>{titleCase(item.city)}</td>
                            <td>{item.zipcode}</td>
                            <td>
                              <div className="actionElements">
                                <Form>
                                  <Form.Check
                                    type="switch"
                                    id="custom-switch"
                                    label=""
                                    checked={
                                      item.branchstatus == 1 ? true : false
                                    } //eslint-disable-line
                                    onChange={(e) =>
                                      updateActiveStatus(e, item.SK)
                                    }
                                  />
                                </Form>
                                <button
                                  className="edit btn"
                                  onClick={() =>
                                    history.push(`/edit-store?id=${item.SK}`, {
                                      id: item.SK,
                                    })
                                  }
                                >
                                  <span>Edit</span>{" "}
                                  <i className="fa-solid fa-pen-to-square"></i>
                                </button>
                                <Button
                                  type="button"
                                  className="delete"
                                  onClick={() => onDeleteHandler(item.SK)}
                                >
                                  <>
                                    {props.deletedId == item.SK && //eslint-disable-line
                                      loader ? (
                                      <Spinner
                                        as="span"
                                        className="delete"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                      />
                                    ) : (
                                      <>
                                        <span>Delete</span>{" "}
                                        <i className="fa-solid fa-trash-can"></i>
                                      </>
                                    )}
                                  </>
                                </Button>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr key={uuidv4()}>
                        <td>No Stores Available.</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              )}
            </div>
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
};
