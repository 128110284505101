import ForgotPassword from './forgotPassword';
import ChangePassword from './changePassword';
import SignUp from './signup';
import Login from './login';

export {
    ForgotPassword,
    ChangePassword,
    SignUp,
    Login
};