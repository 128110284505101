import React, { useState, useEffect } from "react";
import {
  Button,
  Container,
  Row,
  Col,
  Form,
} from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { allApiUrl } from "../../../api/apiRoute";
import { apiGetMethod, apiPostMethod } from "../../../api/rest";
import CommonButton from "../../common-ui/Button";
import SideBanner from "../sideBanner";
import toastMessages from "../../toastMessages";
import { saveBranchArray, setTypeOfUser } from "../../../features/todoSlice";
import { useDispatch, useSelector } from "react-redux";

function Login() {
  let typeOfUser = useSelector((state) => state.counter.typeOfUser)
  // console.log('----388-----', typeOfUser)
  const history = useHistory();
  let dispatch = useDispatch()
  useEffect(() => {
    if (window.location.pathname == "/") {
      history.push("/login")
    }
  }, [])
  const [form, setForm] = useState({
    email: "",
    password: "",
  });
  const [loginResponse, setLoginResponse] = useState(null);
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    if (localStorage.getItem("isLoggedIn") === "true") {
      if (localStorage.getItem('developerAccessToken')) {
        if (typeOfUser == "developerUser") {
          history.push('/organizations')
        }
        else {
          history.push('/dashboard')
        }
      }
      else {
        history.push("/dashboard");
      }
    }
  }, []);
  async function updateRole(token) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    let res = await apiGetMethod(allApiUrl.updateRoleLevel, "", header);
    if (res.status === 200) {
    }
  }

  async function onSubmit(e) {
    e.preventDefault();
    setLoader(true);
    try {
      const response = await apiPostMethod(allApiUrl.login, form, "");
      if (response.status === 200) {
        if (response.data.user.roleId == "Developer") {
          history.push("/organizations");
        }
        else {
          history.push("/dashboard");
          await dispatch(saveBranchArray([{ value: "All", label: 'All' }]))
        }
        setLoader(false);
        // updateRole(response.data.token)
        toastMessages("success", response.data.message);
        localStorage.setItem("isLoggedIn", true);
        localStorage.setItem("accessToken", response.data.token);
        localStorage.setItem("userInfo", JSON.stringify(response.data.user));
        await dispatch(setTypeOfUser('organizationUser'))
        if (response.data.user.roleId == "Developer") {
          localStorage.setItem("developerUserInfo", JSON.stringify(response.data.user));
          localStorage.setItem("developerAccessToken", response.data.token);
          await dispatch(setTypeOfUser('developerUser'))
        }
      }
    } catch (err) {
      console.log("err", err);
      setLoader(false);
      toastMessages("error", err.data.message);
      setLoginResponse(err.data);
    }
  }
  const [showPass, setShowPass] = useState(false);
  function clickHandler() {
    setShowPass(!showPass);
  }
  return (
    <React.Fragment>
      <section className="formWrapper">
        <Container fluid>
          <Row className="flex-row-reverse">
            <SideBanner />
            <Col xl={5} lg={6} className="formGrid">
              <div className="formOuter">
                <div className="formOuter__inner">
                  <h1>LOGIN</h1>
                  <Form onSubmit={onSubmit}>
                    <Form.Group className="form-group">
                      <Form.Label>Username</Form.Label>
                      <Form.Control
                        required
                        placeholder="Enter Username or Email"
                        className={`formField ${loginResponse && loginResponse.message == "You have entered an invalid credential." && "error"} notCapital`}
                        type="text"
                        value={form.email}
                        onChange={(e) => {
                          setLoginResponse(null)
                          setForm({ ...form, email: e.target.value })
                        }
                        }
                      />
                      {loginResponse &&
                        loginResponse.message == "You have entered an invalid credential." && (  //eslint-disable-line
                          <p className="error">Incorrect Username or Email</p>
                        )}
                    </Form.Group>
                    <Form.Group className="form-group">
                      <Form.Label>Password{""} <Link className="forgotText" to="/forgotpassword">Forgot Password?</Link></Form.Label>
                      <div className="passwordWrap">
                        <Form.Control
                          required
                          placeholder="Enter Password"
                          className={`formField ${loginResponse && loginResponse.message == "You have entered the incorrect password please try again.!" && "error"} notCapital`}
                          type={`${!showPass ? "password" : "text"}`}
                          value={form.password}
                          onChange={(e) => {
                            setLoginResponse(null)
                            setForm({ ...form, password: e.target.value })
                          }
                          }
                        />
                        {loginResponse &&
                          loginResponse.message == "You have entered the incorrect password please try again.!" && (  //eslint-disable-line
                            <p className="error">Incorrect Password</p>
                          )}
                        <Button className="showPassIcon" type="button">
                          <i onClick={clickHandler} className={showPass ? "fas fa-eye" : "fas fa-eye-slash"}
                          ></i>
                        </Button>
                      </div>
                    </Form.Group>
                    <CommonButton
                      buttonName="Login"
                      type="fullWidth"
                      loader={loader}
                    />
                    {/* <span className="bottomText">
                      Don’t have an account?{" "}
                      <Link className="linkText" to="/signup">Sign up</Link>
                    </span> */}
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
}
export default Login;
