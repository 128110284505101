import React, { useEffect, useState } from "react";
import Select from 'react-select';
import { Button, Table, Container, Row, Col, Form, Pagination } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
//Header
import Header from '../common/header';
import Sidebar from '../common/sidebar';
import { apiGetMethod, apiPostMethod } from "../../api/rest";
import { allApiUrl } from "../../api/apiRoute";
import toastMessages from "../toastMessages";
import CommonButton from "../common-ui/Button";
import { CategoriesTable } from "../categories";
import { useSelector } from "react-redux";
import SidebarTwo from "../common/sidebarTwo";

function AddNewCategories() {
    let sidebarToggle = useSelector((state) => state.counter.sidebarToggle)
    let branchArray = useSelector((state) => state.counter.branchArray)
    let branchid = useSelector((state) => state.counter.currBranch)
    const [permissionData, setPermissionData] = useState({})
    let branches = branchArray.map((item) => item.value)
    const header = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        branchid: JSON.stringify(branches)
    };
    const [isLoading, setIsLoading] = useState(true)
    const history = useHistory()
    const [loader, setLoader] = useState(false);
    const [category, setCategory] = useState({
        categoryname: '',
        description: ''
    });
    const [errorInput, setErrorInput] = useState({
        categoryname: false,
        description: false
    })
    const [allCategoriesList, setAllCategoriesList] = useState([])
    const [isCategoryPageRefreshed, setIsCategoryPageRefreshed] = useState(false)
    useEffect(() => {
        setIsLoading(true)
        getAllCategories()
        if ((branchArray.length > 1) || (branchArray && branchArray[0].value == "All")) {
            history.push('/dashboard')
        }
    }, [isCategoryPageRefreshed, branchArray])
    // get all categories
    async function getAllCategories() {
        try {
            const response = await apiGetMethod(allApiUrl.view_all_categories, "", header);
            if (response.status === 200) {
                setAllCategoriesList(response.data.categories.Items)
                // setIsCategoryPageRefreshed(false)
                setIsLoading(false)
                toastMessages("success", response.data.message);
            }
        } catch (err) {
            // setIsCategoryPageRefreshed(false)
            console.log("err", err);
        }
    }
    //Sidebar Toggle
    const [active, setActive] = React.useState(false);
    // Save category
    async function saveCategoryHandler(e) {
        e.preventDefault()
        setLoader(true);
        if (category.categoryname) {
            try {
                let data = {
                    name: category.categoryname.replace(/\//g, "/ "),
                    description: category.description
                }
                const response = await apiPostMethod(
                    allApiUrl.addCategories,
                    data,
                    header
                );
                if (response.status === 200) {
                    toastMessages("success", response.data.message);
                    setCategory({
                        categoryname: '',
                        description: ''
                    });
                    getAllCategories()
                    setLoader(false);
                }

            } catch (err) {
                setLoader(false);
                toastMessages("error", err.data.message);
            }
        }
        else {
            const emptyFields = [];
            for (const property in category) {
                if (category[property] === '') { emptyFields.push(property) }
            }
            const errorInputCopy = { ...errorInput };
            emptyFields.forEach(field => errorInputCopy[field] = true);
            setErrorInput(errorInputCopy)
            toastMessages("error", "Please fill the required field")
            setLoader(false);
        }

    }
    let props = {
        setCategory,
        category,
        errorInput,
        setErrorInput
    }
    const [sidebarType, setSidebarType] = useState('singleSidebar')
    let sidebarTypeRedux = useSelector((state) => state.counter.sidebarType)
    return (
        <React.Fragment>
            <Header setActive={setActive} active={sidebarToggle} page="categories" />
            <div className="interFaceWrap">
                <Sidebar active={sidebarToggle} activePage="categories" permissionData={permissionData} />
                <div className={`interFaceWrap__inner ${sidebarTypeRedux == false ? "doubleSidebar" : "singleSidebar"}`}>
                    <div className="commonOuter">
                        <div className="pageHeader">
                            <Container fluid className="px-0">
                                <Row className="align-items-center">
                                    <div className="col">
                                        <div className="headingText headingText--textLeft mb-0">
                                            <h1>Create Categories</h1>
                                        </div>
                                    </div>
                                    <div className="col-auto ms-auto onlyButtons">
                                        <div className="pageHeader__elements">
                                            <CommonButton
                                                buttonName="Save"
                                                type="half"
                                                loader={loader}
                                                onClick={saveCategoryHandler}
                                            />
                                            {/* <Button type="button" className="commonButton" onClick={saveCategoryHandler}>Save</Button> */}
                                        </div>
                                    </div>
                                </Row>
                            </Container>
                        </div>
                        <div className="selectCategoriesOpetions">
                            <CategoriesItem
                                {...props}
                            />
                        </div>
                        <CategoriesTable
                            branchArray={branchArray}
                            setAllCategoriesList={setAllCategoriesList}
                            isLoading={isLoading}
                            allCategoriesList={allCategoriesList}
                            setIsCategoryPageRefreshed={setIsCategoryPageRefreshed} />
                    </div>
                </div>
                <SidebarTwo activePage="" setSidebarType={setSidebarType} sidebarType={sidebarType} setPermissionData={setPermissionData} />
            </div>
        </React.Fragment>
    )
}
const CategoriesItem = ({ setCategory, category, errorInput, setErrorInput }) => {
    return (
        <Row>
            <Col xl={6} lg={8}>
                <div className="whiteBg">
                    <Form>
                        <Row>
                            <Col md={6}>
                                <Form.Group className="form-group">
                                    <Form.Label>Category Name</Form.Label>
                                    <Form.Control
                                        className={`${errorInput.categoryname && "error"}`}
                                        type="text" placeholder="name" value={(category.categoryname)} onChange={(e) => {
                                            if (e.target.value.length < 31) {
                                                setErrorInput({ ...errorInput, categoryname: false })
                                                setCategory({ ...category, categoryname: e.target.value })
                                            }
                                        }
                                        }
                                    />
                                    {errorInput.categoryname && <small>Category Name is required!</small>}
                                </Form.Group>
                            </Col>
                            <Col md={12}>
                                <Form.Group className="form-group">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control as="textarea"
                                        className={`${errorInput.description && "error"}`}
                                        placeholder="description" value={category.description} onChange={(e) => {
                                            setCategory({ ...category, description: e.target.value })
                                            setErrorInput({ ...errorInput, description: false })
                                        }
                                        } />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Col>
            <Col xl={6} lg={4}></Col>
        </Row>
    )
}
export { CategoriesItem };
export default AddNewCategories;
