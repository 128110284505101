import React, { useState, useEffect } from "react";
import Select from 'react-select';
import { Button, Table, Container, Spinner, Row, Col, Form, FloatingLabel, InputGroup } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import PhoneInput from "react-phone-input-2";
import { BsArrowDown, BsArrowUp } from 'react-icons/bs';
import "react-phone-input-2/lib/bootstrap.css";
import Header from '../common/header';
import { smallStyle } from "../../features/helper";
import Sidebar from '../common/sidebar';
import { apiDeleteMethod, apiGetMethod, apiPostMethod, apiPutMethod } from "../../api/rest";
import { allApiUrl } from "../../api/apiRoute";
import toastMessages from "../toastMessages";
import CommonButton from "../common-ui/Button";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { EmployeFields } from "../add-employee";
import { countNumberInPhoneNumber } from "../../features/helper";
import Loader from "../shared/spinner";
import { v4 as uuidv4 } from 'uuid';
import SidebarTwo from "../common/sidebarTwo";
import { changeTableManagement, changeWebsiteTemplate } from "../../features/todoSlice";

function Users() {
    const dispatch = useDispatch()
    const history = useHistory()
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const branchArray = useSelector((state) => state.counter.branchArray)
    const [permissionData, setPermissionData] = useState({})
    let branches = branchArray.map((item) => item.value)
    const header = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        branchid: JSON.stringify(branches)
    };
    const [loader, setLoader] = useState({
        save: false,
        delete: false
    })
    const [form, setForm] = useState({
        fullname: '',
        username: '',
        email: '',
        password: '',
        pin: '',
        countrycode: '+1',
        phone: '',
        roleId: {},
        branch: [],
    })
    // console.log('branchArray-----22---------', branchArray)
    const [active, setActive] = React.useState(false)
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [isPhoneValid, setPhoneValid] = useState(false)
    const [countryCodeLimit, setCountryCodeLimit] = useState({
        status: false,
        error: ''
    })
    //Username Validation
    const [userNameValidation, setUserNameValidation] = useState([]);
    const [invalidUserName, setInvalidUserName] = useState(false);
    const [invalidUserNameError, setInvalidUserNameError] = useState("");
    const [userNameAvailable, setUsernameAvailable] = useState(true)
    const [error, setError] = useState(null);
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [phoneValidatError, setPhoneValidError] = useState(false);
    const [isEntryUpdated, setIsEntryUpdated] = useState(false)
    const [userList, setUserList] = useState([])
    const [deletedId, setDeletedId] = useState('')
    const [loaderForFetchMore, setLoaderForFetchMore] = useState(true)
    const [lastEvaluatedKey, setLastEvaluatedKey] = useState('')
    const [searchedValue, setSearchedValue] = useState('')
    const [sortingTypeIndex, setSortingTypeIndex] = useState("Up");
    const [sortingTypeName, setSortingTypeName] = useState("Up");
    const [sortingTypeUsername, setSortingTypeUsername] = useState("Up")
    const [sortingTypeEmail, setSortingTypeEmail] = useState("Up")
    const [sortingTypePin, setSortingTypePin] = useState("Up")
    const [errorInput, setErrorInput] = useState({
        fullname: false,
        username: false,
        email: false,
        password: false,
        pin: false,
        phone: false,
        branch: false
    })
    const [isLoading, setIsLoading] = useState(true)
    const [allBranchList, setAllBranchList] = useState([])
    console.log('isPasswordValid', isPasswordValid)
    useEffect(() => {
        // console.log('----91----BranchArray--------', branchArray.length)
        getAllUserAndEmployee()
        generatePasscodeHandler()
        // if ((branchArray.length > 1) || (branchArray && branchArray[0].value == "All")) {
        //     history.push('/dashboard')
        // }
    }, [isEntryUpdated, branchArray])
    useEffect(() => {
        getDisableStatus()
    }, [permissionData])
    useEffect(() => {
        setSortingTypeIndex("Up");
        setSortingTypeName("Up");
        setSortingTypeUsername("Up")
        setSortingTypeEmail("Up")
        setSortingTypePin("Up")
    }, [branchArray])
    async function getDisableStatus() {
        const header = {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            branchid: JSON.stringify(branches),
        };
        try {
            const response = await apiGetMethod(allApiUrl.viewSetting, "", header);
            if (response.status == 200) {
                if (response.data.Items.length != 0) {
                    //for table managment sidebar toggle functionality.
                    if (permissionData.tablemanagement == 0) {
                        console.log('116')
                        dispatch(changeTableManagement(false));
                        console.log('116')
                    }
                    else {
                        console.log('116')
                        if (response.data.Items[0].GeneralSettings.tablemanagement == 1) {
                            console.log('116')
                            dispatch(changeTableManagement(true));
                        } else {
                            console.log('116')
                            dispatch(changeTableManagement(false));
                        }
                    }
                    // for website builder
                    if (permissionData.websitetemplate == 0) {
                        dispatch(changeWebsiteTemplate(false));
                    }
                    else {
                        if (response.data.Items[0].WebsiteTemplate.enableWebsiteTemplate == 1) {
                            //eslint-disable-line
                            dispatch(changeWebsiteTemplate(true));
                        } else {
                            dispatch(changeWebsiteTemplate(false));
                        }
                    }

                }
            }
        } catch (err) {
            console.log("err", err);
        }
    }
    async function onAddUserAndEmployeeHandlers(e) {
        e.preventDefault();
        let branches = branchArray.map((item) => item.value)
        const header = {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            branchid: JSON.stringify(branches)
        };
        // console.log('102')
        setLoader({ ...loader, save: true });
        try {
            if (!Object.keys(form.roleId).length == 0) {
                if (form.roleId.label !== "Employee") {
                    //check if branch is selected or not
                    let isBranchEmpty = false
                    if (form.roleId.label !== "Corporate Admin") {
                        if (form.branch.length == 0) {
                            isBranchEmpty = true
                        }
                    }
                    if (form.username && form.password && form.fullname && form.pin && form.phone && !isBranchEmpty) {
                        if (
                            isPasswordValid === true &&
                            !userNameValidation &&
                            isEmailValid &&
                            isPhoneValid &&
                            !invalidUserName && countryCodeLimit.status === false
                        ) {
                            let data = { ...form, roleId: form.roleId.label }
                            const response = await apiPostMethod(allApiUrl.addUser, data, header);
                            if (response.status === 200) {
                                setLoader({ ...loader, save: false });
                                setIsEntryUpdated(!isEntryUpdated)
                                toastMessages("success", `User Created Successfully.`);
                                setForm({
                                    fullname: '',
                                    username: '',
                                    email: '',
                                    password: '',
                                    pin: '',
                                    countrycode: '+1',
                                    phone: '',
                                    roleId: '',
                                    branch: []
                                })
                            }
                        }
                        else if (isEmailValid === false) {
                            setLoader({ ...loader, save: false });
                            toastMessages("error", "Email is not valid.");
                        } else if (isPhoneValid === false) {
                            setLoader({ ...loader, save: false });
                            toastMessages("error", phoneValidatError);
                        } else if (invalidUserName === true) {
                            setLoader({ ...loader, save: false });
                            toastMessages("error", invalidUserNameError);
                        } else if (userNameValidation && userNameValidation.length > 0) {
                            setLoader({ ...loader, save: false });
                            toastMessages("error", "Please use special character after entering any text.");
                        } else {
                            setLoader({ ...loader, save: false });
                            toastMessages("error", "The password is not valid");
                        }
                    }
                    else {
                        setLoader({ ...loader, save: false });
                        const emptyFields = [];
                        for (const property in form) {
                            if (form[property] === '') {
                                emptyFields.push(property)
                            }
                            if (property == "branch") {
                                if (form[property].length == 0) {
                                    emptyFields.push(property)
                                }
                            }
                        }
                        // console.log('167', emptyFields)
                        const errorInputCopy = { ...errorInput };
                        emptyFields.forEach(field => errorInputCopy[field] = true);
                        setErrorInput(errorInputCopy);
                        toastMessages("error", "Please fill the required field")
                    }
                }
                else {
                    // console.log("180")
                    if (form.fullname && form.pin && form.branch.length > 0) {
                        // console.log("181")
                        try {
                            let data = {
                                fullname: form.fullname.trim(),
                                pin: form.pin,
                                roleId: form.roleId.label,
                                branch: form.branch,
                                permissiongranted: form.permissiongranted
                            }
                            const response = await apiPostMethod(
                                allApiUrl.addEmployee,
                                data,
                                header
                            );
                            if (response.status === 200) {
                                toastMessages("success", "Employee Added Successfully.");
                                setForm({
                                    fullname: '',
                                    username: '',
                                    email: '',
                                    password: '',
                                    pin: '',
                                    countrycode: '+1',
                                    phone: '',
                                    roleId: '',
                                    branch: []
                                })
                                setIsEntryUpdated(!isEntryUpdated)
                                setLoader(false);
                            }
                        } catch (err) {
                            toastMessages("error", err.data.message);
                            setLoader(false);
                            console.log("err", err);
                        }
                    }
                    else {
                        // console.log('218')
                        let employeeFields = ['fullname', 'pin', 'branch']
                        let emptyFields = []
                        employeeFields.forEach((property) => {
                            if (form[property] === '') {
                                emptyFields.push(property)
                            }
                            if (property == "branch") {
                                // console.log('207', form[property].length)
                                if (form[property].length == 0) {
                                    emptyFields.push(property)
                                }
                            }
                        })
                        const errorInputCopy = { ...errorInput };
                        emptyFields.forEach(field => errorInputCopy[field] = true);
                        setErrorInput(errorInputCopy);
                        toastMessages("error", "Please fill the required fields")
                        setLoader(false);
                    }
                }
            }
            else {
                setLoader({ ...loader, save: false })
                toastMessages("error", "Select at least one role before saving.")
            }

        } catch (err) {
            setLoader({ ...loader, save: false });
            toastMessages("error", err.data.message);
            console.log("err", err);
        }
    }
    async function getAllUserAndEmployee() {
        setIsLoading(true)
        let branches = branchArray.map((item) => item.value)
        const header = {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            branchid: JSON.stringify(branches)
        };
        // console.log('--------256------', header)
        try {
            const response = await apiGetMethod(`${allApiUrl.allUserAndEmployee}?origin=webApp`, "", header);
            if (response.status === 200) {
                setUserList(response.data.data.Items.sort((a, b) => a.id - b.id))
                setIsLoading(false)
                if (response.data.data.Items.length < 5) {
                    setLoaderForFetchMore(false)
                }
                else {
                    setLoaderForFetchMore(true)
                }
                if (response.data.data.LastEvaluatedKey) {
                    setLastEvaluatedKey(response.data.data.LastEvaluatedKey.SK)
                }
                // setIsEntryUpdated(false)
                toastMessages("success", response.data.message);
            }
        } catch (err) {
            setIsLoading(false)
            toastMessages("error", err.data.message);
            console.log("err", err);
        }
    }
    async function onDeleteHandler(id) {
        setDeletedId(id)
        setLoader({ ...loader, delete: true })
        try {
            const response = await apiDeleteMethod(allApiUrl.delete_user, id, header);
            if (response.status === 200) {
                setLoader({ ...loader, delete: false })
                setIsEntryUpdated(!isEntryUpdated)
                toastMessages("success", response.data.message);
            }
        } catch (err) {
            setLoader({ ...loader, delete: false })
            toastMessages("error", err.data.message);
            setIsEntryUpdated(!isEntryUpdated)
            console.log("err", err);
        }
    }
    async function updateActiveStatus(e, value, roleId) {
        const header = {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        };
        try {
            let url = roleId == "Employee" ? allApiUrl.update_employee : allApiUrl.update_user
            const data = { userstatus: e.target.checked == true ? 1 : 0 }   //eslint-disable-line
            const response = await apiPutMethod(url, data, header, value)
            if (response.status === 200) {
                toastMessages("success", response.data.message)
                setIsEntryUpdated(!isEntryUpdated)
            }
        }
        catch (err) {
            toastMessages("error", err.data.message);
        }
    }
    async function onSearchHandler(e) {
        setSearchedValue(e.target.value)
        try {
            let data = {
                username: e.target.value
            }
            const response = await apiPostMethod(allApiUrl.search_user, data, header);
            if (response.status === 200) {
                if (response.data.message == "No records found.") {  //eslint-disable-line
                    setUserList('');
                    setLoaderForFetchMore(false)
                    setLastEvaluatedKey('')
                }
                else {
                    setUserList(response.data.data.Items);
                    if (response.data.data.LastEvaluatedKey) {
                        setLastEvaluatedKey(response.data.data.LastEvaluatedKey.SK)
                    }
                }
            }
        } catch (err) {
            toastMessages("error", err.data.message);
            console.log("err", err);
        }
    }
    const fetchMoreData = async () => {
        if (lastEvaluatedKey) {
            setLoaderForFetchMore(true)
            const header = {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            };
            try {
                let start = ''
                if (lastEvaluatedKey) {
                    start = `&startKey=${lastEvaluatedKey}`
                }
                const response = await apiGetMethod(`${allApiUrl.view_all_users}?${start}`, "", header);
                if (response.status === 200) {
                    if (response.data.data.Items.length < 1) {
                        setLoaderForFetchMore(false)
                    }
                    setUserList(prevItems => prevItems.concat(response.data.data.Items));
                    if (response.data.data.LastEvaluatedKey) {
                        setLastEvaluatedKey(response.data.data.LastEvaluatedKey.SK)
                    }
                    else {
                        setLastEvaluatedKey('')
                    }
                    toastMessages("success", response.data.message);
                }
            } catch (err) {
                toastMessages("error", err.data.message);
                console.log("err", err);
            }
        }
        else {
            setLoaderForFetchMore(false)
        }

    };
    function titleCase(str) {
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        return splitStr.join(' ');
    }
    async function generatePasscodeHandler() {
        try {
            const response = await apiGetMethod(allApiUrl.generate_pincode, "", header);
            if (response.status === 200) {
                let pin = response.data.code + ''
                setForm({ ...form, pin, roleId: {} })
            }
        } catch (err) {
            console.log('err', err)
        }
    }
    const SortingComponent = ({ field, sortingType }) => {
        return (
            <div className="pointer">
                {sortingType === "Up" ? (
                    <BsArrowUp onClick={() => handleSorting("Down", field)} />
                ) : (
                    <BsArrowDown onClick={() => handleSorting("Up", field)} />
                )}
            </div>
        );
    };
    function handleSorting(arrow, field) {
        if (field === "index") {
            // setSortingTypeIndex(arrow);
            // const sortedData = data.slice().reverse();
            // setData(sortedData);
        } else if (field === "name") {
            const newSortingType = sortingTypeName === "Down" ? "Up" : "Down";
            setSortingTypeName(newSortingType);
            const sortedItems = [...userList].sort((a, b) => {
                if (a.fullname.toLowerCase() < b.fullname.toLowerCase()) {
                    return newSortingType === "Up" ? -1 : 1;
                }
                if (a.fullname.toLowerCase() > b.fullname.toLowerCase()) {
                    return newSortingType === "Up" ? 1 : -1;
                }
                return 0;
            });
            setUserList(sortedItems);
        }
        else if (field === "username") {
            const newSortingType = sortingTypeUsername === "Down" ? "Up" : "Down";
            setSortingTypeUsername(newSortingType);
            const sortedItems = [...userList].sort((a, b) => {
                if (a.SK.toLowerCase() < b.SK.toLowerCase()) {
                    return newSortingType === "Up" ? -1 : 1;
                }
                if (a.SK.toLowerCase() > b.SK.toLowerCase()) {
                    return newSortingType === "Up" ? 1 : -1;
                }
                return 0;
            });
            setUserList(sortedItems);
        }
        else if (field === "email") {
            const newSortingType = sortingTypeEmail === "Down" ? "Up" : "Down";
            setSortingTypeUsername(newSortingType);
            const sortedItems = [...userList].sort((a, b) => {
                if (a.email.toLowerCase() < b.email.toLowerCase()) {
                    return newSortingType === "Up" ? -1 : 1;
                }
                if (a.email.toLowerCase() > b.email.toLowerCase()) {
                    return newSortingType === "Up" ? 1 : -1;
                }
                return 0;
            });
            setUserList(sortedItems);
        }
        else if (field === "pin") {
            const newSortingType = sortingTypePin === "Down" ? "Up" : "Down";
            setSortingTypePin(newSortingType);
            const sortedItems = [...userList].sort((a, b) => {
                if (a.pin < b.pin) {
                    return newSortingType === "Up" ? -1 : 1;
                }
                if (a.pin > b.pin) {
                    return newSortingType === "Up" ? 1 : -1;
                }
                return 0;
            });
            setUserList(sortedItems);
        }
    }
    let props = {
        form,
        setForm,
        setIsPasswordValid,
        isPasswordValid,
        setPhoneValid,
        isPhoneValid,
        setCountryCodeLimit,
        countryCodeLimit,
        userNameAvailable,
        setUsernameAvailable,
        setInvalidUserName,
        setInvalidUserNameError,
        setUserNameValidation,
        setError,
        setIsEmailValid,
        setPhoneValidError,
        phoneValidatError,
        errorInput,
        setErrorInput,
        setAllBranchList,
        allBranchList,
        permissionData,
        setPermissionData
    }
    let sidebarToggle = useSelector((state) => state.counter.sidebarToggle)
    const [sidebarType, setSidebarType] = useState('singleSidebar')
    let sidebarTypeRedux = useSelector((state) => state.counter.sidebarType)
    console.log('permission', permissionData)
    return (
        <React.Fragment>
            <Header setActive={setActive} active={sidebarToggle} />
            <div className="interFaceWrap">
                <Sidebar active={sidebarToggle} activePage="users" permissionData={permissionData} />
                <div className={`interFaceWrap__inner ${sidebarTypeRedux == false ? "doubleSidebar" : "singleSidebar"}`}>
                    <div className="pageHeader">
                        <Container fluid className="px-0">
                            <Row className="align-items-center">
                                <div className="col">
                                    <div className="headingText headingText--textLeft mb-0">
                                        <h1 >Add User</h1>
                                    </div>
                                </div>
                                <div className="col-auto ms-auto onlyButtons">
                                    {Object.keys(form.roleId).length > 0 &&
                                        <div className="pageHeader__elements">
                                            <CommonButton
                                                buttonName="Save"
                                                type="half"
                                                onClick={onAddUserAndEmployeeHandlers}
                                                loader={loader.save}
                                            />
                                        </div>
                                    }

                                </div>
                            </Row>
                        </Container>
                    </div>
                    <UserItem {...props} />
                    <div className="commonTable">
                        <div className="whiteBg">
                            <div className="headingText headingText--dflex headingText--textLeft headingText--border">
                                <h4>All Users</h4>
                                <Form className="searchBarForm">
                                    <InputGroup className="searchBar">
                                        <InputGroup.Text id="searchOrders"><i className="fa-solid fa-magnifying-glass"></i></InputGroup.Text>
                                        <FloatingLabel controlId="floatingInput" label="Search name or email">
                                            <Form.Control type="search" placeholder="Search Name and Email" value={searchedValue} onChange={onSearchHandler} onKeyDown={(e) => {
                                                if (e.code === 'Enter') {
                                                    e.preventDefault()
                                                }
                                            }} />
                                        </FloatingLabel>
                                    </InputGroup>
                                </Form>
                            </div>
                            <InfiniteScroll
                                dataLength={userList.length}
                                next={fetchMoreData}
                                hasMore={loaderForFetchMore}
                                // loader={<Spinner />}
                                height={400}>
                                <div className="tableFixHead">
                                    {isLoading ? <Loader /> :
                                        <Table>
                                            <thead>
                                                <tr key={uuidv4()}>
                                                    <th>S.No.</th>
                                                    <th><div className="display_flex">Name
                                                        <SortingComponent field='name' sortingType={sortingTypeName} />
                                                    </div></th>
                                                    {/* <th>
                                                        <div className="display_flex">
                                                            Username  <SortingComponent field='username' sortingType={sortingTypeUsername} />
                                                        </div>
                                                    </th> */}
                                                    <th>
                                                        <div className="display_flex">
                                                            Email <SortingComponent field='email' sortingType={sortingTypeEmail} />
                                                        </div>
                                                    </th>
                                                    <th>Roles</th>
                                                    <th>
                                                        <div className="display_flex">
                                                            PIN <SortingComponent field='pin' sortingType={sortingTypePin} />
                                                        </div>
                                                    </th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {userList && userList.length > 0 ? userList.map((item, index) => {
                                                    return (
                                                        <tr key={item.id}>
                                                            <td>{`#${index + 1}`}</td>
                                                            <td>{titleCase(item.fullname)}</td>
                                                            {/* <td>{item.roleId !== "Employee" ? item.SK : ''}</td> */}
                                                            <td>{item.email}</td>
                                                            <td>{item.roleId}</td>
                                                            <td>{item.pin}</td>
                                                            <td>
                                                                <div className="actionElements">
                                                                    <Form><Form.Check type="switch" id="custom-switch" label=""
                                                                        checked={item.userstatus == 1 ? true : false}  //eslint-disable-line
                                                                        onChange={(e) => updateActiveStatus(e, item.SK, item.roleId)}
                                                                    /></Form>
                                                                    <button className="edit btn"
                                                                        onClick={() => {
                                                                            if (userInfo.roleId == "Corporate Admin") {
                                                                                history.push(`/edit-user?id=${item.SK}`)
                                                                            }
                                                                            else {
                                                                                if (userInfo.rolelevel <= parseInt(item.rolelevel)) {
                                                                                    toastMessages('error', 'You are not authorized to edit this user')
                                                                                }
                                                                                else {
                                                                                    history.push(`/edit-user?id=${item.SK}`)
                                                                                }
                                                                            }
                                                                        }
                                                                        }
                                                                    ><span>Edit</span> <i className="fa-solid fa-pen-to-square"></i></button>
                                                                    <Button type="button" className="delete"
                                                                        onClick={() => {
                                                                            if (userInfo.roleId == "Corporate Admin") { onDeleteHandler(item.SK) }
                                                                            else {
                                                                                if (userInfo.rolelevel <= parseInt(item.rolelevel)) { toastMessages('error', 'You are not authorized to delete this user') } else { onDeleteHandler(item.SK) }
                                                                            }
                                                                        }}
                                                                    ><>{deletedId == item.SK &&  //eslint-disable-line
                                                                        loader.delete ? <Spinner
                                                                        as="span"
                                                                        className="delete"
                                                                        animation="border"
                                                                        size="sm"
                                                                        role="status"
                                                                        aria-hidden="true"
                                                                    /> : <><span>Delete</span> <i className="fa-solid fa-trash-can"></i></>} </>
                                                                    </Button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                }) :
                                                    <tr key={uuidv4()}><td colSpan={6} className="text-center" >No User Available.</td></tr>
                                                }
                                            </tbody>
                                        </Table>}
                                </div>
                            </InfiniteScroll>
                        </div>
                    </div>
                </div>
                <SidebarTwo
                    activePage=""
                    setSidebarType={setSidebarType}
                    sidebarType={sidebarType}
                    setPermissionData={setPermissionData}
                />
            </div>
        </React.Fragment >
    )
}
export default Users;

const UserItem = ({
    form,
    setForm,
    setIsPasswordValid,
    isPasswordValid,
    setPhoneValid,
    isPhoneValid,
    userNameAvailable,
    setUsernameAvailable,
    setUserNameValidation,
    setInvalidUserNameError,
    setInvalidUserName,
    setError,
    error,
    setIsEmailValid,
    setPhoneValidError,
    phoneValidatError,
    errorInput,
    setErrorInput,
    setAllBranchList,
    allBranchList
}) => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const currBranch = useSelector((state) => state.counter.currBranch)
    const branchArray = useSelector((state) => state.counter.branchArray)
    let branches = branchArray.map((item) => item.value)
    const header = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        branchid: JSON.stringify(branches)
    };
    const typeOfUser = userInfo?.usertype?.split('#')[0]
    let roleLevel = userInfo?.rolelevel
    const history = useHistory()
    const [showPassword, setShowPassword] = useState(false)
    const [showPin, setShowPin] = useState(false)
    const [options, setOptions] = useState([
        { label: "Corporate Admin", value: "90" },
        { label: "Owner", value: "80" },
        { label: "Manager", value: "70" },
        { label: "Employee", value: "60" },
    ])
    const specialCharacters = /[!@#$%^&*(),.?":{}|<>]/;

    useEffect(() => {
        if (typeOfUser == "USER") {
            getLoggedInUserDetail()
        }
        else {
            getAllBranch()
        }
        let modifiedOptions = []
        if (roleLevel < 90) {
            modifiedOptions = options.filter((item) => parseInt(item.value) < roleLevel)
            setOptions(modifiedOptions)
        }

    }, [])
    function clickHandlerPassword() {
        setShowPassword(!showPassword);
    }
    async function getLoggedInUserDetail() {
        const response = await apiGetMethod(allApiUrl.view_user, `/${userInfo.SK}`, header);
        if (response.status === 200) {
            getAllBranch(response.data.data.Items[0])
        }
    }
    function clickHandlerPin() {
        setShowPin(!showPin);
    }

    function handlePasswordChange(event) {
        if (event.target.value.length < 25) {
            setForm({ ...form, password: event.target.value });
            setErrorInput({ ...errorInput, password: false })
            setIsPasswordValid(validatePassword(event.target.value));
        }

    }
    function validatePassword(password) {
        const regex =
            /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*_#?&])([a-zA-Z0-9@$!%*_#?&]{8,})$/;
        return regex.test(password);
    }
    async function checkUserNameAvailabilty(e) {
        if (e.target.value) {
            try {
                const response = await apiPostMethod(allApiUrl.check_username, { username: e.target.value.trim() }, "");
                if (response.status === 200) {
                    setUsernameAvailable(true)
                }
            } catch (err) {
                console.log('err', err)
                setUsernameAvailable(false)

            }
        }
    }
    function containsOnlySpecialChars(str) {
        return /^[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/.test(str); //eslint-disable-line
    }
    function userNameContainingOnlyDigitAndSpecial(e) {
        let isnum = /^\d+$/.test(e.target.value);
        if (containsOnlySpecialChars(e.target.value) || isnum) {
            setInvalidUserName(true);
            setInvalidUserNameError(
                "username cannot contain only digit or special character"
            );
        } else {
            setInvalidUserName(false);
        }
    }
    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }
    const emailValidation = (e) => {
        if (e.target.value.length < 81) {
            setErrorInput({ ...errorInput, email: false })
            setForm({ ...form, email: e.target.value });
            setError(null);
            if (isValidEmail(e.target.value)) {
                let check = e.target.value.split("@")[0];
                let isnum = /^\d+$/.test(check);

                if (isnum === true || containsOnlySpecialChars(check)) {
                    setIsEmailValid(false);
                    setError("The email address field must be a valid email");
                } else {
                    setIsEmailValid(true);
                    setError("email is valid");
                }
            } else {
                setIsEmailValid(false);
                setError("The email address field must be a valid email");
            }
        } else {
            setIsEmailValid(false);
            setError("Email cann't be greater than 80 chars.");
        }
    };
    async function getAllBranch(assignedData) {
        const header = {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        };
        try {
            const response = await apiGetMethod(allApiUrl.active_branches, "", header);
            if (response.status === 200) {
                console.log('assignedData', assignedData)
                if (typeOfUser == "USER" && assignedData.rolelevel < 90) {  //eslint-disable-line
                    // console.log('746')
                    assignedData.branch.forEach((userItem) => {
                        response.data.data.Items.forEach((item) => {
                            if (userItem.value == item.SK) {  //eslint-disable-line
                                setAllBranchList((prev) => [...prev, { label: titleCase(item.branchname), value: item.SK }])
                            }
                        })
                    })
                }
                else {
                    let branch = response.data.data.Items.map((item) => {
                        return (
                            {
                                label: titleCase(item.branchname),
                                value: item.SK
                            }
                        )
                    })
                    setAllBranchList(branch)
                }

                setShowPin(true)
                toastMessages("success", response.data.message);
            }
        } catch (err) {
            toastMessages("error", err.data.message);
            console.log("err", err);
        }
    }
    // console.log('779', form)
    function titleCase(str) {
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        return splitStr.join(' ');
    }
    const handleOnChange = (value, country) => {
        setErrorInput({ ...errorInput, phone: false })
        setForm({ ...form, phone: value });
        const result = countNumberInPhoneNumber(country.format, ".");
        var zeroCount = value.toString().replace("0.", "").split("0").length - 1;
        if (zeroCount > 9) {
            setPhoneValid(false);
            setPhoneValidError(
                `Enter a valid phone number`
            );
        }
        else {
            if (value.length - country.dialCode.length == result - country.dialCode.length || (result - country.dialCode.length) > 10) {
                setPhoneValid(true);
            } else {
                setPhoneValid(false);
                setPhoneValidError(`The Phone Number should be of ${result - country.dialCode.length} Digit`);
            };
        }
    };
    useEffect(() => {
        setErrorInput({
            name: false,
            username: false,
            email: false,
            password: false,
            pin: false,
            phoneNumber: false,
            branch: false
        })
    }, [form.roleId])

    let props = {
        setForm,
        form,
        errorInput,
        setErrorInput,
        smallStyle
    }
    // console.log('errorInput', errorInput)
    // console.log('form', form)
    return (
        <div className="selectEmployeeOpetions">
            <Row>
                <Col xxl={7} xl={8} lg={12}>
                    <div className="whiteBg">
                        <Form>
                            <Row>
                                <Col md={6}>
                                    <Form.Group className="form-group">
                                        <Form.Label>Select Role<sup>*</sup></Form.Label>
                                        <Select
                                            className="select2 multiSelect"
                                            options={options}
                                            value={form.roleId}
                                            onChange={(e) => {

                                                let preSelectedBranch = allBranchList.find((item) => item.value == (branchArray && branchArray[0].value))
                                                let branch = []
                                                if (e.label == "Corporate Admin") {
                                                    branch = allBranchList
                                                }
                                                else {
                                                    if ((branchArray && branchArray[0].value) !== "All") {
                                                        branch.push(preSelectedBranch)
                                                    }
                                                }
                                                setForm({ ...form, roleId: e, branch, rolelevel: parseInt(e.value) })
                                            }}
                                            labelledBy="Select" />
                                    </Form.Group>
                                </Col>
                                {form?.roleId?.label === "Employee" ?
                                    <Col md={6}>
                                        <Form.Group className="form-group">
                                            <Form.Label>Store Access<sup>*</sup></Form.Label>
                                            <Select className="select2 multiSelect"
                                                options={allBranchList}
                                                value={form.branch}
                                                onChange={(e) => {
                                                    setErrorInput({ ...errorInput, branch: false })
                                                    setForm({ ...form, branch: [e] })
                                                }}
                                                labelledBy="Select" />
                                            {errorInput.branch && <small style={smallStyle}>Store Access is required!</small>}
                                        </Form.Group>
                                    </Col> : <Col md={6}></Col>}
                                {!Object.keys(form.roleId).length == 0 ? form.roleId.label !== "Employee" ?
                                    <>
                                        <Col md={6}>
                                            <Form.Group className="form-group">
                                                <Form.Label>Name<sup>*</sup></Form.Label>
                                                <Form.Control type="text" placeholder=""
                                                    value={(form.fullname)}
                                                    className={`${errorInput.fullname ? "error" : ''}`}
                                                    onChange={(e) => {
                                                        const alphabetRegex = /^[a-zA-Z .]*$/;
                                                        if (e.target.value.length < 61) {
                                                            if (e.target.value.match(alphabetRegex)) {
                                                                setErrorInput({ ...errorInput, fullname: false })
                                                                setForm({
                                                                    ...form,
                                                                    fullname: e.target.value,
                                                                });
                                                            }
                                                        }
                                                    }}
                                                />
                                                {errorInput.fullname && <small>Name is required!</small>}
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="form-group">
                                                <Form.Label>Username<sup>*</sup></Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder=""
                                                    className={`${errorInput.username ? "error" : ''} notCapital`}
                                                    value={form.username}
                                                    onChange={(e) => {
                                                        setUserNameValidation(
                                                            e.target.value
                                                                .charAt(0)
                                                                .match(specialCharacters)
                                                        );
                                                        userNameContainingOnlyDigitAndSpecial(e);
                                                        checkUserNameAvailabilty(e)
                                                        const alphabetRegex = /^[a-zA-Z0-9@_.]*$/;
                                                        if (e.target.value.length < 61) {
                                                            if (e.target.value.match(alphabetRegex)) {
                                                                setForm({
                                                                    ...form,
                                                                    username: e.target.value,
                                                                });
                                                            }
                                                            setErrorInput({ ...errorInput, username: false })
                                                        }
                                                    }}
                                                />
                                                {errorInput.username && <small>Username is required!</small>}
                                                {form.username &&
                                                    <small style={{
                                                        color: `${userNameAvailable ? "green" : "red"}`,
                                                    }}>
                                                        {`${userNameAvailable ? " Username is available." : "Username is already being taken."}`}
                                                    </small>
                                                }
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="form-group">
                                                <Form.Label>Email<sup>*</sup></Form.Label>
                                                <Form.Control
                                                    type="email "
                                                    placeholder=""
                                                    className={`${errorInput.email ? "error" : ''} notCapital`}
                                                    value={form.email}
                                                    onChange={emailValidation}
                                                />
                                                {errorInput.email && <small>Email is required!</small>}
                                                {error &&
                                                    form.email !== "" &&
                                                    error !== "email is valid" && (
                                                        <small
                                                            style={{
                                                                color: "red",
                                                            }}
                                                        >
                                                            {error}
                                                        </small>
                                                    )}
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="form-group">
                                                <Form.Label>Password<sup>*</sup></Form.Label>
                                                <div className="passwordWrap">
                                                    <Form.Control
                                                        className={`${errorInput.password ? "error" : ''} notCapital`}
                                                        autoComplete="new-password"
                                                        type={`${!showPassword ? "password" : "text"}`}
                                                        placeholder=""
                                                        value={form.password}
                                                        onChange={handlePasswordChange}
                                                    />
                                                    <Button className="showPassIcon" type="button">
                                                        <i
                                                            onClick={clickHandlerPassword}
                                                            className={
                                                                showPassword ? "fas fa-eye" : "fas fa-eye-slash"
                                                            }
                                                        ></i>
                                                    </Button>
                                                </div>
                                                {errorInput.password && <small style={smallStyle}>Password is required!</small>}
                                                {!isPasswordValid && (
                                                    <small>
                                                        The password has to be at least 8 characters
                                                        long, with at least 1 Uppercase letter, 1 Lowercase
                                                        letter, 1 Number and 1 Special Character.
                                                    </small>
                                                )}
                                            </Form.Group>
                                        </Col>
                                        {form?.roleId?.label !== "Corporate Admin" && <Col md={6}>
                                            <Form.Group className="form-group">
                                                <Form.Label>PIN<sup>*</sup></Form.Label>
                                                <div className="passwordWrap">
                                                    <Form.Control
                                                        className={`${errorInput.pin ? "error" : ''} notCapital`}
                                                        type={`${!showPin ? "password" : "text"}`}
                                                        onWheel={(e) => e.target.blur()}
                                                        onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                                                        placeholder=""
                                                        value={form.pin}
                                                        onChange={(e) => {
                                                            const alphabetRegex = /^[0-9 ]*$/;
                                                            if (e.target.value.match(alphabetRegex)) {
                                                                if (e.target.value.length < 5) {
                                                                    setErrorInput({ ...errorInput, pin: false })
                                                                    setForm({
                                                                        ...form,
                                                                        pin: e.target.value,
                                                                    });
                                                                }
                                                            }
                                                        }}
                                                    />
                                                    <Button className="showPassIcon" type="button">
                                                        <i
                                                            onClick={clickHandlerPin}
                                                            className={
                                                                showPin ? "fas fa-eye" : "fas fa-eye-slash"
                                                            }
                                                        ></i>
                                                    </Button>
                                                </div>
                                                {errorInput.pin && <small style={smallStyle}>Pin is required!</small>}
                                            </Form.Group>
                                        </Col>
                                        }
                                        <Col md={6}>
                                            <Form.Group className="form-group">
                                                <Form.Label>Phone Number<sup>*</sup></Form.Label>
                                                <PhoneInput
                                                    country="us"
                                                    style={{ borderColor: "red" }}
                                                    value={form.phone}
                                                    onChange={handleOnChange}
                                                    enableSearch={true}
                                                    inputStyle={{ width: "100%" }}
                                                />
                                                {isPhoneValid === false && form.phone && (
                                                    <small style={smallStyle}>
                                                        {phoneValidatError}
                                                    </small>
                                                )}
                                                {errorInput.phone && <small style={smallStyle}>Phone is required!</small>}
                                            </Form.Group>
                                        </Col>
                                        {
                                            form?.roleId?.label !== "Corporate Admin" &&
                                            <Col md={6}>
                                                <Form.Group className="form-group">
                                                    {/* {console.log('1096', form.roleId)} */}
                                                    <Form.Label>Store Access<sup>*</sup></Form.Label>
                                                    <Select className="select2 multiSelect"
                                                        isMulti={form.roleId.label == "Manager" ? false : true}
                                                        // menuIsOpen={true}
                                                        isClearable={false}
                                                        closeMenuOnSelect={false}
                                                        hideSelectedOptions={false}
                                                        options={allBranchList}
                                                        value={form.branch}
                                                        onChange={(e) => {
                                                            if (form.roleId.label == "Manager") {
                                                                setForm({ ...form, branch: [e] })
                                                            }
                                                            else {
                                                                setForm({ ...form, branch: e })
                                                            }
                                                            setErrorInput({ ...errorInput, branch: false })
                                                        }}

                                                        labelledBy="Select" />
                                                    {errorInput.branch && <small style={smallStyle}>Store Access is required!</small>}
                                                </Form.Group>
                                            </Col>
                                        }

                                    </> : <EmployeFields  {...props} /> : <div></div>
                                }

                            </Row>
                        </Form>
                    </div>
                </Col>
                <Col xl={6} lg={4}></Col>
            </Row>
        </div >
    )
}
export { UserItem }