import React, { useState } from "react";
import { Button, Table, Spinner } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { apiDeleteMethod, apiGetMethod } from "../../api/rest";
import { allApiUrl } from "../../api/apiRoute";
import toastMessages from "../toastMessages";
import { useSelector } from "react-redux"
import InfiniteScroll from "react-infinite-scroll-component";
import { BsArrowDownUp, BsArrowDown, BsArrowUp } from 'react-icons/bs';
import { useEffect } from "react";
import Loader from "../shared/spinner";
import { v4 as uuidv4 } from 'uuid';

const AddOnTable = (props) => {
    const history = useHistory()
    const currBranch = useSelector((state) => state?.counter?.currBranch)
    const branchArray = useSelector((state) => state?.counter?.branchArray)
    const [deletedId, setDeletedId] = useState('')
    const [sortingTypeIndex, setSortingTypeIndex] = useState("Up");
    const [sortingTypeName, setSortingTypeName] = useState("Up");
    const [sortingTypeNoOfChoices, setSortingTypeNoOfChoices] = useState("Up")

    useEffect(() => {
        setSortingTypeNoOfChoices("Up")
        setSortingTypeName("Up")
        setSortingTypeIndex("Up")
    }, [])
    const SortingComponent = ({ field, sortingType }) => {
        return (
            <div className="pointer">
                {sortingType === "Up" ? (
                    <BsArrowUp onClick={() => handleSorting("Down", field)} />
                ) : (
                    <BsArrowDown onClick={() => handleSorting("Up", field)} />
                )}
            </div>
        );
    };

    function handleSorting(arrow, field) {
        if (field === "index") {
            // setSortingTypeIndex(arrow);
            // const sortedData = allEmployeeList.slice().reverse();
            // setAllEmployeeList(sortedData);
        }
        else if (field === "name") {
            const newSortingType = sortingTypeName === "Down" ? "Up" : "Down";
            setSortingTypeName(newSortingType);
            const sortedItems = [...props.modifierList].sort((a, b) => {
                if (a.modifiername < b.modifiername) {
                    return newSortingType === "Up" ? -1 : 1;
                }
                if (a.modifiername > b.modifiername) {
                    return newSortingType === "Up" ? 1 : -1;
                }
                return 0;
            });
            props.setModifierList(sortedItems);
        }
        else if (field === "noOfChoices") {
            const newSortingTypeDescription = sortingTypeNoOfChoices === "Down" ? "Up" : "Down";
            setSortingTypeNoOfChoices(newSortingTypeDescription);
            const sortedItems = [...props.modifierList].sort((a, b) => {
                return (a.choices.length - b.choices.length) * (newSortingTypeDescription === "Up" ? 1 : -1);
            });
            props.setModifierList(sortedItems);
        }
    }

    const [loader, setLoader] = useState({ delete: false })
    async function onDeleteHandler(id) {
        setDeletedId(id)
        setLoader({ ...loader, delete: true })
        const header = {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        };
        try {
            const response = await apiDeleteMethod(allApiUrl.delete_modifier, id, header);
            if (response.status === 200) {
                setLoader({ ...loader, delete: false })
                props.setPageRefresh(!props.pageRefresh)
                toastMessages("success", response.data.message);
            }
        } catch (err) {
            setLoader({ ...loader, delete: false })
            toastMessages("error", err.data.message);
            props.setPageRefresh(!props.pageRefresh)
            console.log("err", err);
        }
    }
    const fetchMoreData = async () => {
        // console.log('loade more data', props.lastEvaluatedKey)
        if (props.lastEvaluatedKey) {
            props.setLoaderForFetchMore(true)
            let branches = branchArray.map((item) => item.value)
            const header = {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                branchid: JSON.stringify(branches)
            };
            try {
                let start = ''
                if (props.lastEvaluatedKey) {
                    start = `&startKey=${props.lastEvaluatedKey}`
                }
                const response = await apiGetMethod(`${allApiUrl.view_all_modifier}?${start}`, "", header);
                if (response.status === 200) {
                    if (response.data.categories.Items.length < 1) {
                        props.setLoaderForFetchMore(false)
                    }
                    props.setModifierList(prevItems => prevItems.concat(response.data.categories.Items));
                    if (response.data.categories.LastEvaluatedKey) {
                        props.setLastEvaluatedKey(response.data.categories.LastEvaluatedKey.SK)
                    }
                    else {
                        props.setLastEvaluatedKey('')
                    }
                    toastMessages("success", response.data.message);
                }
            } catch (err) {
                toastMessages("error", err.data.message);
                console.log("err", err);
            }
        }
        else {
            props.setLoaderForFetchMore(false)
        }

    };
    function titleCase(str) {
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        return splitStr.join(' ');
    }
    return (
        <div className="commonTable">
            <div className="whiteBg">
                <div className="headingText headingText--dflex headingText--textLeft headingText--border">
                    <h4>All Modifier's</h4>
                </div>
                <InfiniteScroll
                    dataLength={props.modifierList.length}
                    next={fetchMoreData}
                    hasMore={props.loaderForFetchMore}
                    className="equalHeightTable">
                    <div className="tableFixHead">
                        {!props.modifierList.length && props.isLoading ?
                            <Loader /> :
                            <Table>
                                <thead>
                                    <tr key={uuidv4()}>
                                        <th>S.No.</th>
                                        <th>
                                            <div className="display_flex">
                                                Modifier Name  <SortingComponent field='name' sortingType={sortingTypeName} />
                                            </div>
                                        </th>
                                        <th>Set Name</th>
                                        <th>Type</th>
                                        {/* <th>Optional or Mandatory</th> */}
                                        {/* <th>
                                            <div className="display_flex">
                                                No of Choices  <SortingComponent field='noOfChoices' sortingType={sortingTypeNoOfChoices} />
                                            </div>
                                        </th> */}
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {props && props.modifierList && props.modifierList.length > 0 ? props.modifierList.map((item, index) => {
                                        console.log('172', item)
                                        return (
                                            <tr key={item.SK}>
                                                <td>#{index + 1}</td>
                                                <td>{titleCase(item.modifiername)}</td>
                                                <td>{titleCase(item.setName)}</td>
                                                <td>{titleCase(item.modifiertype)}</td>
                                                {/* <td>{titleCase(item.modifierstatus.modifiretype)}</td> */}
                                                {/* <td>{item.choices.length}</td> */}
                                                <td>
                                                    <div className="actionElements">
                                                        <button
                                                            onClick={() => history.push(`edit-modifiers?id=${item.SK}`)}
                                                            className="edit btn"><span>Edit</span> <i className="fa-solid fa-pen-to-square"></i></button>
                                                        <Button type="button" className="delete"
                                                            onClick={() => onDeleteHandler(item.SK)}
                                                        >
                                                            <>{
                                                                deletedId == item.SK && //eslint-disable-line
                                                                    loader.delete ? <Spinner
                                                                    as="span"
                                                                    className="delete"
                                                                    animation="border"
                                                                    size="sm"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                /> : <><span>Delete</span> <i className="fa-solid fa-trash-can"></i></>}

                                                            </>
                                                        </Button>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    }
                                    ) :
                                        <tr><td key={uuidv4()} className="text-center" colSpan={5}>No Modifiers Available </td></tr>
                                    }
                                </tbody>
                            </Table>
                        }

                    </div>
                </InfiniteScroll>
            </div>
        </div>
    )
}
export default AddOnTable