import React, { useState } from "react";
import {
  Button,
  Container,
  Row,
  Col,
  Image,
  ListGroup,
  Form,
} from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import { allApiUrl } from "../../../api/apiRoute";
import { apiPostMethod } from "../../../api/rest";
import CommonButton from "../../common-ui/Button";
import toastMessages from "../../toastMessages";
//SideBanner
import SideBanner from "../sideBanner";
import queryString from "query-string";

function ChangePassword() {
  const history = useHistory();
  const location = useLocation();
  const [isValid, setIsValid] = useState(false);
  const [loader, setLoader] = useState(false);
  const [showRedText, setShowRedText] = useState(false);

  let { token } = queryString.parse(location.search);
  const [newPassword, setNewPassword] = useState({
    password: "",
  });
  async function onSubmit(e) {
    setLoader(true);
    e.preventDefault();
    let header = {
      Authorization: `Bearer ${token}`,
    };
    try {
      if (isValid == true) {
        const response = await apiPostMethod(
          allApiUrl.change_password,
          newPassword,
          header
        );
        if (response.status === 200) {
          setLoader(false);
          setTimeout(() => {
            history.push("/login");
          }, 1000);
          setShowRedText(false);
          toastMessages("success", response.data.message);
        }
      } else {
        setLoader(false);
        setShowRedText(true);
        toastMessages("error", "The password is not valid.");
      }
    } catch (err) {
      setLoader(false);
      toastMessages("error", err.data.message);
      console.log("err", err);
    }
  }

  function handlePasswordChange(event) {
    setNewPassword({
      ...newPassword,
      password: event.target.value,
    });
    setIsValid(validatePassword(event.target.value));
  }
  function validatePassword(password) {
    const regex =
      /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*_#?&])([a-zA-Z0-9@$!%*_#?&]{8,})$/;
    return regex.test(password);
  }
  const [showPass, setShowPass] = useState(false);
  function clickHandler() {
    setShowPass(!showPass);
  }
  // console.log("loader", loader);
  return (
    <React.Fragment>
      <section className="formWrapper">
        <Container fluid>
          <Row className="flex-row-reverse">
            <SideBanner />
            <Col xl={5} lg={6} className="formGrid">
              <div className="formOuter">
                <div className="formOuter__inner">
                  <h1>Change Password</h1>
                  <Form onSubmit={onSubmit}>
                    <Form.Group className="form-group">
                      <Form.Label>New Password</Form.Label>
                      <div className="passwordWrap">
                        <Form.Control
                          className="formField notCapital"
                          type={`${!showPass ? "password" : "text"}`}
                          value={newPassword.password}
                          onChange={handlePasswordChange}
                        />
                        <Button className="showPassIcon" type="button">
                          <i
                            onClick={clickHandler}
                            className={showPass ? "fas fa-eye" : "fas fa-eye-slash"}
                          ></i>
                        </Button>
                      </div>
                      {!isValid && (
                        <small
                          style={{
                            color: `${showRedText == true ? "red" : ""}`,
                          }}
                        >
                          The password has to be at least 8 characters long,with
                          at least 1 Uppercase letter, 1 Lowercase letter, 1
                          Number and 1 Special Character.
                        </small>
                      )}
                    </Form.Group>

                    {/* <Button
                      className="commonButton commonButton--fullWidth"
                      type="submit"
                    >
                      Change
                    </Button> */}
                    <CommonButton
                      buttonName="Change"
                      type="fullWidth"
                      loader={loader}
                    />
                    <span className="bottomText">
                      Don’t have an account?{" "}
                      <Link className="linkText" to="/login">
                        Login
                      </Link>
                    </span>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
}
export default ChangePassword;
