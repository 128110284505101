import React, { useEffect } from 'react';
import { Row, Col, Form, Container, Button, Table } from 'react-bootstrap';
import Header from '../common/header';
import Sidebar from '../common/sidebar';
import copy from "copy-to-clipboard";
import toastMessages from '../toastMessages';
import { useSelector } from 'react-redux';
import chooseProductImg from '../shared/assets/images/choose-product-img.svg';
import { apiGetMethod } from '../../api/rest';
import { allApiUrl } from '../../api/apiRoute';
import { useState } from 'react';
import SidebarTwo from '../common/sidebarTwo';

function Url() {
    let branchArray = useSelector((state) => state.counter.branchArray)
    let branches = branchArray.map((item) => item.value)
    const header = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        branchid: JSON.stringify(branches)
    };
    const [modifierList, setModifierList] = useState('')
    useEffect(() => {
        // console.log('url-----1')
        getAllModifier()
        // console.log('url-----2')
    }, [])


    let branchid = useSelector((state) => state.counter.currBranch)
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    let url = ''
    if (process.env.REACT_APP_ENVIRONMENT == "development") {
        url = 'http://192.168.1.133:3000/'
    }
    else if (process.env.REACT_APP_ENVIRONMENT == "local") {
        // url = 'https://cloudpos-modifier.softuvo.click/'
        url = 'https://cloudpos.softuvo.click/'
    }
    else {
        url = 'https://portal.cloudpos.app/'
    }
    const [active, setActive] = React.useState(false);
    const copyToClipboard = () => {
        toastMessages('success', "Text Copied Successfully")
        // copy(text);
    }
    // let text = `<iframe src=${data}></iframe>`
    async function getAllModifier() {
        try {
            const response = await apiGetMethod(`${allApiUrl.linkCreate}`, "", header);
            if (response.status === 200) {
                setModifierList(response.data.data)
            }
        } catch (err) {
            console.log("err", err);
        }
    }
    let sidebarToggle = useSelector((state) => state.counter.sidebarToggle)
    const [sidebarType, setSidebarType] = useState('singleSidebar')
    let sidebarTypeRedux = useSelector((state) => state.counter.sidebarType)

    return (
        <React.Fragment>
            <Header setActive={setActive} active={sidebarToggle} />
            <div className="interFaceWrap">
                <Sidebar active={sidebarToggle} activePage="categories" />
                <div className={`interFaceWrap__inner ${sidebarTypeRedux}`}>
                    <div className="commonOuter">
                        <div className="pageHeader">
                            <Container fluid className="px-0">
                                <Row className="align-items-center">
                                    <div className="col">
                                        <div className="headingText headingText--textLeft mb-0">
                                            <h2>Steps to create the embed url for your branch. </h2>
                                        </div>
                                    </div>
                                </Row>
                            </Container>
                        </div>
                        <div className="selectCategoriesOpetions">
                            <Row>
                                {branchid.value == "All" ?
                                    <div className="commonTable">
                                        <div className="whiteBg">
                                            <div className="chooseProductViewStats">
                                                <img src={chooseProductImg} alt="chooseProductImg" />
                                                <h4>Select any Branch from header to continue..</h4>
                                            </div>
                                        </div>
                                    </div> : <div className="commonTable embedUrlTable">
                                        <div className="whiteBg">
                                            <Table responsive>
                                                <tbody>
                                                    <tr>
                                                        <th>Step 1</th>
                                                        <td>Selected any existing branch from header in case no branch is present  then Create a new branch from branch section Once the branch is created, select the new branch from the dropdown in the header. </td>
                                                    </tr>
                                                    <tr>
                                                        <th>Step 2</th>
                                                        <td> Once you have created the new branch and selected it, you can follow the remaining steps to copy the script and replace the iframe as needed.</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Step 3:</th>
                                                        <td>
                                                            <Form.Control className="iframeDataField" value={modifierList} disabled />
                                                            <div className="mt-2">Copy the script given above. <Button className="commonButton commonButton--small ms-2" onClick={copyToClipboard}>Copy</Button></div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>Step 4:</th>
                                                        <td>Replace the given iframe with your own iframe or create a new one and you are good to go.</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>}
                            </Row>
                        </div>
                    </div>
                </div>
                <SidebarTwo activePage="" setSidebarType={setSidebarType} sidebarType={sidebarType} />
            </div>
        </React.Fragment >
    )
}
export default Url; 