import React, { useRef, useState } from "react";
import {
  Button,
  Badge,
  Dropdown,
  Table,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";
import Chart from "react-apexcharts";
import Header from "../common/header";
import Sidebar from "../common/sidebar";
import arrowRightIcon from "../shared/assets/icons/right-arrow.svg";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { apiGetMethod, apiPostMethod } from "../../api/rest";
import { allApiUrl } from "../../api/apiRoute";
import {
  calculatedPercentage,
  dateFormatter,
  getAllDatesBetweenShortFormat,
  getTheDifferenceBetweenTwoMonths,
  titleCase,
} from "../../features/helper";
import InfiniteScroll from "react-infinite-scroll-component";
import DatePicker from "react-datepicker";
import chooseProductImg from "../shared/assets/images/choose-product-img.svg";
import toastMessages from "../toastMessages";
import CustomTooltip from "../common/tooltip";
import SidebarTwo from "../common/sidebarTwo";
import {
  changeTableManagement,
  changeWebsiteTemplate,
  toggleSideBarFuntionalityTwo,
} from "../../features/todoSlice";
// import StoreChangeLoader from '../../features/storeChangeLoader';
let dropDownData = [
  "Today",
  "Yesterday",
  "This Week",
  "Last Week",
  "This Month",
  "Last Month",
  "Custom",
];
const defaultTopProductGraphData = {
  series: [
    {
      name: "Previous",
      type: "column",
      data: [],
    },
    {
      name: "Current",
      type: "column",
      data: [],
    },
  ],
  options: {
    chart: {
      height: 350,
      type: "line",
      stacked: false,
      toolbar: {
        show: false,
      },
      selection: {
        enabled: true,
      },
      zoom: {
        enabled: false,
      },
    },
    colors: ["#1C64F2", "#FDBA8C"],
    plotOptions: {
      bar: {
        columnWidth: "30%",
        borderRadius: 5,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: false,
      width: [0, 0, 3],
    },
    title: {
      text: "",
      align: "center",
      offsetX: 0,
    },
    grid: {
      show: false,
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    },
    xaxis: {
      show: true,
      labels: {
        show: true,
        style: {
          fontFamily: "Dosis",
          fontSize: "11px",
          fontWeight: "400",
          colors: "var(--greyColorTint1)",
        },
      },
      // categories: ['Sugarcane', "Momo"],
    },
    yaxis: {
      show: true,
      labels: {
        show: true,
        offsetX: -15,
        style: {
          fontFamily: "Dosis",
          fontSize: "14px",
          fontWeight: "700",
          colors: "var(--greyColorTint1)",
        },
      },
      categories: ["$0", "$40K", "$80K", "$120K", "$160K", "$200K"],
    },
    tooltip: {
      fixed: {
        enabled: false,
        position: "topLeft",
        // offsetY: 30,
        // offsetX: 60
      },
    },
    legend: {
      show: true,
      showForSingleSeries: false,
      showForNullSeries: true,
      showForZeroSeries: true,
      position: "bottom",
      horizontalAlign: "center",
      floating: false,
      fontSize: "16px",
      fontFamily: "Dosis,sans-serif",
      fontWeight: 500,
      formatter: undefined,
      inverseOrder: false,
      width: undefined,
      height: undefined,
      tooltipHoverFormatter: false,
      customLegendItems: [],
      offsetX: 0,
      offsetY: 0,
      labels: {
        colors: undefined,
        useSeriesColors: false,
      },
      markers: {
        width: 12,
        height: 12,
        strokeWidth: 0,
        strokeColor: "#fff",
        fillColors: undefined,
        radius: 12,
        customHTML: undefined,
        onClick: undefined,
        offsetX: 0,
        offsetY: 0,
      },
      itemMargin: {
        horizontal: 5,
        vertical: 20,
      },
      onItemClick: {
        toggleDataSeries: false,
      },
      onItemHover: {
        highlightDataSeries: false,
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (y) {
          if (typeof y !== "undefined") {
            return "$" + y.toFixed(0);
          }
          return y;
        },
      },
    },
    responsive: [
      {
        breakpoint: 1750,
        options: {
          xaxis: {
            labels: {
              rotate: -45,
              rotateAlways: true,
              minHeight: 100,
              maxHeight: 180,
              style: {
                fontSize: "12px",
              },
            },
          },
        },
      },
    ],
  },
};
function Dashboard() {
  // console.log('window.location', window.location)
  let sidebarToggle = useSelector((state) => state.counter.sidebarToggle);
  let branchid = useSelector((state) => state.counter.currBranch);
  let branchArray = useSelector((state) => state.counter.branchArray);
  let typeOfUser = useSelector((state) => state.counter.typeOfUser);
  const [isTopProductVisible, setIsTopProductVisible] = useState(false);
  console.log("testing--224")
  useEffect(() => {
    // toastMessages('success','hello')
    if (
      branchArray.length == 1 &&
      branchArray &&
      branchArray[0].label !== "All"
    ) {
      setIsTopProductVisible(true);
    } else {
      setIsTopProductVisible(false);
    }
  }, [branchArray]);
  let history = useHistory();
  let dispatch = useDispatch();
  // console.log('isTopProductVisible', isTopProductVisible)
  // const active = localStorage.getItem('sidebarToggle')
  const [isGraphLoadingForSalesReport, setGraphLoadingForSalesReport] =
    useState(false);
  const [isGraphLoadingForNewProduct, setGraphLoadingForNewProduct] =
    useState(false);
  const [isTransactionReportLoading, setIsTransactionReportLoading] =
    useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [refresh, setRefresh] = useState(false);
  const [permissionData, setPermissionData] = useState({})
  const [sidebarType, setSidebarType] = useState("singleSidebar");
  function getRequiredTimeStamp(type, from) {
    if (from == "newProduct") {
      setGraphLoadingForNewProduct(false);
    } else if (from == "sales") {
      setGraphLoadingForSalesReport(false);
    } else {
      setIsTransactionReportLoading(false);
    }
    let currentStart = "";
    let currentEnd = "";
    let previousStart = "";
    switch (type) {
      case "Today":
        const startOfDay = new Date();
        startOfDay.setHours(0, 0, 0, 0); // set to midnight
        const startOfDayTimestamp = startOfDay.getTime();
        const endOfDay = new Date();
        endOfDay.setHours(23, 59, 59, 999); // set to 1 millisecond before midnight
        const endOfDayTimestamp = endOfDay.getTime();
        const nowDate = new Date();
        const yesterdayDate = new Date(
          nowDate.getFullYear(),
          nowDate.getMonth(),
          nowDate.getDate() - 1
        );
        yesterdayDate.setHours(0, 0, 0, 0); // set to midnight
        const startOfYesterday = yesterdayDate.getTime();
        yesterdayDate.setHours(23, 59, 59, 999); // set to 1 millisecond before midnight
        const endOfYesterday = yesterdayDate.getTime();
        return {
          currentStart: startOfDayTimestamp,
          currentEnd: endOfDayTimestamp,
          previousEnd: endOfYesterday,
          previousStart: startOfYesterday,
        };
        break;
      case "Yesterday":
        const now = new Date();
        const yesterday = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate() - 1
        );
        const dayBeforeYesterday = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate() - 2
        );
        yesterday.setHours(0, 0, 0, 0);
        const startOfYesterdayTimestamp = yesterday.getTime();
        yesterday.setHours(23, 59, 59, 999);
        const endOfYesterdayTimestamp = yesterday.getTime();
        dayBeforeYesterday.setHours(0, 0, 0, 0);
        const startOfDayBeforeYesterdayTimestamp = dayBeforeYesterday.getTime();
        dayBeforeYesterday.setHours(23, 59, 59, 999);
        const endOfDayBeforeYesterdayTimestamp = dayBeforeYesterday.getTime();
        return {
          currentStart: startOfYesterdayTimestamp,
          currentEnd: endOfYesterdayTimestamp,
          previousEnd: endOfDayBeforeYesterdayTimestamp,
          previousStart: startOfDayBeforeYesterdayTimestamp,
        };
        break;
      case "This Week":
        currentStart = new Date(
          new Date().setDate(new Date().getDate() - new Date().getDay())
        ).getTime();
        currentEnd = new Date(
          new Date().setDate(new Date().getDate() - new Date().getDay() + 6)
        ).getTime();
        previousStart = new Date(
          new Date().setDate(new Date().getDate() - new Date().getDay() - 6)
        ).getTime();
        return {
          currentStart,
          currentEnd,
          previousEnd: currentStart,
          previousStart,
        };
        break;
      case "Last Week":
        var curr = new Date(); // get current date
        var first = curr.getDate() - curr.getDay();
        first = first - 7;
        var firstdayOb = new Date(curr.setDate(first));
        var firstday = firstdayOb.getTime();
        var firstdayTemp = firstdayOb;
        var lastday = new Date(
          firstdayTemp.setDate(firstdayTemp.getDate() + 6)
        ).getTime();
        previousStart = firstday - 604800000;
        var previousEndUpdated = firstday - 86400000; // set previous end date to be one day before the first day
        return {
          currentStart: firstday,
          currentEnd: lastday,
          previousEnd: previousEndUpdated,
          previousStart,
        };
        break;
      case "This Month":
        const date = new Date();
        return {
          currentStart: new Date(
            date.getFullYear(),
            date.getMonth(),
            1
          ).getTime(),
          currentEnd: new Date(
            date.getFullYear(),
            date.getMonth() + 1,
            0
          ).getTime(),
          previousStart: new Date(
            date.getFullYear(),
            date.getMonth() - 1,
            1
          ).getTime(),
          previousEnd: new Date(
            date.getFullYear(),
            date.getMonth(),
            0,
            23,
            59,
            59,
            999
          ).getTime(),
        };
        break;
      case "Last Month":
        const dateNow = new Date();
        return {
          currentStart: new Date(
            dateNow.getFullYear(),
            dateNow.getMonth() - 1,
            1
          ).getTime(),
          currentEnd: new Date(
            dateNow.getFullYear(),
            dateNow.getMonth(),
            0,
            23,
            59,
            59,
            999
          ).getTime(),
          previousStart: new Date(
            dateNow.getFullYear(),
            dateNow.getMonth() - 2,
            1
          ).getTime(),
          previousEnd: new Date(
            dateNow.getFullYear(),
            dateNow.getMonth() - 1,
            0,
            23,
            59,
            59,
            999
          ).getTime(),
        };
        break;
      case "Custom":
        let result = endDate.getTime() - startDate.getTime();
        previousStart = startDate.getTime() - result;
        // let days = result/86400
        // if(days>28){

        // }
        return {
          currentStart: startDate.getTime(),
          currentEnd: endDate.getTime(),
          previousStart: previousStart,
          previousEnd: startDate.getTime(),
        };
        break;
    }
  }
  let props = {
    history,
    dispatch,
    getRequiredTimeStamp,
    setGraphLoadingForSalesReport,
    isGraphLoadingForSalesReport,
    setGraphLoadingForNewProduct,
    isGraphLoadingForNewProduct,
    setIsTransactionReportLoading,
    isTransactionReportLoading,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    setRefresh,
    refresh,
    permissionData
  };
  let sidebarTypeRedux = useSelector((state) => state.counter.sidebarType);

  //
  const [isSidebarTwoOpen, setIsSidebarTwoOpen] = useState(false);

  const sidebarTwoRef = useRef(null);
  const isClickOutsideSidebarTwo = (event) => {
    if (
      //   sidebarTypeRedux &&
      sidebarTwoRef.current &&
      !sidebarTwoRef.current.contains(event.target)
    ) {
      return true; // Click occurred outside SidebarTwo
    }
    return false; // Click occurred inside SidebarTwo or SidebarTwo is not open
  };
  useEffect(() => {
    document.addEventListener("click", (event) => {
      if (isClickOutsideSidebarTwo(event)) {
        console.log("event");
        // Perform your action when the click is outside SidebarTwo
        setIsSidebarTwoOpen(false); // Example: Close SidebarTwo
      }
    });

    return () => {
      document.removeEventListener("click", isClickOutsideSidebarTwo);
    };
  }, [sidebarTypeRedux]);
  console.log("isSidebarTwoOpen", isSidebarTwoOpen);
  console.log('permissionData', permissionData)
  return (
    <>
      <React.Fragment>
        <Header active={sidebarToggle} page="dashboard" />
        <div className="interFaceWrap">
          <Sidebar active={sidebarToggle} activePage="dashboard" permissionData={permissionData} />
          <div
            // onClick={() => {
            //   dispatch(toggleSideBarFuntionalityTwo(true));
            // }}
            className={`interFaceWrap__inner ${sidebarTypeRedux == false ? "doubleSidebar" : "singleSidebar"
              }`}
          >
            <div className="commonOuter">
              <div className="headingText headingText--textLeft">
                <h1>Dashboard</h1>
              </div>
              <div className="graphChart">
                <SalesReport {...props} />
                {isTopProductVisible && <TopProduct {...props} />}
              </div>
              <TransitionTable {...props} />
            </div>
          </div>
          <SidebarTwo
            activePage=""
            setSidebarType={setSidebarType}
            sidebarType={sidebarType}
            ref={sidebarTwoRef}
            setPermissionData={setPermissionData}
          />
        </div>
      </React.Fragment>
    </>
  );
}
function SalesReport({
  history,
  dispatch,
  getRequiredTimeStamp,
  setGraphLoadingForSalesReport,
  isGraphLoadingForSalesReport,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  setRefresh,
  refresh,
  permissionData
}) {
  console.log('526', permissionData)
  console.log('---testing----528--')
  let branchArray = useSelector((state) => state.counter.branchArray);
  let branches = branchArray.map((item) => item.value);
  const header = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    branchid: JSON.stringify(branches),
  };
  const [salesReportGraphData, setSalesReportGraphData] = useState({});
  const [salesOrderDetail, setSalesOrderDetail] = useState({
    previous: 0,
    current: 0,
    percentage: 0,
  });
  const [loader, setLoader] = useState(false);
  const [selectedSalesReport, setSelectedSalesReport] = useState("This Month");
  useEffect(() => {
    if (selectedSalesReport !== "Custom") {
      if (branches && branches.length == 1 && branches[0] !== "All") {
        getDisableStatus();
      }
      getSalesReportGraphData(selectedSalesReport);
    }
  }, [selectedSalesReport, branchArray, permissionData]);
  async function getSalesReportGraphData(value) {
    setLoader(true);
    let data = getRequiredTimeStamp(value, "sales");
    try {
      const response = await apiPostMethod(
        allApiUrl.dashboardSalesGraph,
        data,
        header
      );
      if (response.status === 200) {
        // console.log('478')
        let typeOfData = "";
        let days =
          Math.round(
            (data.currentEnd - data.currentStart) / (24 * 60 * 60 * 1000)
          ) + 1;
        if (days >= 33) {
          // console.log('------482--------')
          let result = data.currentEnd - data.currentStart;
          // console.log('------484--------')
          typeOfData = "months";
          data.previousStart = data.currentStart - result;
          // console.log('------487--------')
          data.previousEnd = data.currentStart;
          // console.log('------489--------')
          // let result = endDate.getTime() - startDate.getTime()
          // previousStart = startDate.getTime() - result
          // return {
          //     currentStart: startDate.getTime(),
          //     currentEnd: endDate.getTime(),
          //     previousStart: previousStart,
          //     previousEnd: startDate.getTime(),
        } else {
          typeOfData = "days";
        }
        let { allDateListing, allDateWiseData: currDataList } =
          getAllDatesBetweenShortFormat(
            data.currentStart,
            data.currentEnd,
            response.data,
            true,
            typeOfData
          );
        let { allDateListing: previousdates, allDateWiseData: prevDataList } =
          getAllDatesBetweenShortFormat(
            data.previousStart,
            data.previousEnd,
            response.data,
            false,
            typeOfData
          );
        // console.log('currDataList', currDataList)
        // console.log('allDateListing', allDateListing)
        // console.log('prevDataList', prevDataList)
        if (currDataList.length < prevDataList.length) {
          prevDataList.pop();
        }
        let series = [
          {
            name: "Current",
            type: "area",
            data: currDataList,
          },
          {
            name: "Previous",
            type: "area",
            data: prevDataList,
          },
        ];
        setSalesOrderDetail({
          current: response.data.current.orderDetails.gross,
          previous: response.data.previous.orderDetails.gross,
          percentage: calculatedPercentage(
            response.data.previous.orderDetails.gross,
            response.data.current.orderDetails.gross
          ),
        });
        if (response.data.current) {
          setGraphLoadingForSalesReport(true);
          setSalesReportGraphData({
            series: [
              {
                name: "Current",
                type: "area",
                data: currDataList,
              },
              {
                name: "Previous",
                type: "area",
                data: prevDataList,
              },
            ],
            options: {
              chart: {
                foreColor: "rgba(0,0,0,0.3)",
                height: 350,
                type: "line",
                toolbar: {
                  show: false,
                },
                selection: {
                  enabled: true,
                },
                zoom: {
                  enabled: false,
                },
              },
              stroke: {
                curve: "smooth",
              },
              fill: {
                type: "solid",
                opacity: [0.04, 1],
                colors: ["#6B7280", "transparent"],
              },
              colors: ["#0E9F6E", "#6B7280"],
              markers: {
                size: 0,
              },
              legend: {
                show: true,
                showForSingleSeries: false,
                showForNullSeries: true,
                showForZeroSeries: true,
                position: "bottom",
                horizontalAlign: "center",
                floating: false,
                fontSize: "16px",
                fontFamily: "Dosis,sans-serif",
                fontWeight: 500,
                formatter: undefined,
                inverseOrder: false,
                width: undefined,
                height: undefined,
                tooltipHoverFormatter: false,
                customLegendItems: [],
                offsetX: 0,
                offsetY: 0,
                labels: {
                  colors: undefined,
                  useSeriesColors: false,
                },
                markers: {
                  width: 12,
                  height: 12,
                  strokeWidth: 0,
                  strokeColor: "#fff",
                  fillColors: undefined,
                  radius: 12,
                  customHTML: undefined,
                  onClick: undefined,
                  offsetX: 0,
                  offsetY: 0,
                },
                itemMargin: {
                  horizontal: 5,
                  vertical: 0,
                },
                onItemClick: {
                  toggleDataSeries: false,
                },
                onItemHover: {
                  highlightDataSeries: false,
                },
              },
              xaxis: {
                show: true,
                labels: {
                  style: {
                    fontFamily: "Dosis",
                    fontSize: "16px",
                    fontWeight: "600",
                    colors: "var(--greyColorTint1)",
                  },
                },
                categories: allDateListing,
                tooltip: {
                  enabled: true,
                  formatter: (
                    value,
                    { series, seriesIndex, dataPointIndex }
                  ) => {
                    if (seriesIndex === 0) {
                      return "Current Date: " + allDateListing[dataPointIndex];
                    } else {
                      return "Previous Date:" + previousdates[dataPointIndex];
                    }
                  },
                },
              },
              yaxis: {
                show: true,
                labels: {
                  // offsetX: -15,
                  style: {
                    fontFamily: "Dosis",
                    fontSize: "16px",
                    fontWeight: "600",
                    colors: "var(--greyColorTint1)",
                  },
                },
              },
              grid: {
                borderColor: "var(--greyColorTint1)",
                strokeDashArray: 2,
                padding: {
                  left: 20,
                  bottom: -10,
                  //   right: -20
                },
              },
              tooltip: {
                shared: true,
                intersect: false,
                y: [
                  {
                    title: {
                      formatter: function (y) {
                        return y;
                      },
                    },
                  },
                  {
                    title: {
                      formatter: function (y) {
                        return y;
                      },
                    },
                  },
                ],
              },
            },
          });
        }
        setLoader(false);
      }
    } catch (err) {
      setGraphLoadingForSalesReport(true);
      setLoader(false);
      setSalesOrderDetail({
        current: 0,
        previous: 0,
        percentage: "0%",
      });
      // toastMessages("error", err.data.message);
    }
  }
  function customTimePicker() {
    if (new Date(startDate).getTime() <= new Date(endDate).getTime()) {
      setRefresh(!refresh);
      getSalesReportGraphData(selectedSalesReport);
    } else {
      toastMessages("error", "Start date should be less than end date.");
    }
  }
  async function getDisableStatus() {
    try {
      const response = await apiGetMethod(allApiUrl.viewSetting, "", header);
      if (response.status == 200) {
        if (response.data.Items.length != 0) {
          //for table managment sidebar toggle functionality.
          console.log('permissionData_810', permissionData, permissionData.tablemanagement == 0
            // , permissionData.tablemanagement && permissionData.tablemanagement == 0
          )
          if (permissionData.tablemanagement == 0) {
            console.log('812')
            dispatch(changeTableManagement(false));
          }
          else {
            if (response.data.Items[0].GeneralSettings.tablemanagement == 1) {
              console.log('817')
              dispatch(changeTableManagement(true));
            } else {
              console.log('820')
              dispatch(changeTableManagement(false));
            }
          }
          // for website template
          if (permissionData.websitetemplate == 0) {
            dispatch(changeWebsiteTemplate(false));
          }
          else {
            if (response.data.Items[0].WebsiteTemplate.enableWebsiteTemplate == 1) {
              //eslint-disable-line
              dispatch(changeWebsiteTemplate(true));
            } else {
              dispatch(changeWebsiteTemplate(false));
            }
          }

        }
      }
    } catch (err) {
      console.log("err", err);
    }
  }
  // console.log('578----------->', salesReportGraphData)
  return (
    <div className="chart whiteBg">
      {/* <StoreChangeLoader /> */}
      <div className="chartHead">
        <div className="chartHead__left">
          <h3>${salesOrderDetail?.current.toFixed(2)}</h3>
          <span>
            Sales{" "}
            {selectedSalesReport !== "Custom"
              ? selectedSalesReport
              : "in selected Period"}
          </span>
          {/* {console.log('salesOrderDetail.percentage', salesOrderDetail.percentage)} */}
        </div>
        <div className="chartHead__right">
          <div
            className={`${salesOrderDetail.percentage[0] == "-" ? "down" : ""}`}
          >
            <span>
              {parseFloat(salesOrderDetail.percentage).toFixed(2) + "%"}
            </span>
            {salesOrderDetail.percentage != "0%" && (
              <i className="fa-solid fa-arrow-up-long"></i>
            )}
          </div>
        </div>
      </div>
      {!isGraphLoadingForSalesReport && (
        <div style={{ height: "350px" }}>
          <Spinner animation="border" role="status">
            {" "}
            <span className="visually-hidden">Loading...</span>{" "}
          </Spinner>
        </div>
      )}
      {isGraphLoadingForSalesReport &&
        Object.keys(salesReportGraphData).length ? (
        <Chart
          options={{ ...salesReportGraphData.options }}
          series={salesReportGraphData.series}
          type="line"
          height={350}
        />
      ) : (
        ""
      )}
      {isGraphLoadingForSalesReport &&
        !Object.keys(salesReportGraphData).length && (
          <div className="chooseProductViewStats">
            <img src={chooseProductImg} alt="chooseProductImg" />
            <h4>No Stores Available</h4>
          </div>
        )}
      <div className="chartFoot chartFoot--border">
        <div className="chartFoot__left">
          <div className="chartCustomDropdown">
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                {selectedSalesReport}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {dropDownData.map((item, index) => {
                  return (
                    <Dropdown.Item
                      onClick={() => {
                        setSelectedSalesReport(item);
                      }}
                      key={index}
                    >
                      {" "}
                      {item}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
            {selectedSalesReport == "Custom" && (
              <div className="reportsChart__buttons">
                <div className="customDatepicker">
                  <span className="subTitle">Custom:</span>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    maxDate={new Date()}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                  />
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    selectsEnd
                    maxDate={new Date()}
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                  />
                  <Button
                    type="button"
                    className="commonButton"
                    onClick={customTimePicker}
                  >
                    Go
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
        <div
          className="chartFoot__right"
          onClick={() => {
            history.push("/reports?page=salesReport");
          }}
        >
          <Button type="button">
            <span>SALES REPORT</span>{" "}
            <img src={arrowRightIcon} alt="arrowRightIcon" />
          </Button>
        </div>
      </div>
    </div>
  );
}
function TopProduct({
  history,
  getRequiredTimeStamp,
  setGraphLoadingForNewProduct,
  isGraphLoadingForNewProduct,
  refresh,
  setRefresh,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}) {
  let branchArray = useSelector((state) => state.counter.branchArray);
  let branchid = useSelector((state) => state.counter.currBranch);
  const [topProductGraphData, setTopProductGraphData] = useState(
    defaultTopProductGraphData
  );
  const [topProductDetails, setTopProductDetails] = useState({
    previous: 0,
    current: 0,
    percentage: 0,
  });
  const [loader, setLoader] = useState(false);
  const [selectedTopProduct, setSelectedTopProduct] = useState("This Month");

  useEffect(() => {
    if (selectedTopProduct !== "Custom") {
      getNewProductData(selectedTopProduct);
    }
  }, [selectedTopProduct, branchid.value, branchArray]);
  async function getNewProductData(value) {
    let branches = branchArray.map((item) => item.value);
    setLoader(true);
    const header = {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      branchid: JSON.stringify(branches),
    };
    let data = getRequiredTimeStamp(value, "newProduct");
    try {
      const response = await apiPostMethod(
        allApiUrl.viewTopProducts,
        data,
        header
      );
      if (response.status === 200) {
        let currentPrice = 0;
        let previousPrice = 0;
        let previousList = [];
        let currentList = [];
        let categoriesList = [];

        response.data.data.forEach((item) => {
          currentPrice += item.current;
          previousPrice += item.previous;
          previousList.push(item.previous.toFixed(2));
          currentList.push(item.current.toFixed(2));
          categoriesList.push(titleCase(item.name));
        });
        setTopProductDetails({
          current: currentPrice,
          previous: previousPrice,
          percentage: calculatedPercentage(previousPrice, currentPrice),
        });

        let series = [
          {
            name: "Previous",
            type: "column",
            data: previousList,
          },
          {
            name: "Current",
            type: "column",
            data: currentList,
          },
        ];
        // console.log('---761---', series)
        // console.log('---762---', categoriesList)
        if (response.data.data) {
          setGraphLoadingForNewProduct(true);
          setTopProductGraphData((prev) => {
            prev.series = series;
            prev.options.xaxis.categories = categoriesList;
            return { ...prev };
          });
        }
        setLoader(false);
      }
    } catch (err) {
      setLoader(false);
      setGraphLoadingForNewProduct(true);
      setTopProductDetails({
        current: 0,
        previous: 0,
        percentage: "0%",
      });
    }
  }
  function customTimePicker() {
    if (new Date(startDate).getTime() <= new Date(endDate).getTime()) {
      setRefresh(!refresh);
      getNewProductData(selectedTopProduct);
    } else {
      toastMessages("error", "Start date should be less than end date.");
    }
  }

  return (
    <Row>
      <Col xl={12} className="">
        <div className="chart whiteBg">
          <div className="headingText headingText--textLeft headingText--border">
            <h4>Top Products</h4>
          </div>
          <div className="chartHead">
            <div className="chartHead__left">
              <h3>${topProductDetails?.current.toFixed(2)}</h3>
              <span>
                Top Products{" "}
                {selectedTopProduct !== "Custom"
                  ? selectedTopProduct
                  : "in selected Period"}
              </span>
            </div>
            <div className="chartHead__right">
              {/* <CustomTooltip
                                tooltipText={'testing'}
                            > */}
              <div
                className={`${topProductDetails?.percentage[0] == "-" ? "down" : ""
                  }`}
              >
                <span>
                  {parseFloat(topProductDetails.percentage).toFixed(2) + "%"}
                </span>
                {topProductDetails.percentage != "0%" && (
                  <i className="fa-solid fa-arrow-up-long"></i>
                )}
              </div>
              {/* </CustomTooltip> */}
            </div>
          </div>
          {!isGraphLoadingForNewProduct && (
            <div style={{ height: "350px" }}>
              <Spinner animation="border" role="status">
                {" "}
                <span className="visually-hidden">Loading...</span>{" "}
              </Spinner>
            </div>
          )}
          {isGraphLoadingForNewProduct && (
            <div className="topProductGraph">
              <Chart
                options={{ ...topProductGraphData.options }}
                series={topProductGraphData.series}
                type="line"
                height={350}
              />
            </div>
          )}

          <div className="chartFoot chartFoot--border">
            <div className="chartFoot__left">
              <div className="chartCustomDropdown">
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    {selectedTopProduct}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {dropDownData.map((item, index) => {
                      return (
                        <Dropdown.Item
                          onClick={() => {
                            setSelectedTopProduct(item);
                          }}
                          key={index}
                        >
                          {item}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
                {selectedTopProduct == "Custom" && (
                  <div className="reportsChart__buttons">
                    <div className="customDatepicker">
                      <span className="subTitle">Custom:</span>
                      <DatePicker
                        selected={startDate}
                        maxDate={new Date()}
                        onChange={(date) => setStartDate(date)}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                      />
                      <DatePicker
                        selected={endDate}
                        maxDate={new Date()}
                        onChange={(date) => setEndDate(date)}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                      />
                      <Button
                        type="button"
                        className="commonButton commonButton--small"
                        onClick={customTimePicker}
                      >
                        Go
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="chartFoot__right">
              <Button
                type="button"
                onClick={() => {
                  history.push("/reports");
                }}
              >
                <span>PRODUCTS REPORT</span>{" "}
                <img src={arrowRightIcon} alt="arrowRightIcon" />
              </Button>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
}
function TransitionTable({
  history,
  getRequiredTimeStamp,
  setIsTransactionReportLoading,
  isTransactionReportLoading,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  setRefresh,
  refresh,
}) {
  const [selectedTransaction, setSelectedTransaction] = useState("This Month");
  let branchArray = useSelector((state) => state.counter.branchArray);
  let branchid = useSelector((state) => state.counter.currBranch);
  const [loader, setLoader] = useState(false);
  const [transactionList, setTransactionList] = useState([]);
  useEffect(() => {
    if (selectedTransaction !== "Custom") {
      getTransactiondata(selectedTransaction);
    }
  }, [selectedTransaction, branchid.value, branchArray]);
  async function getTransactiondata(value) {
    let branches = branchArray.map((item) => item.value);
    setLoader(true);
    const header = {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      branchid: JSON.stringify(branches),
    };
    let data = getRequiredTimeStamp(value, "transaction");
    // console.log('782', data)
    try {
      const response = await apiPostMethod(allApiUrl.transaction, data, header);
      if (response.status === 200) {
        setTransactionList(response.data.data);
        setIsTransactionReportLoading(true);
        setLoader(false);
      }
    } catch (err) {
      setLoader(false);
      setIsTransactionReportLoading(true);
    }
  }
  function customTimePicker() {
    if (new Date(startDate).getTime() <= new Date(endDate).getTime()) {
      setRefresh(!refresh);
      getTransactiondata(selectedTransaction);
    } else {
      toastMessages("error", "Start date should be less than end date.");
    }
  }
  return (
    <div className="commonTable mt-4">
      <div className="graphChart">
        <div className="chart whiteBg">
          <div className="headingText headingText--dflex headingText--textLeft headingText--border">
            <h4>Transactions</h4>
            {/* <Button type="button"
                            onClick={() => {
                                history.push('/transactions')
                            }}
                        >View all</Button> */}
          </div>
          {!isTransactionReportLoading && (
            <div style={{ height: "350px" }}>
              <Spinner animation="border" role="status">
                {" "}
                <span className="visually-hidden">Loading...</span>{" "}
              </Spinner>
            </div>
          )}
          <InfiniteScroll
            dataLength={transactionList.length}
            // next={fetchMoreData}
            // hasMore={loaderForFetchMore}
            className="equalHeightTable"
          >
            <div className="tableFixHead">
              {isTransactionReportLoading && (
                <Table>
                  <thead>
                    <tr>
                      <th>Transaction Name</th>
                      <th>Transaction Date</th>
                      <th>Transaction Amount</th>
                      <th>Transaction Store</th>
                      <th>Transaction Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transactionList && transactionList.length > 0 ? (
                      transactionList.map((item, index) => {
                        // var date = new Date(parseInt(item.date));
                        // var date_format_str = (date.getDate().toString().length == 2 ? date.getDate().toString() : "0" + date.getDate().toString()) + "/" +
                        //     ((date.getMonth() + 1).toString().length == 2 ? (date.getMonth() + 1).toString() : "0" + (date.getMonth() + 1).toString()) +
                        //     "/" + date.getFullYear().toString() + " ";
                        let dateFormatted = dateFormatter(item.date);
                        return (
                          <tr key={index}>
                            <td>
                              Payment From{" "}
                              {item.name !== " -"
                                ? titleCase(item.name)
                                : "Dine in"}
                            </td>
                            <td>{dateFormatted}</td>
                            <td>${parseFloat(item.amount).toFixed(2)}</td>
                            <td>{titleCase(item.branch)}</td>
                            <td>
                              <Badge bg="completed">{item.paymentStatus}</Badge>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td className="text-center" colSpan="5">
                          No data is found for the selected period.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              )}
            </div>
          </InfiniteScroll>
          <div className="chartFoot chartFoot--border">
            <div className="chartFoot__left">
              <div className="chartCustomDropdown">
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    {selectedTransaction}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {dropDownData.map((item, index) => {
                      return (
                        <Dropdown.Item
                          onClick={() => {
                            setSelectedTransaction(item);
                          }}
                          key={index}
                        >
                          {item}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
                {selectedTransaction == "Custom" && (
                  <div className="reportsChart__buttons">
                    <div className="customDatepicker">
                      <span className="subTitle">Custom:</span>
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        selectsStart
                        maxDate={new Date()}
                        startDate={startDate}
                        endDate={endDate}
                      />
                      <DatePicker
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        selectsEnd
                        maxDate={new Date()}
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                      />
                      <Button
                        type="button"
                        className="commonButton commonButton--small"
                        onClick={customTimePicker}
                      >
                        Go
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div
              className="chartFoot__right"
              onClick={() => {
                history.push("/transactions");
              }}
            >
              <Button type="button">
                <span>TRANSACTIONS REPORT</span>{" "}
                <img src={arrowRightIcon} alt="arrowRightIcon" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Dashboard;
