import React, { useRef, useEffect, useState } from "react";
import Select from "react-select";
import CryptoJS from "crypto-js";
import { Nav, Tab, Button, Container, Row, Col, Form, Modal, ListGroup, Card, InputGroup, Alert, OverlayTrigger, Tooltip } from "react-bootstrap";
import { changeTableManagement, changeWebsiteTemplate } from "../../features/todoSlice";
//Header
import { GrRefresh } from "react-icons/gr";
import { FiCopy } from "react-icons/fi";
import { MdPending } from "react-icons/md"
import { AiFillCheckCircle } from "react-icons/ai"
import { MdSmsFailed } from 'react-icons/md'
import { RxCrossCircled } from "react-icons/rx";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import Header from "../common/header";
import Sidebar from "../common/sidebar";
import Loader from "../shared/spinner";
import { v4 as uuid } from "uuid";
//Icons
import reportsIcon from "../shared/assets/icons/reports-icon.svg";
//Image 
import templateOne from "../shared/assets/template/templateOne.png"
import templateTwo from "../shared/assets/template/templateTwo.png"

import InputButton from "../common-ui/Input";
import { useDispatch, useSelector } from "react-redux";
import {
  apiDeleteMethod,
  apiGetMethod,
  apiPostMethod,
  apiPutMethod,
} from "../../api/rest";
import { allApiUrl } from "../../api/apiRoute";
import toastMessages from "../toastMessages";
import { useHistory } from "react-router-dom";
import CommonButton from "../common-ui/Button";
import { c, countNumberInPhoneNumber, formatDate_mm_dd_yyyy, titleCase } from "../../features/helper";
import { countries, domainSuffixList, Keys } from "../../features/constant";
import { v4 as uuidv4 } from "uuid"
// import AWS from "aws-sdk"
// const AWS = require("aws-sdk");
// import { Buffer } from "buffer";
import SidebarTwo from "../common/sidebarTwo";


import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Template from "../template";
import CustomTooltip from "../common/tooltip";
import { ClipboardCopy } from "../digital-menu";

console.log('region', process.env.REACT_APP_AWS_REGION)
const PreviewModal = ({
  showPreview,
  setShowPreview,
  children,
}) => {
  let modalname = 'Template Preview'
  return (
    <Modal
      show={showPreview}
      fullscreen={true}
      className="modal-fullscreen modal-dark"
      onHide={() => setShowPreview(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title >
          {modalname}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {children}
      </Modal.Body>
    </Modal>
  )
}
const AdvanceSettingsDetails = () => {
  return (
    <div className="advancedSettingsDetails d-none">
      <div className="commonBgHead commonBgHead--bottomRadiusNone">
        Advanced Settings (admin only)
      </div>
      <div className="whiteBg whiteBg--topRadiusNone">
        <Tab.Container
          id="advancedSettingsDetailsTab"
          defaultActiveKey="administration"
        >
          <Row>
            <Col lg={3}></Col>
            <Col lg={9}>
              <Nav className="commonTabs">
                <Nav.Item>
                  <Nav.Link eventKey="administration">
                    Administration
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="onlineOrdering">
                    Online Ordering
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
          </Row>
          <Tab.Content className="commonTabsContent">
            <Tab.Pane eventKey="administration">
              <Form>
                <Form.Group className="form-group">
                  <Row>
                    <Col lg={3}>
                      <Form.Label>Admin Lock Code</Form.Label>
                    </Col>
                    <Col xxl={3} xl={6} lg={6} className="extraPX">
                      <Form.Control
                        type="number"
                        placeholder="123456"
                      />
                    </Col>
                    <Col
                      xxl={6}
                      xl={3}
                      lg={3}
                      className="d-lg-block d-none"
                    >
                      &nbsp;
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group className="form-group">
                  <Row>
                    <Col lg={3}>
                      <Form.Label>Online Ordering</Form.Label>
                    </Col>
                    <Col xxl={3} xl={6} lg={6} className="extraPX">
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        className="enabled"
                        label=""
                      />
                    </Col>
                    <Col
                      xxl={6}
                      xl={3}
                      lg={3}
                      className="d-lg-block d-none"
                    >
                      &nbsp;
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group className="form-group">
                  <Row>
                    <Col lg={3}>
                      <Form.Label>Local Orders</Form.Label>
                    </Col>
                    <Col xxl={3} xl={6} lg={6} className="extraPX">
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        className="enabled"
                        label=""
                      />
                    </Col>
                    <Col
                      xxl={6}
                      xl={3}
                      lg={3}
                      className="d-lg-block d-none"
                    >
                      &nbsp;
                    </Col>
                  </Row>
                </Form.Group>
              </Form>
            </Tab.Pane>
            <Tab.Pane eventKey="onlineOrdering">
              <Form>
                <Form.Group className="form-group">
                  <Row>
                    <Col lg={3}>
                      <Form.Label>Stripe Account ID</Form.Label>
                    </Col>
                    <Col xxl={3} xl={6} lg={6} className="extraPX">
                      <Form.Control
                        type="text"
                        placeholder="Acct_1HbkjdSBfhkBl"
                      />
                    </Col>
                    <Col
                      xxl={6}
                      xl={3}
                      lg={3}
                      className="d-lg-block d-none"
                    >
                      &nbsp;
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group className="form-group">
                  <Row>
                    <Col lg={3}>
                      <Form.Label>Reference ID/Token</Form.Label>
                    </Col>
                    <Col xxl={3} xl={6} lg={6} className="extraPX">
                      <Form.Control type="text" placeholder="Demo" />
                    </Col>
                    <Col
                      xxl={6}
                      xl={3}
                      lg={3}
                      className="d-lg-block d-none"
                    >
                      &nbsp;
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group className="form-group">
                  <Row>
                    <Col lg={3}>
                      <Form.Label>Restaurant Key</Form.Label>
                    </Col>
                    <Col xxl={3} xl={6} lg={6} className="extraPX">
                      <Form.Control
                        type="text"
                        placeholder="PO3sdhabvjvh"
                      />
                    </Col>
                    <Col
                      xxl={6}
                      xl={3}
                      lg={3}
                      className="d-lg-block d-none"
                    >
                      &nbsp;
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group className="form-group">
                  <Row>
                    <Col lg={3}>
                      <Form.Label>Consumer Kay</Form.Label>
                    </Col>
                    <Col xxl={3} xl={6} lg={6} className="extraPX">
                      <Form.Control
                        type="text"
                        placeholder="ck_adfsfjdbfjidsbfihesfvdsjh"
                      />
                    </Col>
                    <Col
                      xxl={6}
                      xl={3}
                      lg={3}
                      className="d-lg-block d-none"
                    >
                      &nbsp;
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group className="form-group">
                  <Row>
                    <Col lg={3}>
                      <Form.Label>Consumer Secret</Form.Label>
                    </Col>
                    <Col xxl={3} xl={6} lg={6} className="extraPX">
                      <Form.Control
                        type="text"
                        placeholder="cs_784165468dsfdsfadshvhj"
                      />
                    </Col>
                    <Col
                      xxl={6}
                      xl={3}
                      lg={3}
                      className="d-lg-block d-none"
                    >
                      &nbsp;
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group className="form-group">
                  <Row>
                    <Col lg={3}>
                      <Form.Label>Auto Login Key</Form.Label>
                    </Col>
                    <Col xxl={3} xl={6} lg={6} className="extraPX">
                      <Form.Control
                        type="text"
                        placeholder="UTx1dsajlkfb68safd4d6dffds"
                      />
                    </Col>
                    <Col
                      xxl={6}
                      xl={3}
                      lg={3}
                      className="d-lg-block d-none"
                    >
                      &nbsp;
                    </Col>
                  </Row>
                </Form.Group>
              </Form>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </div>
  )
}
function Settings() {
  const audioRef = useRef(null);
  const audioRefAlarm = useRef(null)
  const playNotificationSoundAlarm = () => {
    setTimeout(() => {
      audioRefAlarm.current.play()
    })
  }
  const playNotificationSound = () => {
    setTimeout(() => {
      audioRef.current.play()
    })
  }
  const inputRef = useRef(null)
  const [isWebsiteTemplateEnabled, setIsWebsiteTemplateEnabled] = useState(false)
  const history = useHistory();
  const currBranch = useSelector((state) => state.counter.currBranch);
  const branchArray = useSelector((state) => state.counter.branchArray);
  let branches = branchArray.map((item) => item.value);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  let restaurant = userInfo?.organizationKey ? userInfo?.organizationKey.split("#")[1] : userInfo?.PK.split("#")[1]
  const header = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    branchid: JSON.stringify(branches),
  };
  const [isNewImageUploaded, setIsNewImageUploaded] = useState(false)
  const [newSettings, setNewSettings] = useState(false);
  const [permissionData, setPermissionData] = useState({})
  const [isLoading, setIsLoading] = useState(false);
  const [preview, setPreview] = useState([])
  // console.log('------69----', isNewImageUploaded)
  const [deleteImage, setDeleteImage] = useState(false)
  const [form, setForm] = useState({
    settings: {
      GeneralSettings: {
        workflow: 0,
        sendtokitchen: 0,
        tablemanagement: 1,
        defaultordertype: {},
        locktimeout: 0,
        dineintablenumberrequire: 0,
        locktimeoutsecond: 10,
        manualsettlement: 0,
      },
      PaymentSettings: {
        taxrate: "0%",
        enablepaymentmethod: [],
        defaultpaymentmethod: {},
        discount: 0,
        gratuity: 0,
        gratuityAmount: [""],
        paymentGateway: {},
        cashdrawer: 0
      },
      Receipt: {
        merchantreceipt: 1,
        receiptmessage: "",
        fontsize: {
          product: "",
          modifiers: "",
          note: "",
        },
      },
      Reports: {
        email: "",
      },
      Settlement: {
        lastsettletime: " "
      },
      BusinessInfo: {
        businessname: "",
        address: "",
        city: "",
        stateId: "",
        zipcode: "",
        country: "",
        phone: "",
        countrycode: "+",
        logo: ''
      },
      WebsiteTemplate: {
        enableWebsiteTemplate: 0,
        selectedTemplate: {},
        preview: '',
        domainName: ""
      },
      KitchenApp: {
        enablekitchendisplay: 0,
        ordernotificationsound: 0,
        notificationsound: { value: '', label: "" },
        timeuntilwarningcolor: 0,
        timeuntillatecolor: 0,
        autoapprove: 0,
      }
    },
  });
  const [currentlySelectedNotification, setCurrentlySelectedNotification] = useState({ value: '', label: '' })
  const [settlementList, setSettlementList] = useState({})
  const [settlementLoader, setSettlementLoader] = useState(false)
  const [keyDecryptionDone, setKeyDecryptionDone] = useState(false)
  const [orderTypeList, setOrderTypeList] = useState([])
  const [paymentTypeList, setPaymentTypeList] = useState([])
  async function getSettlement() {
    try {
      const response = await apiGetMethod(`${allApiUrl.settlementList}`, "", header);
      if (response.status === 200) {
        setSettlementList(response.data)
        setSettlementLoader(false)
      }
    } catch (err) {
      setSettlementLoader(false)
      console.log("err", err);
    }
  }
  const [notificationSoundList, setNotificationSoundList] = useState()
  async function onSettlementHandler(e) {
    e.preventDefault()
    setSettlementLoader(true)
    try {
      let data = {
        card: {
          amount: settlementList?.balance?.available[0]?.source_types?.card,
        },
      }
      const response = await apiPostMethod(`${allApiUrl.settlePayment}`, data, header);
      if (response.status === 200) {
        getSettlement()
      }
    } catch (err) {
      toastMessages('error', err.data.message)
      setSettlementLoader(false)
      console.log("err", err);
    }
  }
  async function getAudio() {
    try {
      const response = await apiGetMethod(`${allApiUrl.getNotificationSound}`, "", header);
      if (response.status === 200) {
        setNotificationSoundList(response.data.data)
      }
    } catch (err) {
      console.log("err", err);
    }
  }
  useEffect(() => {
    if (
      branchArray.length > 1 ||
      (branchArray && branchArray[0].value == "All")
    ) {
      history.push("/dashboard");
    }
    setIsLoading(true);
    getAllSettings();
    getAudio()
    getSettlement()
    getStripeKeys()
    // getAllRegistratedDomain()
  }, [currBranch.value, branchArray]);
  const configurePaymentKeys = async (keys) => {
    return Promise.all(
      keys.map(async (key) => {
        const decryptedPublic = await decryptKey(key.keys.public);
        const decryptedPrivate = await decryptKey(key.keys.private);

        return {
          ...key,
          keys: {
            public: decryptedPublic || "",
            private: decryptedPrivate || "",
          },
        };
      })
    );
  };
  const decryptKey = async (key) => {
    return new Promise((resolve, reject) => {
      try {
        let token = key
          .replace(/p1L2u3S/g, "+")
          .replace(/s1L2a3S4h/g, "/")
          .replace(/e1Q2u3A4l/g, "=");
        const bytes = CryptoJS.AES.decrypt(token, process.env.REACT_APP_SECRET);
        let dataString1 = CryptoJS.enc.Utf8.stringify(bytes)
        resolve(dataString1);
      }
      catch (errr) {
        reject(errr)
      }
    })
  };
  const [realEncypytedKey, setRealEncypytedKey] = useState([]);
  const [currentKeys, setCurrentKeys] = useState([]);
  async function getAllSettings() {
    try {
      const response = await apiGetMethod(
        `${allApiUrl.viewSetting}`,
        "",
        header
      );
      setIsUpdateButtonClicked(true)
      if (response.status === 200) {
        if (response.data.Items.length != 0) {
          //eslint-disable-line
          if (response.data.Items[0].PaymentSettings.paymentGatewayKeys) {
            setRealEncypytedKey(response.data.Items[0].PaymentSettings.paymentGatewayKeys);
            let decryptedKeys = await configurePaymentKeys(response.data.Items[0].PaymentSettings.paymentGatewayKeys);
            setKeyDecryptionDone(true)
            setForm((prev) => {
              prev.settings = response.data.Items[0];
              prev.settings.PaymentSettings.paymentGateway =
                prev.settings.PaymentSettings.paymentGateway || {};
              prev.settings.PaymentSettings.paymentGatewayKeys = decryptedKeys;
              return { ...prev };
            });
            setCurrentKeys(JSON.parse(JSON.stringify(decryptedKeys)));
          } else {
            setCurrentKeys([]);
            setForm((prev) => {
              prev.settings = response.data.Items[0];
              prev.settings.PaymentSettings.paymentGateway =
                prev.settings.PaymentSettings.paymentGateway || {};
              prev.settings.PaymentSettings.paymentGatewayKeys = [];
              return { ...prev };
            });
          }
          setDeleteImage(false)
          setIsNewImageUploaded(false)
          setPreview(response.data.Items[0].BusinessInfo.logourl !== "" && response.data.Items[0].BusinessInfo.logourl)
          delete response.data.Items[0].BusinessInfo.logourl
          setNewSettings(false);
          if (response.data.Items[0].PaymentSettings.gratuityAmount.length) {
            setGrauityAmountCount(response.data.Items[0].PaymentSettings.gratuityAmount.length);
          } else {
            setGrauityAmountCount(1);
          }
          setIsEmailValid(true);
          setIsPhoneNumberValid(true);
          setIsUpdateButtonClicked(false)
        } else {
          setIsUpdateButtonClicked(false)
          setNewSettings(true);
          let defaultValue = {
            settings: {
              GeneralSettings: {
                tablemanagement: 1,
                locktimeout: 0,
                manualsettlement: 0,
                workflow: 1,
                sendtokitchen: 1,
                defaultordertype: {
                  value: "2",
                  label: "Dine-in",
                },
                orderType: [
                  { label: "Pick up", value: "1" },
                  { label: "Dine-in", value: "2" },
                  { label: "Delivery", value: "3" },
                  { label: "Catering", value: "4" },
                ],
              },
              PaymentSettings: {
                cashdrawer: 0,
                discount: 1,
                paymentGatewayKeys: [],
                paymentGateway: {},
                gratuity: 0,
                gratuityAmount: [""],
                taxrate: "0%",
                enablepaymentmethod: [
                  {
                    value: "1",
                    label: "Credit Card",
                  },
                  {
                    value: "2",
                    label: "Debit card",
                  },
                  {
                    value: "3",
                    label: "UPI",
                  },
                  {
                    value: "4",
                    label: "Cash",
                  },
                ],
                defaultpaymentmethod: {
                  value: "4",
                  label: "Cash",
                },
              },
              Receipt: {
                merchantreceipt: 0,
                receiptmessage: "",
                fontsize: {
                  product: "10",
                  modifiers: "10",
                  note: "10",
                },
              },
              Reports: {
                email: "",
              },
              BusinessInfo: {
                businessname: '',
                address: '',
                city: '',
                stateId: '',
                zipcode: '',
                country: '',
                phone: '',
                countrycode: "",
                logo: ''
              },
              WebsiteTemplate: {
                selectedTemplate: {},
                preview: '',
                domainName: ""
              },
              KitchenApp: {
                enablekitchendisplay: 0,
                ordernotificationsound: 0,
                notificationsound: { value: '', label: "" },
                timeuntilwarningcolor: 0,
                timeuntillatecolor: 0,
                autoapprove: 0,
              }
            },
          };
          setForm(defaultValue);
          // localStorage.setItem("settingData", JSON.stringify(defaultValue));
        }

        if (response.data.Items.length != 0) {
          //eslint-disable-line
          console.log('permissionData--681', permissionData)
          if (permissionData && permissionData.tablemanagement == 0) {
            dispatch(changeTableManagement(false));
          }
          else {
            if (response.data.Items[0].GeneralSettings.tablemanagement == 1) {
              //eslint-disable-line
              dispatch(changeTableManagement(true));
            } else {
              dispatch(changeTableManagement(false));
            }
          }
          //
          if (permissionData && permissionData.websitetemplate == 0) {
            dispatch(changeTableManagement(true));
          }
          else {
            if (response.data.Items[0].WebsiteTemplate.enableWebsiteTemplate == 1) {
              //eslint-disable-line
              dispatch(changeWebsiteTemplate(true));
            } else {
              dispatch(changeWebsiteTemplate(false));
            }
          }
        }
      }
      setCurrentlySelectedNotification(response.data.Items[0].KitchenApp.notificationsound)
      setIsWebsiteTemplateEnabled(response.data.Items[0].WebsiteTemplate.enableWebsiteTemplate == 0 ? false : true)
      toastMessages("success", response.data.message);
      setIsLoading(false);
      setOrderTypeList(response.data.Items[0].GeneralSettings.orderType)
      setPaymentTypeList(response.data.Items[0].PaymentSettings.enablepaymentmethod)
      getAllRegistratedDomain()
    } catch (err) {
      console.log("err", err);
      setIsLoading(false);
    }
  }
  const orderType = [
    { label: "Pick Up", value: "1" },
    { label: "Dine-In", value: "2" },
    { label: "Delivery", value: "3" },
    { label: "Catering", value: "4" },
  ];
  //Payment Tab
  const enablePaymentType = [
    { label: "Credit Card", value: "1" },
    { label: "Debit Card", value: "2" },
    { label: "UPI", value: "3" },
    { label: "Cash", value: "4" },
    { label: "Other", value: "5" },
  ];
  //Orders Tab
  const defaultOrderType = [
    { label: "Dine-In", value: "0" },
    { label: "Add Extra Meat", value: "1" },
    { label: "Berry Choice", value: "2" },
    { label: "Biscuits Size", value: "3" },
    { label: "Blended Flavors", value: "4" },
    { label: "Bread Choice", value: "5" },
  ];
  const refreshDays = [
    { label: "Monday", value: "0" },
    { label: "Tuesday", value: "1" },
    { label: "Wednesday", value: "2" },
    { label: "Thursday", value: "3" },
    { label: "Friday", value: "4" },
    { label: "Saturday", value: "5" },
    { label: "Sunday", value: "5" },
  ];
  const defaultPaymentType = [
    { label: "Card", value: "1" },
    { label: "Cash", value: "4" },
  ];
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);
  const [phoneValidatError, setPhoneValidError] = useState(false);
  const [countryCodeLimit, setCountryCodeLimit] = useState({
    status: false,
    error: "",
  });
  //email validation
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [error, setError] = useState(null);
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  function containsOnlySpecialChars(str) {
    return /^[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/.test(str); //eslint-disable-line
  }
  const emailValidation = (e) => {
    if (e.target.value.length < 81) {
      setForm((prevData) => {
        prevData.settings.Reports.email = e.target.value;
        return {
          ...prevData,
        };
      });
      setError(null);
      if (isValidEmail(e.target.value)) {
        let check = e.target.value.split("@")[0];
        let isnum = /^\d+$/.test(check);

        if (isnum === true || containsOnlySpecialChars(check)) {
          setIsEmailValid(false);
          setError("The email address field must be a valid email");
        } else {
          setIsEmailValid(true);
          setError("The email address is valid");
        }
      } else {
        setIsEmailValid(false);
        setError("The email address field must be a valid email");
      }
    } else {
      setIsEmailValid(false);
      setError("Email cann't be greater than 80 chars.");
    }
  };
  //Sidebar Toggle
  const [active, setActive] = React.useState(false);
  const [loader, setLoader] = useState(false);
  const [customOrderTypesCount, setCustomOrderTypesCount] = useState(1);
  const [grauityAmountCount, setGrauityAmountCount] = useState(1);
  const [orderSettings, setOrderSettings] = useState({
    autoRefreshOrders: false,
    customOrderTypes: [],
  });
  const [reportsSettings, setReportsSettings] = useState({
    autoRefreshOrders: false,
  });
  //increment gratuity amount count
  function incrementGratuity(event) {
    event.preventDefault();
    setGrauityAmountCount(grauityAmountCount + 1);
    // setForm((prevData) => {
    //   prevData.settings.PaymentSettings.gratuityAmount = [...prevData.settings.PaymentSettings.gratuityAmount, ""]
    //   return {
    //     ...prevData
    //   }
    // })
  }
  //decrement gratuity amount count
  const decrementGratuity = (event, index) => {
    event.preventDefault();
    if (
      grauityAmountCount > 1 &&
      form.settings.PaymentSettings.gratuityAmount.length > 0
    ) {
      let cpyedData = [...form.settings.PaymentSettings.gratuityAmount];
      cpyedData.splice(index, 1);
      setForm((prevData) => {
        prevData.settings.PaymentSettings.gratuityAmount = cpyedData;
        return {
          ...prevData,
        };
      });
    }
    setGrauityAmountCount((prev) => prev - 1);
  };

  const unique_id = uuid();
  const small_id = unique_id.slice(0, 8);
  const dispatch = useDispatch();
  console.log('newSettings', newSettings)
  const updateSettings = async (newSettings) => {
    setIsHideKeys(true)
    setIsUpdateButtonClicked(true)
    setLoader(true);
    async function createApi() {
      try {
        const response = await apiPostMethod(
          allApiUrl.configureSettings,
          form,
          header
        );
        if (response.status === 200) {
          toastMessages("success", response.data.message);
          getAllSettings();
          setLoader(false);
        }
      } catch (err) {
        setLoader(false);
        toastMessages("error", err?.data?.message);
      }
    }
    async function updateApi() {
      try {
        let newObj = form;
        newObj.settings.PaymentSettings.gratuityAmount = newObj.settings.PaymentSettings.gratuityAmount.filter(v => v != '');
        async function convertionCryptojs(value) {
          var ciphertext = CryptoJS.AES.encrypt(value, process.env.REACT_APP_SECRET).toString();
          const dataString = ciphertext.replace(/\+/g, 'p1L2u3S').replace(/\//g, 's1L2a3S4h').replace(/=/g, 'e1Q2u3A4l');
          return dataString
        }
        if (newObj.settings.PaymentSettings.paymentGatewayKeys.length > 0) {
          await newObj.settings.PaymentSettings.paymentGatewayKeys.forEach(
            async (item, index) => {
              let data1;
              let data2;
              let realValue = { keys: { public: item.keys.public, private: item.keys.private }, name: "Stripe" }
              if (item.keys.public && item.keys.private) {
                if ((item.keys.public === (currentKeys.length && currentKeys[0].keys.public)) &&
                  (item.keys.private === (currentKeys.length && currentKeys[0].keys.private))
                ) {
                  data1 = realEncypytedKey[0].keys.public;
                  data2 = realEncypytedKey[0].keys.private;
                } else {
                  data1 = await convertionCryptojs(item.keys.public);
                  data2 = await convertionCryptojs(item.keys.private);
                  // console.log('-----data1-------', data1, "-----data2----", data2)
                }
                item.keys.public = data1;
                item.keys.private = data2;
                if (
                  index == newObj.settings.PaymentSettings.paymentGatewayKeys.length - 1
                ) {
                  const formData = new FormData();
                  if (isNewImageUploaded) {
                    console.log('662', form.settings)
                    formData.append('file', form.settings.BusinessInfo.logo)
                  }
                  if (deleteImage) {
                    formData.append('deleteImage', 'true')
                  }
                  formData.append('settings', JSON.stringify(newObj.settings))
                  try {
                    const response = await apiPutMethod(
                      allApiUrl.updateSetting,
                      formData,
                      // data,
                      header
                    );
                    if (response.status === 200) {
                      toastMessages("success", response.data.message);
                      getAllSettings();
                      setIsUpdateButtonClicked(false)
                      setLoader(false);
                    }
                  } catch (err) {
                    setIsUpdateButtonClicked(false)
                    toastMessages("error", err.data.message);
                    getAllSettings();
                    setLoader(false);
                    // console.log('realValue-----535', realValue)
                    setForm((prev) => {
                      prev.settings.PaymentSettings.paymentGatewayKeys[0] = realValue;
                      return { ...prev };
                    });
                    console.log('err', err)
                  }
                }
              }
              else {
                newObj.settings.PaymentSettings.paymentGatewayKeys = []
                const formDataTwo = new FormData();
                if (isNewImageUploaded) {
                  console.log('662', form.settings)
                  formDataTwo.append('file', form.settings.BusinessInfo.logo)
                }
                if (deleteImage) {
                  formDataTwo.append('deleteImage', 'true')
                }
                formDataTwo.append('settings', JSON.stringify(newObj.settings))
                console.log('-----701-----')
                try {
                  const response = await apiPutMethod(
                    allApiUrl.updateSetting,
                    formDataTwo,
                    header
                  );
                  if (response.status === 200) {
                    toastMessages("success", response.data.message);
                    getAllSettings();
                    setIsUpdateButtonClicked(false)
                    setLoader(false);
                  }
                }
                catch (err) {
                  toastMessages('error', err.data.message)
                  setLoader(false);
                  setIsUpdateButtonClicked(false)
                  console.log('err', err)
                }
              }
            }
          );
        } else {
          console.log('-----725-----')
          try {
            const formDataThree = new FormData();
            if (isNewImageUploaded) {
              console.log('662', form.settings)
              formDataThree.append('file', form.settings.BusinessInfo.logo)
            }
            if (deleteImage) {
              formDataThree.append('deleteImage', 'true')
            }
            formDataThree.append('settings', JSON.stringify(newObj.settings))
            console.log('-----701-----')

            const response = await apiPutMethod(
              allApiUrl.updateSetting,
              formDataThree,
              header
            );
            if (response.status === 200) {
              toastMessages("success", response.data.message);
              getAllSettings();
              setLoader(false);
              setIsUpdateButtonClicked(false)
            }
          }
          catch (err) {
            toastMessages('error', err.data.message)
            setLoader(false);
            setIsUpdateButtonClicked(false)
            console.log('err', err)
          }
        }
      } catch (err) {
        setLoader(false);
        setIsUpdateButtonClicked(false)
        toastMessages("error", err?.data?.message);
      }
    }
    if (
      isEmailValid &&
      isPhoneNumberValid &&
      countryCodeLimit.status === false
    ) {
      // console.log("----529-----", newSettings);
      newSettings == true ? createApi() : updateApi();
      // createApi()
    } else if (
      (countryCodeLimit.status === true || form.countrycode == "+") &&
      form.settings.BusinessInfo.countrycode
    ) {
      //eslint-disable-line
      setLoader(false);
      if (countryCodeLimit.state == true) {
        //eslint-disable-line
        toastMessages("error", "country code can't be greater than 4 digit");
      } else {
        toastMessages("error", "country code is not valid");
      }
    } else if (isEmailValid === false && form.settings.Reports.email) {
      setIsUpdateButtonClicked(false)
      setLoader(false);
      toastMessages("error", "Email is not valid.");
    } else if (
      isPhoneNumberValid === false &&
      form.settings.BusinessInfo.phone
    ) {
      setIsUpdateButtonClicked(false)
      setLoader(false);
      toastMessages("error", phoneValidatError);
    } else {
      setIsUpdateButtonClicked(false)
      newSettings == true ? createApi() : updateApi();
    }
  };
  const handleOnChange = (value, country) => {
    setForm((prev) => {
      prev.settings.BusinessInfo.phone = value;
      prev.settings.BusinessInfo.countrycode = country.dialCode;
      return { ...prev };
    });
    const result = countNumberInPhoneNumber(country.format, ".");
    if (
      value.length - country.dialCode.length ==
      result - country.dialCode.length ||
      result - country.dialCode.length > 10
    ) {
      setIsPhoneNumberValid(true);
    } else {
      setIsPhoneNumberValid(false);
      setPhoneValidError(
        `The Phone Number should be of ${result - country.dialCode.length
        } Digit`
      );
    }
  };
  const [showKey, setShowKey] = useState({
    secretKey: false,
    publicKey: false,
  });
  function clickHandler(type) {
    if (type == "secret") {
      setShowKey({ ...showKey, secretKey: !showKey.secretKey });
    } else {
      setShowKey({ ...showKey, publicKey: !showKey.publicKey });
    }
  }
  let sidebarToggle = useSelector((state) => state.counter.sidebarToggle)
  const [isUpdateButtonClicked, setIsUpdateButtonClicked] = useState(false)
  const [sidebarType, setSidebarType] = useState('singleSidebar')
  const [isHideKeys, setIsHideKeys] = useState(false)
  let sidebarTypeRedux = useSelector((state) => state.counter.sidebarType)
  const [onHoverImage, setOnHoverImage] = useState(false)
  const [token, setTokenAuth] = useState('')
  // const []
  // console.log('----711-----', form)

  async function getStripeKeys() {
    try {
      const response = await apiGetMethod(`${allApiUrl.linkCreate}`, "", header);
      if (response.status === 200) {
        let token = response.data.data.split('token=')[1]
        setTokenAuth(token)
      }
    } catch (err) {
      console.log("err", err);
    }
  }
  // console.log('token-------903----', token)
  // console.log('-----form-------', form)
  // console.log('isWebsiteTemplateEnabled', isWebsiteTemplateEnabled)
  const [prevuewTemplate, setPreviewTemplate] = useState(false)
  const [allRegistratedDomain, setAllRegistratedDomain] = useState([])
  const [isDomainRegisrationStarted, setIsDomainRegisrationStarted] = useState({
    status: false,
    messsage: ''
  })
  const [domainRecords, setDomainRecords] = useState({})
  let url = ''
  console.log('process.env.REACT_APP_ENVIRONMENT', process.env.REACT_APP_ENVIRONMENT)
  if (process.env.REACT_APP_ENVIRONMENT == "development") {
    // url = 'http://192.168.1.133:3000/'
    url = 'http://localhost:3000/'
  }
  else if (process.env.REACT_APP_ENVIRONMENT == "local") {
    // url = 'https://cloudpos-modifier.softuvo.click/'
    url = 'https://cloudpos.softuvo.click/'
  }
  else {
    url = 'https://portal.cloudpos.app/'
  }

  console.log('permissionData', permissionData)
  console.log('domainRecords', domainRecords)
  const getAllRegistratedDomain = async () => {
    try {
      const response = await apiGetMethod(allApiUrl.getAllRegistratedDomainDetail, '', header)
      if (response.status == 200) {
        let domainAssociated = []
        response.data.forEach((item) => {
          domainAssociated.push(item)
        })
        setAllRegistratedDomain(domainAssociated)
        if (form.settings.WebsiteTemplate.clientDomain) {
          let isAlreadyPresent = response.data.find((item) =>
            item.domainName == `${form.settings.WebsiteTemplate.clientDomain}${form.settings.WebsiteTemplate.suffix.value}`)
          console.log('isAlreadyPresent----921----', isAlreadyPresent)
          if (isAlreadyPresent) {
            setIsDomainRegisrationStarted({
              status: true,
              messsage: isAlreadyPresent.domainStatus
            })
            setDomainRecords(isAlreadyPresent)
          }
          else {
            //close the toggle when the domain is not found in database 
            setForm((prev) => {
              prev.settings.WebsiteTemplate.haveDomain = 0
              return { ...prev }
            })
            setIsDomainRegisrationStarted({
              status: false,
              messsage: 'The Domain was eariler registered but now it is not present in the dns records please contact the admin or click on attach Subdomain.'
            })
          }

        }
      }
    }
    catch (err) {
      setAllRegistratedDomain([])
    }
  }
  // c('isDomainRegisrationStarted', isDomainRegisrationStarted)
  // c('allRegistratedDomain', allRegistratedDomain)
  // c('isPhoneNumberValid', isPhoneNumberValid)
  // c('-form-1104-', form.settings.WebsiteTemplate)
  const onDeleteSubdomainHandler = async () => {
    let _id = `${form.settings.WebsiteTemplate.clientDomain}${form.settings.WebsiteTemplate.suffix.value}`
    try {
      const response = await apiDeleteMethod(allApiUrl.deleteSubdomain, _id, header)
      if (response.status == 200) {
        toastMessages('success', `Successfully deleted ${_id} from dns records`)
        setForm((prev) => {
          prev.settings.WebsiteTemplate.clientDomain = ''
          prev.settings.WebsiteTemplate.suffix = {}
          return { ...prev }
        })
        getAllRegistratedDomain()
      }
    }
    catch (err) {
      toastMessages('error', `${err.data.message}`)
    }
  }
  // console.log('isWebsiteTemplateEnabled', isWebsiteTemplateEnabled)
  // console.log('permissionData', permissionData)
  // console.log('---form----', form.settings)
  const onCreateHandler = async (e) => {
    e.preventDefault()
    if (!form.settings.WebsiteTemplate.clientDomain) {
      toastMessages('error', "Please enter some client domain to continue.")
      return
    }
    let isDomainExist
    if (form.settings.WebsiteTemplate.clientDomain) {
      isDomainExist = allRegistratedDomain.find((item) => item.domainName == form.settings.WebsiteTemplate.clientDomain)
    }
    if (isDomainExist) {
      toastMessages('error', "This domain is already registrated")
    }
    else {
      if (!form.settings.WebsiteTemplate.domainName) {
        return toastMessages('error', 'sudomain is missing')
      }
      if (form.settings.WebsiteTemplate.clientDomain == form.settings.WebsiteTemplate.domainName) {
        // console.log('!form.settings.WebsiteTemplate.suffix', !form.settings.WebsiteTemplate.suffix)
        if (!form.settings.WebsiteTemplate.suffix || !Object.keys(form.settings.WebsiteTemplate.suffix).length) {
          return toastMessages('error', "Suffix is missing.")
        }
        const formData = new FormData()
        formData.append('settings', JSON.stringify({ WebsiteTemplate: form.settings.WebsiteTemplate }))
        try {
          let response = await apiPutMethod(allApiUrl.updateSetting, formData, header);
          if (response.status == 200) {
            createDomainAmpify()
          }
        }
        catch (err) {
          console.log('error', err.datas)
        }
      }
      else {
        console.log('1182', form.settings.WebsiteTemplate)
        toastMessages('error', "subdomain and domain must be same")
      }
    }
  }
  const createDomainAmpify = async () => {
    try {
      let domainName = `${form.settings.WebsiteTemplate.clientDomain}${form.settings.WebsiteTemplate.suffix.value}`
      const response = await apiPostMethod(allApiUrl.createSubdomain, { domainName: domainName }, header)
      if (response.status == 200) {
        setIsDomainRegisrationStarted({
          status: true,
          messsage: response.data.message.domainStatus
        })
        setDomainRecords(response.data.message)
        // updateSettings()
        getAllSettings()
      }
    }
    catch (err) {
      console.log('error', err.data)
      toastMessages('error', err.data.message)
      setIsDomainRegisrationStarted({
        status: false,
        messsage: err.data.message
      })
    }
  }
  console.log('settings-->1280', form)
  async function copyTextToClipboard() {
    let copyText = `<iframe src=${url}menu-order?token=${token}   title="online ordering"/>`
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(copyText);
    } else {
      return document.execCommand('copy', true, copyText);
    }
  }
  return (
    <React.Fragment>
      <PreviewModal
        showPreview={prevuewTemplate}
        setShowPreview={setPreviewTemplate}
      >
        <Template token={token} preview={true} />
      </PreviewModal>
      <Header setActive={setActive} active={sidebarToggle} />
      <div className="interFaceWrap"
        onMouseOut={() => {
          setOnHoverImage(false)
        }}>
        <Sidebar active={sidebarToggle} activePage="settings" permissionData={permissionData} />
        <div className={`interFaceWrap__inner ${sidebarTypeRedux == false ? "doubleSidebar" : "singleSidebar"}`}>
          <div className="commonOuter">
            <div className="pageHeader">
              <Container fluid className="px-0">
                <Row className="align-items-center">
                  <div className="col">
                    <div className="headingText headingText--textLeft mb-0">
                      <h1>Edit Settings</h1>
                    </div>
                  </div>
                  <div className="col-auto ms-auto onlyButtons">
                    <div className="pageHeader__elements">
                      <CommonButton
                        disabled={isUpdateButtonClicked}
                        buttonName="Update"
                        type="half"
                        onClick={() => updateSettings(newSettings)}
                        loader={loader}
                      />
                    </div>
                  </div>
                </Row>
              </Container>
            </div>
            <div className="editSettingsOpetions">
              <div className="settingsDetails">
                <div className="commonBgHead commonBgHead--bottomRadiusNone">
                  Settings
                </div>
                {isLoading ? (
                  <Loader />
                ) : (
                  <div className="whiteBg whiteBg--topRadiusNone">
                    <Tab.Container
                      id="settingsDetailsTab"
                      defaultActiveKey="general"
                    >
                      <Row>
                        <Col lg={3}></Col>
                        <Col lg={9}>
                          <Nav className="commonTabs">
                            <Nav.Item>
                              <Nav.Link eventKey="general">General</Nav.Link>
                            </Nav.Item>
                            {/* <Nav.Item className="d-none">
                              <Nav.Link eventKey="orders">Orders</Nav.Link>
                            </Nav.Item> */}
                            <Nav.Item>
                              <Nav.Link eventKey="payment">Payment</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="receipt">Receipt</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="reports">Reports</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="businessInfo">
                                Business Info
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              {!!permissionData.kitchendisplayapp &&
                                <Nav.Link eventKey="kitchenApp">
                                  Kitchen App
                                </Nav.Link>
                              }
                            </Nav.Item>
                            <Nav.Item>
                              {!!permissionData.manualsettlement &&
                                <Nav.Link eventKey="settlement">
                                  Settlement
                                </Nav.Link>
                              }
                            </Nav.Item>
                            <Nav.Item className="">
                              {
                                !!permissionData.websitetemplate &&
                                <Nav.Link eventKey="websiteTemplate">
                                  Website Template
                                </Nav.Link>
                              }
                            </Nav.Item>
                          </Nav>
                        </Col>
                      </Row>
                      <Tab.Content className="commonTabsContent" style={{ textAlign: 'left' }}>
                        <Tab.Pane eventKey="general">
                          <Form>
                            <Form.Group className="form-group">
                              <Row>
                                <Col lg={3}>
                                  <Form.Label>Workflow</Form.Label>
                                </Col>
                                <Col lg={9} className="extraPX">
                                  {["radio"].map((type, idx) => {
                                    return (
                                      <div
                                        key={`default-${idx}`}
                                        className="formCheckbox formCheckbox--inline"
                                      >
                                        <Form.Check
                                          checked={
                                            form.settings.GeneralSettings
                                              .workflow == 1
                                              ? true
                                              : false
                                          } //eslint-disable-line
                                          onChange={() => { }}
                                          onClick={() => {
                                            setForm((prevData) => {
                                              prevData.settings.GeneralSettings.workflow = 1;
                                              return {
                                                ...prevData,
                                              }
                                            });
                                          }}
                                          name={`sizeSelected-${form.settings.GeneralSettings.workflow}${idx}${small_id}`}
                                          id={`yesCheck-${form.settings.GeneralSettings.workflow}${idx}${small_id}`}
                                          type={type}
                                          label="Select Menu Items First"
                                        />
                                        <Form.Check
                                          checked={
                                            form.settings.GeneralSettings
                                              .workflow == 0
                                              ? true
                                              : false
                                          } //eslint-disable-line
                                          onChange={() => { }}
                                          onClick={() => {
                                            setForm((prevData) => {
                                              prevData.settings.GeneralSettings.workflow = 0;
                                              return {
                                                ...prevData,
                                              };
                                            });
                                          }}
                                          name={`sizeSelected-${form.settings.GeneralSettings.workflow}${idx}${small_id}`}
                                          id={`yesCheck-${form.settings.GeneralSettings.workflow}${idx}${small_id}`}
                                          label="Select Order Type First"
                                          type={type}
                                        />
                                      </div>
                                    )
                                  })}
                                </Col>
                              </Row>
                            </Form.Group>
                            <Form.Group className="form-group">
                              <Row>
                                <Col lg={3}>
                                  <Form.Label>Send to Kitchen</Form.Label>
                                </Col>
                                <Col
                                  xxl={3}
                                  xl={6}
                                  lg={6}
                                  className="d-flex extraPX"
                                >
                                  <Form.Check
                                    onClick={(e) => {
                                      setForm((prevData) => {
                                        prevData.settings.GeneralSettings.sendtokitchen =
                                          e.target.checked == true ? 1 : 0; //eslint-disable-line
                                        return {
                                          ...prevData,
                                        };
                                      });
                                    }}
                                    checked={
                                      form.settings.GeneralSettings
                                        .sendtokitchen == 1
                                        ? true
                                        : false
                                    } //eslint-disable-line
                                    onChange={() => { }}
                                    disabled={!permissionData.sendtokitchen ? true : false}
                                    type="switch"
                                    id="custom-switch"
                                    className="show"
                                    label=""
                                  />
                                  <span className="labelInfoText">
                                    on the Register screen
                                  </span>
                                </Col>
                                <Col
                                  xxl={6}
                                  xl={3}
                                  lg={3}
                                  className="d-lg-block d-none"
                                >
                                  &nbsp;
                                </Col>
                              </Row>
                            </Form.Group>
                            <Form.Group className="form-group">
                              <Row>
                                <Col lg={3}>
                                  <Form.Label>Table Management</Form.Label>
                                </Col>
                                {console.log('permissionData--->', permissionData)}
                                <Col xxl={3} xl={6} lg={6} className="extraPX">
                                  <Form.Check
                                    disabled={permissionData.tablemanagement == 0 ? true : false}
                                    onClick={(e) => {
                                      setForm((prevData) => {
                                        // prevData.settings.GeneralSettings.dineintablenumberrequire 
                                        prevData.settings.GeneralSettings.tablemanagement =
                                          e.target.checked == true ? 1 : 0; //eslint-disable-line

                                        return {
                                          ...prevData,
                                        };
                                      });
                                    }}
                                    checked={
                                      form.settings.GeneralSettings
                                        .tablemanagement == 1
                                        ? true
                                        : false
                                    } //eslint-disable-line
                                    onChange={() => { }}
                                    type="switch"
                                    id="custom-switch"
                                    className="enabled"
                                    label=""
                                  />
                                </Col>
                                <Col
                                  xxl={6}
                                  xl={3}
                                  lg={3}
                                  className="d-lg-block d-none"
                                >
                                  &nbsp;
                                </Col>
                              </Row>
                            </Form.Group>
                            {!!form.settings.GeneralSettings.tablemanagement &&
                              <Form.Group className="form-group">
                                <Row>
                                  <Col lg={3}>
                                    <Form.Label>Dine-in Table assignment non-mandatory</Form.Label>
                                  </Col>
                                  {console.log('permissionData--->', permissionData)}
                                  <Col xxl={3} xl={6} lg={6} className="extraPX">
                                    <Form.Check
                                      // disabled={permissionData.tablemanagement == 0 ? true : false}
                                      onClick={(e) => {
                                        setForm((prevData) => {
                                          prevData.settings.GeneralSettings.dineintablenumberrequire =
                                            e.target.checked == true ? 1 : 0; //eslint-disable-line
                                          return {
                                            ...prevData,
                                          };
                                        });
                                      }}
                                      checked={
                                        form.settings.GeneralSettings
                                          .dineintablenumberrequire == 1
                                          ? true
                                          : false
                                      } //eslint-disable-line
                                      onChange={() => { }}
                                      type="switch"
                                      id="custom-switch"
                                      className="enabled"
                                      label=""
                                    />
                                  </Col>
                                  <Col
                                    xxl={6}
                                    xl={3}
                                    lg={3}
                                    className="d-lg-block d-none"
                                  >
                                    &nbsp;
                                  </Col>
                                </Row>
                              </Form.Group>
                            }
                            <Form.Group className="form-group">
                              <Row>
                                <Col lg={3}>
                                  <Form.Label>Default Order Type</Form.Label>
                                </Col>
                                <Col xxl={3} xl={6} lg={6} className="extraPX">
                                  <Select
                                    isSearchable={false}
                                    className="select2 singleSelect"
                                    // menuIsOpen={true}
                                    placeholder="Select Order Type"
                                    options={orderTypeList}
                                    value={
                                      form.settings.GeneralSettings.defaultordertype
                                    }
                                    onChange={(e) => {
                                      setForm((prevData) => {
                                        prevData.settings.GeneralSettings.defaultordertype =
                                          e;
                                        return {
                                          ...prevData,
                                        };
                                      });
                                    }}
                                    labelledBy="Select"
                                  />
                                </Col>
                                <Col
                                  xxl={6}
                                  xl={3}
                                  lg={3}
                                  className="d-lg-block d-none"
                                >
                                  &nbsp;
                                </Col>
                              </Row>
                            </Form.Group>
                            <Form.Group className="form-group">
                              <Row>
                                <Col lg={3}>
                                  <Form.Label>Lock Timeout</Form.Label>
                                </Col>
                                <Col xxl={3} xl={6} lg={6} className="extraPX">
                                  <Form.Check
                                    onClick={(e) => {
                                      setForm((prevData) => {
                                        prevData.settings.GeneralSettings.locktimeout =
                                          e.target.checked == true ? 1 : 0; //eslint-disable-line
                                        return {
                                          ...prevData,
                                        };
                                      });
                                    }}
                                    checked={
                                      form.settings.GeneralSettings
                                        .locktimeout == 1
                                        ? true
                                        : false
                                    } //eslint-disable-line
                                    onChange={() => { }}
                                    type="switch"
                                    id="custom-switch"
                                    className="enabled"
                                    label=""
                                  />
                                </Col>
                                <Col
                                  xxl={6}
                                  xl={3}
                                  lg={3}
                                  className="d-lg-block d-none"
                                >
                                  &nbsp;
                                </Col>
                              </Row>
                            </Form.Group>
                            <Form.Group className="form-group">
                              <Row>
                                <Col lg={3}>
                                  <Form.Label>Lock Timeout (sec)</Form.Label>
                                </Col>
                                <Col xxl={3} xl={6} lg={6} className="extraPX">
                                  <InputButton
                                    type="text"
                                    value={
                                      form.settings.GeneralSettings
                                        .locktimeoutsecond
                                    }
                                    onBlur={(e) => {
                                      if (e.target.value < 10) {
                                        const re = /[^0-9]/g;
                                        setForm((prevData) => {
                                          prevData.settings.GeneralSettings.locktimeoutsecond = 10;
                                          return {
                                            ...prevData,
                                          };
                                        });
                                      }
                                    }}
                                    onChange={(e) => {
                                      const re = /[^0-9]/g;
                                      setForm((prevData) => {
                                        prevData.settings.GeneralSettings.locktimeoutsecond =
                                          e.target.value.replace(re, "");
                                        return {
                                          ...prevData,
                                        };
                                      });
                                    }}
                                    placeholder="Time in seconds"
                                    disabled={
                                      form.settings.GeneralSettings
                                        .locktimeout == 0
                                        ? true
                                        : false
                                    } //eslint-disable-line
                                  />
                                </Col>
                                <Col
                                  xxl={6}
                                  xl={3}
                                  lg={3}
                                  className="d-lg-block d-none"
                                >
                                  &nbsp;
                                </Col>
                              </Row>
                            </Form.Group>
                            <Form.Group className="form-group">
                              <Row>
                                <Col lg={3}>
                                  <Form.Label>Manual Settlement</Form.Label>
                                </Col>
                                <Col xxl={3} xl={6} lg={6} className="extraPX">
                                  <Form.Check
                                    onClick={(e) => {
                                      setForm((prevData) => {
                                        prevData.settings.GeneralSettings.manualsettlement =
                                          e.target.checked == true ? 1 : 0; //eslint-disable-line
                                        return {
                                          ...prevData,
                                        };
                                      });
                                    }}
                                    disabled={!permissionData.manualsettlement ? true : false}
                                    checked={
                                      form.settings.GeneralSettings
                                        .manualsettlement == 1
                                        ? true
                                        : false
                                    } //eslint-disable-line
                                    onChange={() => { }}
                                    type="switch"
                                    id="custom-switch"
                                    className="enabled"
                                    label=""
                                  />
                                </Col>
                                <Col
                                  xxl={6}
                                  xl={3}
                                  lg={3}
                                  className="d-lg-block d-none"
                                >
                                  &nbsp;
                                </Col>
                              </Row>
                            </Form.Group>
                          </Form>
                          <Form.Group className="form-group">
                            <Row>
                              <Col lg={3}>
                                <Form.Label>Default Preparation Time</Form.Label>
                              </Col>
                              <Col xxl={3} xl={6} lg={6} className="extraPX">
                                <InputButton
                                  type="text"
                                  value={
                                    form.settings.GeneralSettings.defaultPreparationTime
                                  }
                                  onChange={(e) => {
                                    const re = /[^0-9]/g;
                                    setForm((prevData) => {
                                      prevData.settings.GeneralSettings.defaultPreparationTime =
                                        e.target.value.replace(re, "");
                                      return {
                                        ...prevData,
                                      };
                                    });
                                  }}
                                  placeholder="Time in Minutes"
                                />
                              </Col>
                              <Col
                                xxl={6}
                                xl={3}
                                lg={3}
                                className="d-lg-block d-none"
                              >
                                &nbsp;
                              </Col>
                            </Row>
                          </Form.Group>
                          <Form.Group className="form-group">
                            <Row>
                              <Col lg={3}>
                                <Form.Label>Alarm Beep Time</Form.Label>
                              </Col>
                              <Col xxl={3} xl={6} lg={6} className="extraPX">
                                <InputButton
                                  type="text"
                                  value={
                                    form.settings.GeneralSettings.alarmbeeptime
                                  }
                                  onChange={(e) => {
                                    const re = /[^0-9]/g;
                                    setForm((prevData) => {
                                      prevData.settings.GeneralSettings.alarmbeeptime =
                                        e.target.value.replace(re, "");
                                      return {
                                        ...prevData,
                                      };
                                    });
                                  }}
                                  placeholder="Time in Seconds"
                                />
                              </Col>
                              <Col
                                xxl={6}
                                xl={3}
                                lg={3}
                                className="d-lg-block d-none"
                              >
                                &nbsp;
                              </Col>
                            </Row>
                          </Form.Group>
                          <Form.Group className="form-group">
                            <Row>
                              <Col lg={3}>
                                <Form.Label>Alarm Sound</Form.Label>
                              </Col>
                              <Col xxl={3} xl={6} lg={6} className="extraPX">
                                <Select
                                  isSearchable={false}
                                  className="select2 singleSelect"
                                  placeholder="Select Alarm Sound"
                                  options={notificationSoundList}
                                  value={form.settings.GeneralSettings.alarmsound}
                                  onChange={(e) => {
                                    setForm((prevData) => {
                                      prevData.settings.GeneralSettings.alarmsound = e
                                      return {
                                        ...prevData,
                                      };
                                    });
                                    playNotificationSoundAlarm()
                                  }}
                                  labelledBy="Select"
                                />
                              </Col>
                              <div>
                                <audio
                                  ref={audioRefAlarm}
                                  src={form.settings.GeneralSettings.alarmsound && form.settings.GeneralSettings.alarmsound.value !== undefined &&
                                    form.settings.GeneralSettings.alarmsound.value}
                                  type="audio/mpeg"
                                />
                              </div>

                              <Col
                                xxl={6}
                                xl={3}
                                lg={3}
                                className="d-lg-block d-none"
                              >
                                &nbsp;
                              </Col>
                            </Row>
                          </Form.Group>
                        </Tab.Pane>
                        <Tab.Pane className="d-none" eventKey="orders">
                          <Form>
                            <Form.Group className="form-group">
                              <Row>
                                <Col lg={3}>
                                  <Form.Label>Default Order Type</Form.Label>
                                </Col>
                                <Col xxl={3} xl={6} lg={6} className="extraPX">
                                  <Select
                                    isSearchable={false}
                                    className="select2 singleSelect"
                                    placeholder="Select Menu item First"
                                    options={orderTypeList}
                                    // value={selectDefaultOrderType}
                                    // onChange={setSelectDefaultOrderType}
                                    labelledBy="Select"
                                  />
                                </Col>
                                <Col
                                  xxl={6}
                                  xl={3}
                                  lg={3}
                                  className="d-lg-block d-none"
                                >
                                  &nbsp;
                                </Col>
                              </Row>
                            </Form.Group>
                            <Form.Group className="form-group">
                              <Row>
                                <Col lg={3}>
                                  <Form.Label>
                                    Custom Order Types{" "}
                                    <small>Max 5 types</small>
                                  </Form.Label>
                                </Col>
                                <Col lg={9} className="extraPX">
                                  <Row className="g-3">
                                  </Row>
                                </Col>
                              </Row>
                            </Form.Group>
                            <Form.Group className="form-group">
                              <Row>
                                <Col lg={3}>
                                  <Form.Label>Auto Refresh-Orders</Form.Label>
                                </Col>
                                <Col xxl={3} xl={6} lg={6} className="extraPX">
                                  <Form.Check
                                    // checked={orderSettings.autoRefreshOrders}

                                    onClick={(e) => {
                                      setOrderSettings({
                                        ...orderSettings,
                                        autoRefreshOrders: e.target.checked,
                                      });
                                    }}
                                    type="switch"
                                    id="custom-switch"
                                    className="enabled"
                                    label=""
                                  />
                                </Col>
                                <Col
                                  xxl={6}
                                  xl={3}
                                  lg={3}
                                  className="d-lg-block d-none"
                                >
                                  &nbsp;
                                </Col>
                              </Row>
                            </Form.Group>
                            <Form.Group className="form-group">
                              <Row>
                                <Col lg={3}>
                                  <Form.Label>Refresh Interval</Form.Label>
                                </Col>
                                <Col
                                  xxl={3}
                                  xl={6}
                                  lg={6}
                                  className="d-flex align-items-center extraPX"
                                >
                                  {/* <Form.Control type="number" placeholder="1" /> */}
                                  <InputButton
                                    placeholder="21"
                                    type="number"
                                    disabled={!orderSettings.autoRefreshOrders}
                                  />

                                  <span className="labelInfoText">min</span>
                                </Col>
                                <Col
                                  xxl={6}
                                  xl={3}
                                  lg={3}
                                  className="d-lg-block d-none"
                                >
                                  &nbsp;
                                </Col>
                              </Row>
                            </Form.Group>
                            <Form.Group className="form-group">
                              <Row>
                                <Col lg={3}>
                                  <Form.Label>Refresh Days</Form.Label>
                                </Col>
                                <Col xxl={3} xl={6} lg={6} className="extraPX">
                                  <Select
                                    className="select2 singleSelect"
                                    // menuIsOpen={true}
                                    placeholder="Select day"
                                    options={refreshDays}
                                    // value={selectedRefreshDays}
                                    // onChange={setSelectedRefreshDays}
                                    labelledBy="Select"
                                  />
                                </Col>
                                <Col
                                  xxl={6}
                                  xl={3}
                                  lg={3}
                                  className="d-lg-block d-none"
                                >
                                  &nbsp;
                                </Col>
                              </Row>
                            </Form.Group>
                            <Form.Group className="form-group">
                              <Row>
                                <Col lg={3}>
                                  <Form.Label>Refresh Time</Form.Label>
                                </Col>
                                <Col lg={9} className="extraPX">
                                  <Row className="g-3">
                                    <Col
                                      lg={4}
                                      md={6}
                                      className="d-flex align-items-center"
                                    >
                                      <span className="dateText">Start</span>{" "}
                                      <InputButton
                                        placeholder="10:00 am"
                                        type="text"
                                        disabled={
                                          !orderSettings.autoRefreshOrders
                                        }
                                      />
                                    </Col>
                                    <Col
                                      lg={4}
                                      md={6}
                                      className="d-flex align-items-center"
                                    >
                                      <span className="dateText">End</span>{" "}
                                      <InputButton
                                        placeholder="10:00 pm"
                                        type="text"
                                        disabled={
                                          !orderSettings.autoRefreshOrders
                                        }
                                      />
                                    </Col>
                                    <Col
                                      lg={4}
                                      md={6}
                                      className="d-flex align-items-center"
                                    >
                                      &nbsp;
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Form.Group>
                          </Form>
                        </Tab.Pane>
                        <Tab.Pane eventKey="payment">
                          <Form>
                            <Form.Group className="form-group">
                              <Row>
                                <Col lg={3}>
                                  <Form.Label>Tax Rate(s)</Form.Label>
                                </Col>
                                <Col
                                  xxl={3}
                                  xl={6}
                                  lg={6}
                                  className="extraPX d-flex align-items-center"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="Tax Rate"
                                    value={
                                      form.settings.PaymentSettings.taxrate
                                    }
                                    onChange={(e) => {
                                      const re = /[^0-9%.]/g;
                                      if (e.target.value.length < 4) {
                                        setForm((prevData) => {
                                          prevData.settings.PaymentSettings.taxrate =
                                            e.target.value.replace(re, "");
                                          return {
                                            ...prevData,
                                          };
                                        });
                                      }
                                    }}
                                  />
                                </Col>
                                <Col
                                  xxl={6}
                                  xl={3}
                                  lg={3}
                                  className="d-lg-block d-none"
                                >
                                  &nbsp;
                                </Col>
                              </Row>
                            </Form.Group>
                            <Form.Group className="form-group d-none">
                              <Row>
                                <Col lg={3}>
                                  <Form.Label>Enable Payment Types</Form.Label>
                                </Col>
                                <Col xxl={3} xl={6} lg={6} className="extraPX">
                                  <Select
                                    className="select2 multiSelect"
                                    placeholder="Select Payment Type"
                                    isMulti={true}
                                    // menuIsOpen={true}
                                    isClearable={false}
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    options={paymentTypeList}
                                    value={
                                      form.settings.PaymentSettings
                                        .enablepaymentmethod
                                    }
                                    onChange={(e) => {
                                      setForm((prevData) => {
                                        prevData.settings.PaymentSettings.enablepaymentmethod =
                                          e;
                                        return {
                                          ...prevData,
                                        };
                                      });
                                    }}
                                    labelledBy="Select"
                                  />
                                </Col>
                                <Col
                                  xxl={6}
                                  xl={3}
                                  lg={3}
                                  className="d-lg-block d-none"
                                >
                                  &nbsp;
                                </Col>
                              </Row>
                            </Form.Group>
                            <Form.Group className="form-group">
                              <Row>
                                <Col lg={3}>
                                  <Form.Label>Default Payment Types</Form.Label>
                                </Col>
                                <Col xxl={3} xl={6} lg={6} className="extraPX">
                                  <Select
                                    isSearchable={false}
                                    className="select2 singleSelect"
                                    placeholder="Select Payment Type"
                                    // menuIsOpen={true}
                                    options={paymentTypeList}
                                    value={
                                      form.settings.PaymentSettings
                                        .defaultpaymentmethod
                                    }
                                    onChange={(e) => {
                                      setForm((prevData) => {
                                        prevData.settings.PaymentSettings.defaultpaymentmethod =
                                          e;
                                        return {
                                          ...prevData,
                                        };
                                      });
                                    }}
                                    labelledBy="Select"
                                  />
                                </Col>
                                <Col
                                  xxl={6}
                                  xl={3}
                                  lg={3}
                                  className="d-lg-block d-none"
                                >
                                  &nbsp;
                                </Col>
                              </Row>
                            </Form.Group>
                            <Form.Group className="form-group">
                              <Row>
                                <Col lg={3}>
                                  <Form.Label>Enable Cash Drawer</Form.Label>
                                </Col>
                                <Col xxl={3} xl={6} lg={6} className="extraPX">
                                  <Form.Check
                                    disabled={!permissionData.cashdrawer ? true : false}
                                    onClick={(e) => {
                                      setForm((prevData) => {
                                        prevData.settings.PaymentSettings.cashdrawer =
                                          e.target.checked == true ? 1 : 0; //eslint-disable-line
                                        return {
                                          ...prevData,
                                        };
                                      });
                                    }}
                                    checked={
                                      form.settings.PaymentSettings.cashdrawer ==
                                        1
                                        ? true
                                        : false
                                    } //eslint-disable-line
                                    onChange={() => { }}
                                    type="switch"
                                    id="custom-switch"
                                    className="enabled"
                                    label=""
                                  />
                                </Col>
                                <Col
                                  xxl={6}
                                  xl={3}
                                  lg={3}
                                  className="d-lg-block d-none"
                                >
                                  &nbsp;
                                </Col>
                              </Row>
                            </Form.Group>
                            <Form.Group className="form-group">
                              <Row>
                                <Col lg={3}>
                                  <Form.Label>Enable Card Reader</Form.Label>
                                </Col>
                                {console.log('permissionData', permissionData)}
                                <Col xxl={3} xl={6} lg={6} className="extraPX">
                                  <Form.Check
                                    disabled={!permissionData.cardreader ? true : false}
                                    onClick={(e) => {
                                      setForm((prevData) => {
                                        prevData.settings.PaymentSettings.cardreader =
                                          e.target.checked == true ? 1 : 0; //eslint-disable-line
                                        return {
                                          ...prevData,
                                        };
                                      });
                                    }}
                                    checked={
                                      form.settings.PaymentSettings.cardreader ==
                                        1
                                        ? true
                                        : false
                                    } //eslint-disable-line
                                    onChange={() => { }}
                                    type="switch"
                                    id="custom-switch"
                                    className="enabled"
                                    label=""
                                  />
                                </Col>
                                <Col
                                  xxl={6}
                                  xl={3}
                                  lg={3}
                                  className="d-lg-block d-none"
                                >
                                  &nbsp;
                                </Col>
                              </Row>
                            </Form.Group>
                            <Form.Group className="form-group">
                              <Row>
                                <Col lg={3}>
                                  <Form.Label>Discounts</Form.Label>
                                </Col>
                                <Col xxl={3} xl={6} lg={6} className="extraPX">
                                  <Form.Check
                                    onClick={(e) => {
                                      setForm((prevData) => {
                                        prevData.settings.PaymentSettings.discount =
                                          e.target.checked == true ? 1 : 0; //eslint-disable-line
                                        return {
                                          ...prevData,
                                        };
                                      });
                                    }}
                                    checked={
                                      form.settings.PaymentSettings.discount ==
                                        1
                                        ? true
                                        : false
                                    } //eslint-disable-line
                                    onChange={() => { }}
                                    type="switch"
                                    id="custom-switch"
                                    className="enabled"
                                    label=""
                                  />
                                </Col>
                                <Col
                                  xxl={6}
                                  xl={3}
                                  lg={3}
                                  className="d-lg-block d-none"
                                >
                                  &nbsp;
                                </Col>
                              </Row>
                            </Form.Group>
                            <Form.Group className="form-group">
                              <Row>
                                <Col lg={3}>
                                  <Form.Label>Gratuity</Form.Label>
                                </Col>
                                <Col xxl={3} xl={6} lg={6} className="extraPX">
                                  <Form.Check
                                    onClick={(e) => {
                                      setForm((prevData) => {
                                        prevData.settings.PaymentSettings.gratuity =
                                          e.target.checked == true ? 1 : 0; //eslint-disable-line
                                        return {
                                          ...prevData,
                                        };
                                      });
                                    }}
                                    checked={
                                      form.settings.PaymentSettings.gratuity ==
                                        1
                                        ? true
                                        : false
                                    } //eslint-disable-line
                                    onChange={() => { }}
                                    type="switch"
                                    id="custom-switch"
                                    className="enabled"
                                    label=""
                                  />
                                </Col>
                                <Col
                                  xxl={6}
                                  xl={3}
                                  lg={3}
                                  className="d-lg-block d-none"
                                >
                                  &nbsp;
                                </Col>
                              </Row>
                            </Form.Group>
                            <Form.Group className="form-group">
                              <Row>
                                <Col lg={3}>
                                  <Form.Label>
                                    {/* // gratuityAmount: [] */}
                                    Gratuity Amounts
                                  </Form.Label>
                                </Col>
                                {/* {console.log('form', form.settings.PaymentSettings.gratuityAmount)} */}
                                <Col lg={9} className="extraPX">
                                  {[...Array(grauityAmountCount)].map(
                                    (value, index) => {
                                      return (
                                        <Row className="g-3" key={`${index}`}>
                                          <CustomInputBoxes
                                            hideDeleteButton={form.settings.PaymentSettings.gratuity}
                                            disabled={!form.settings.PaymentSettings.gratuity == 1 ? true : false} //eslint-disable-line
                                            value={
                                              form.settings.PaymentSettings
                                                .gratuityAmount[index]
                                            }
                                            index={index}
                                            onChange={(e) => {
                                              const inputNumber = e.target.value;
                                              const threeDigitRegex = /^\d{0,3}$/; // Allow up to 3 digits
                                              if (threeDigitRegex.test(inputNumber)) {
                                                setForm((prevData) => {
                                                  prevData.settings.PaymentSettings.gratuityAmount[index] = inputNumber;
                                                  return {
                                                    ...prevData,
                                                  };
                                                });
                                              }
                                            }}

                                            onClick={(event) =>
                                              decrementGratuity(event, index)
                                            }
                                            placeholder="5%"
                                            count={index}
                                            grauityAmountCount={
                                              grauityAmountCount
                                            }
                                          />
                                          {index == grauityAmountCount - 1 && !!form.settings.PaymentSettings.gratuity &&
                                            grauityAmountCount < 10 && ( //eslint-disable-line
                                              <Col
                                                lg={12}
                                                className="d-flex align-items-center "
                                              >
                                                <Button
                                                  type="button"
                                                  className="commonButton commonButton--white  commonButton--small"
                                                  onClick={(event) => {
                                                    event.preventDefault()
                                                    if (index >= 3) {
                                                      toastMessages('error', "Maximum rows have already been added")
                                                    } else {
                                                      incrementGratuity(event, index)
                                                    }
                                                  }
                                                  }
                                                >
                                                  Add Gratuity
                                                </Button>
                                              </Col>
                                            )}
                                        </Row>

                                      )
                                    }
                                  )}
                                </Col>
                              </Row>
                            </Form.Group>
                            <Form.Group className="form-group">
                              <Row>
                                <Col lg={3}>
                                  <Form.Label>Payment Gateway</Form.Label>
                                </Col>
                                <Col xxl={3} xl={6} lg={6} className="extraPX">
                                  <Select
                                    isSearchable={false}
                                    autoComplete="off"
                                    className="select2 multiSelect"
                                    placeholder="Select Payment Type"
                                    // isMulti={true}
                                    isClearable={false}
                                    hideSelectedOptions={false}
                                    options={[
                                      { label: "Stripe", value: "Stripe" },
                                    ]}
                                    value={
                                      form.settings.PaymentSettings
                                        .paymentGateway
                                    }
                                    onChange={(e) => {
                                      setForm((prevData) => {
                                        prevData.settings.PaymentSettings.paymentGateway =
                                          e;
                                        return {
                                          ...prevData,
                                        };
                                      });
                                    }}
                                    labelledBy="Select"
                                  />
                                </Col>
                                <Col
                                  xxl={6}
                                  xl={3}
                                  lg={3}
                                  className="d-lg-block d-none">
                                  &nbsp;
                                </Col>
                              </Row>
                            </Form.Group>
                            {Object.keys(
                              form.settings.PaymentSettings.paymentGateway
                            ).length ? (
                              <Form.Group className="form-group">
                                <Row>
                                  <Col lg={3}>
                                    <Form.Label></Form.Label>
                                  </Col>
                                  <Col lg={9} className="extraPX">
                                    <Row className="g-3">
                                      <Col lg={12} md={12} className="">
                                        <Form.Label>Public Key</Form.Label>
                                        <div className="passwordWrap" >
                                          <Form.Control
                                            // autoComplete="off"
                                            autoComplete="new-password"
                                            disabled={userInfo && userInfo?.rolelevel <= 90 ? true : false}
                                            value={form?.settings?.PaymentSettings?.paymentGatewayKeys.filter((item) =>
                                              item.name ==
                                              form.settings.PaymentSettings
                                                .paymentGateway.label
                                            )[0]?.keys?.public
                                            }
                                            onChange={(e) => {
                                              let keys = form.settings.PaymentSettings.paymentGateway.label;
                                              let isExist = form.settings?.PaymentSettings?.paymentGatewayKeys?.filter((itemFilter) => itemFilter.name == keys)
                                              if (typeof isExist == "undefined" || isExist.length == 0) {
                                                setForm((prevData) => {
                                                  prevData?.settings?.PaymentSettings?.paymentGatewayKeys.push(
                                                    {
                                                      name: keys,
                                                      keys: {
                                                        public: e.target.value,
                                                      },
                                                    }
                                                  );
                                                  return {
                                                    ...prevData,
                                                  };
                                                });
                                              } else {
                                                setForm((prev) => {
                                                  prev.settings.PaymentSettings.paymentGatewayKeys.map(
                                                    (item) => {
                                                      if (item.name == keys) {
                                                        item.name = keys
                                                        item.keys.public = e.target.value
                                                      }
                                                    }
                                                  );
                                                  return {
                                                    ...prev,
                                                  };
                                                });
                                              }
                                            }}
                                            type={`${showKey.publicKey ? "text" : "password"}`}
                                            placeholder="public key"
                                            className="notCapital iconPE"
                                          />
                                          <Button
                                            className="showPassIcon"
                                            type="button"
                                          >
                                            <i
                                              onClick={() =>
                                                clickHandler("public")
                                              }
                                              className={
                                                showKey.publicKey
                                                  ? "fas fa-eye"
                                                  : "fas fa-eye-slash"
                                              }
                                            ></i>
                                          </Button>
                                        </div>
                                      </Col>
                                      <Col lg={12} md={12} className="">
                                        <Form.Label>Secret Key</Form.Label>
                                        <div className="passwordWrap">
                                          <Form.Control
                                            disabled={(userInfo && userInfo?.rolelevel <= 90 ? true : false)}
                                            className="notCapital iconPE"
                                            autoComplete="off"
                                            // autoComplete="new-password"
                                            value={
                                              form.settings.PaymentSettings.paymentGatewayKeys.filter(
                                                (item) =>
                                                  item.name ==
                                                  form.settings.PaymentSettings
                                                    .paymentGateway.label
                                              )[0]?.keys?.private
                                            }
                                            onChange={(e) => {
                                              let keys =
                                                form.settings.PaymentSettings
                                                  .paymentGateway.label;
                                              let isExist =
                                                form?.settings?.PaymentSettings?.paymentGatewayKeys?.filter(
                                                  (itemFilter) =>
                                                    itemFilter.name == keys
                                                );
                                              // console.log("isExist", isExist);
                                              if (typeof isExist == "undefined" || isExist.length == 0) {
                                                setForm((prevData) => {
                                                  prevData.settings.PaymentSettings.paymentGatewayKeys.push(
                                                    {
                                                      name: keys,
                                                      keys: {
                                                        private: "",
                                                        private: e.target.value,
                                                      },
                                                    }
                                                  );
                                                  return {
                                                    ...prevData,
                                                  };
                                                });
                                              } else {
                                                setForm((prev) => {
                                                  prev.settings.PaymentSettings.paymentGatewayKeys.map(
                                                    (item) => {
                                                      if (item.name == keys) {
                                                        item.name = keys
                                                        item.keys.private =
                                                          e.target.value
                                                      }
                                                    }
                                                  );
                                                  return {
                                                    ...prev,
                                                  };
                                                });
                                              }
                                            }}
                                            type={`${showKey.secretKey
                                              ? "text"
                                              : "password"
                                              }`}
                                            placeholder="secret Key"
                                          />

                                          <Button
                                            className="showPassIcon"
                                            type="button"
                                          >
                                            <i
                                              onClick={() =>
                                                clickHandler("secret")
                                              }
                                              className={
                                                showKey.secretKey
                                                  ? "fas fa-eye"
                                                  : "fas fa-eye-slash"
                                              }
                                            ></i>
                                          </Button>
                                        </div>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </Form.Group>
                            ) : (
                              <></>
                            )}
                          </Form>
                        </Tab.Pane>
                        <Tab.Pane eventKey="receipt">
                          <Form>
                            <Form.Group className="form-group">
                              <Row>
                                <Col lg={3}>
                                  <Form.Label>Merchant Receipt</Form.Label>
                                </Col>
                                <Col xl={6} lg={6} className="extraPX">
                                  {["radio"].map((type, idx) => {
                                    return (
                                      <div
                                        key={`default-${idx}`}
                                        className="formCheckbox formCheckbox--inline"
                                      >
                                        <Form.Check
                                          checked={
                                            form.settings.Receipt
                                              .merchantreceipt == 1
                                              ? true
                                              : false
                                          } //eslint-disable-line
                                          onChange={() => { }}
                                          onClick={() => {
                                            setForm((prevData) => {
                                              prevData.settings.Receipt.merchantreceipt = 1;
                                              return {
                                                ...prevData,
                                              };
                                            });
                                          }}
                                          name={`sizeSelected-${form.settings.Receipt.merchantreceipt}${idx}${small_id}`}
                                          id={`yesCheck-${form.settings.Receipt.merchantreceipt}${idx}${small_id}`}
                                          type={type}
                                          label="Show Items"
                                        />
                                        <Form.Check
                                          checked={
                                            form.settings.Receipt
                                              .merchantreceipt == 0
                                              ? true
                                              : false
                                          } //eslint-disable-line
                                          onChange={() => { }}
                                          onClick={() => {
                                            setForm((prevData) => {
                                              prevData.settings.Receipt.merchantreceipt = 0;
                                              return {
                                                ...prevData,
                                              };
                                            });
                                          }}
                                          name={`sizeSelected-${form.settings.Receipt.merchantreceipt}${idx}${small_id}`}
                                          id={`yesCheck-${form.settings.Receipt.merchantreceipt}${idx}${small_id}`}
                                          label="Hide Items"
                                          type={type}
                                        />
                                      </div>
                                    )
                                  })}
                                </Col>
                                <Col
                                  xxl={6}
                                  xl={3}
                                  lg={3}
                                  className="d-lg-block d-none"
                                >
                                  &nbsp;
                                </Col>
                              </Row>
                            </Form.Group>
                            <Form.Group className="form-group">
                              <Row>
                                <Col lg={3}>
                                  <Form.Label>Enable Printer</Form.Label>
                                </Col>
                                <Col xl={6} lg={6} className="extraPX">
                                  <Form.Check
                                    disabled={!permissionData.printer ? true : false}
                                    onClick={(e) => {
                                      setForm((prevData) => {
                                        prevData.settings.Receipt.printer =
                                          e.target.checked == true ? 1 : 0; //eslint-disable-line

                                        return {
                                          ...prevData,
                                        };
                                      });
                                    }}
                                    checked={
                                      form.settings.Receipt
                                        .printer == 1
                                        ? true
                                        : false
                                    } //eslint-disable-line
                                    onChange={() => { }}
                                    type="switch"
                                    id="custom-switch"
                                    className="enabled"
                                    label=""
                                  />
                                </Col>
                                <Col
                                  xxl={6}
                                  xl={3}
                                  lg={3}
                                  className="d-lg-block d-none"
                                >
                                  &nbsp;
                                </Col>
                              </Row>
                            </Form.Group>
                            <Form.Group className="form-group">
                              <Row>
                                <Col lg={3}>
                                  <Form.Label>Receipt Message</Form.Label>
                                </Col>
                                <Col xxl={5} xl={6} lg={6} className="extraPX">
                                  <Form.Control
                                    value={form.settings.Receipt.receiptmessage}
                                    onChange={(e) => {
                                      setForm((prevData) => {
                                        prevData.settings.Receipt.receiptmessage =
                                          e.target.value;
                                        return {
                                          ...prevData,
                                        };
                                      });
                                    }}
                                    as="textarea"
                                    placeholder="Thank you for visiting us. See you soon!"
                                  />
                                </Col>
                                <Col
                                  xxl={4}
                                  xl={3}
                                  lg={3}
                                  className="d-lg-block d-none"
                                >
                                  &nbsp;
                                </Col>
                              </Row>
                            </Form.Group>
                            <Form.Group className="form-group">
                              <Row>
                                <Col lg={3}>
                                  <Form.Label>
                                    Kitchen Ticket Font Size
                                  </Form.Label>
                                </Col>
                                <Col lg={9} className="extraPX">
                                  <Row className="g-3">
                                    <Col lg={4} md={6} className="">
                                      <Form.Label>Items Size</Form.Label>
                                      <Form.Control
                                        value={
                                          form.settings.Receipt.fontsize.product
                                        }
                                        onChange={(e) => {
                                          const re = /[^0-9]/g;
                                          setForm((prevData) => {
                                            prevData.settings.Receipt.fontsize.product =
                                              e.target.value.replace(re, "");
                                            return {
                                              ...prevData,
                                            };
                                          });
                                        }}
                                        type="text"
                                        placeholder="20"
                                      />
                                    </Col>
                                    <Col lg={4} md={6} className="">
                                      <Form.Label>
                                        Choices & Addons Size
                                      </Form.Label>
                                      <Form.Control
                                        value={
                                          form.settings.Receipt.fontsize
                                            .modifiers
                                        }
                                        onChange={(e) => {
                                          const re = /[^0-9]/g;
                                          setForm((prevData) => {
                                            prevData.settings.Receipt.fontsize.modifiers =
                                              e.target.value.replace(re, "");
                                            return {
                                              ...prevData,
                                            };
                                          });
                                        }}
                                        type="text"
                                        placeholder="18"
                                      />
                                    </Col>
                                    <Col lg={4} md={6} className="">
                                      <Form.Label>Note Size</Form.Label>
                                      <Form.Control
                                        value={
                                          form.settings.Receipt.fontsize.note
                                        }
                                        onChange={(e) => {
                                          const re = /[^0-9]/g;
                                          setForm((prevData) => {
                                            prevData.settings.Receipt.fontsize.note =
                                              e.target.value.replace(re, "");
                                            return {
                                              ...prevData,
                                            };
                                          });
                                        }}
                                        type="text"
                                        placeholder="20"
                                      />
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Form.Group>
                          </Form>
                        </Tab.Pane>
                        <Tab.Pane eventKey="reports">
                          <Form>
                            <Form.Group className="form-group d-none">
                              <Row>
                                <Col lg={3}>
                                  <Form.Label>Auto Refresh-Orders</Form.Label>
                                </Col>
                                <Col
                                  xxl={5}
                                  xl={6}
                                  lg={7}
                                  className="d-flex extraPX"
                                >
                                  <Form.Check
                                    checked={reportsSettings.autoRefreshOrders}
                                    onChange={() => { }}
                                    onClick={(e) => {
                                      setReportsSettings({
                                        ...reportsSettings,
                                        autoRefreshOrders: e.target.checked,
                                      });
                                    }}
                                    type="switch"
                                    id="custom-switch"
                                    className="enabled"
                                    label=""
                                  />
                                  <span className="labelInfoText">
                                    Every morning at around 4:00 AM.
                                  </span>
                                </Col>
                                <Col xxl={4} xl={3} lg={2}>
                                  &nbsp;
                                </Col>
                              </Row>
                            </Form.Group>
                            <Form.Group className="form-group">
                              <Row>
                                <Col lg={3}>
                                  <Form.Label>Email</Form.Label>
                                </Col>
                                <Col xxl={3} xl={6} lg={6} className="extraPX">
                                  <Form.Group className="form-group">
                                    <div className="formControlIcon">
                                      <img
                                        src={reportsIcon}
                                        alt="reportsIcon"
                                      />
                                    </div>
                                    <Form.Control
                                      value={form.settings.Reports.email}
                                      onChange={emailValidation}
                                      type="email"
                                      className="notCapital"
                                      placeholder="email@mail.com"
                                    />
                                    {form.settings.Reports.email !== "" ? ( //eslint-disable-line
                                      error &&
                                        error !== "The email address is valid" ? ( //eslint-disable-line
                                        <small
                                          style={{
                                            color: "red",
                                          }}
                                        >
                                          Default Order Type
                                          {error}
                                        </small>
                                      ) : (
                                        <small
                                          style={{
                                            color: "green",
                                          }}
                                        >
                                          {error}
                                        </small>
                                      )
                                    ) : (
                                      ""
                                    )}
                                  </Form.Group>
                                </Col>
                                <Col
                                  xxl={6}
                                  xl={3}
                                  lg={3}
                                  className="d-lg-block d-none"
                                >
                                  &nbsp;
                                </Col>
                              </Row>
                            </Form.Group>
                          </Form>
                        </Tab.Pane>
                        <Tab.Pane eventKey="businessInfo">
                          <Form>
                            <Form.Group className="form-group">
                              <Row>
                                <Col lg={3}>
                                  <Form.Label>Business Name</Form.Label>
                                </Col>
                                <Col xxl={3} xl={6} lg={6} className="extraPX">
                                  <Form.Control
                                    value={
                                      form.settings.BusinessInfo.businessname
                                    }
                                    onChange={(e) => {
                                      setForm((prevData) => {
                                        prevData.settings.BusinessInfo.businessname =
                                          e.target.value;
                                        return {
                                          ...prevData,
                                        };
                                      });
                                    }}
                                    type="text"
                                    placeholder="Great Eats"
                                  />
                                </Col>
                                <Col
                                  xxl={6}
                                  xl={3}
                                  lg={3}
                                  className="d-lg-block d-none"
                                >
                                  &nbsp;
                                </Col>
                              </Row>
                            </Form.Group>
                            <Form.Group className="form-group">
                              <Row>
                                <Col lg={3}>
                                  <Form.Label>Logo</Form.Label>
                                </Col>
                                <Col
                                  xxl={3} xl={6} lg={6}
                                  className="extraPX"
                                >
                                  <Form.Control
                                    onChange={(e) => {
                                      if (e) {
                                        const file = e.target.files;
                                        if (file &&
                                          file[0] &&
                                          file[0].type &&
                                          file[0].type.split('/')[0] == "image") {
                                          console.log('------2293-----', file[0])
                                          setIsNewImageUploaded(true)
                                          setPreview(URL.createObjectURL(file[0]));
                                          setForm((prevData) => {
                                            prevData.settings.BusinessInfo.logo = file[0];
                                            return {
                                              ...prevData,
                                            };
                                          });
                                        }
                                        else {
                                          toastMessages('error', 'The image format is not correct')
                                        }
                                      }
                                    }}
                                    type="file"
                                    placeholder=""
                                    accept="image/*"
                                    required
                                    style={{ display: "none" }}
                                    id="file-upload"
                                  />
                                  <label htmlFor="file-upload" className="custom-file-upload">
                                    Upload Image
                                  </label>
                                  <div className="imageView"
                                    onMouseOver={() => {
                                      setOnHoverImage(true)
                                    }}
                                  >
                                    {preview &&
                                      preview.length > 0 &&
                                      <img
                                        style={{ cursor: 'pointer', width: '30%', height: '30%', marginTop: "12px" }}
                                        src={preview}
                                        alt="branchImage"
                                      />}
                                    {preview &&
                                      preview.length > 0 &&
                                      onHoverImage &&
                                      <i
                                        style={{
                                          marginLeft: "4px"
                                        }}
                                        className="fa-solid fa-trash-can pointer"


                                        onClick={() => {
                                          setPreview([]);
                                          setDeleteImage(true)
                                        }}></i>}
                                  </div>
                                </Col>
                                <Col
                                  xxl={6}
                                  xl={3}
                                  lg={3}
                                  className="d-lg-block d-none"
                                >
                                  &nbsp;
                                </Col>
                              </Row>
                            </Form.Group>
                            <Form.Group className="form-group">
                              <Row>
                                <Col lg={3}>
                                  <Form.Label>Address</Form.Label>
                                </Col>
                                <Col xxl={3} xl={6} lg={6} className="extraPX">
                                  <Form.Control
                                    value={form.settings.BusinessInfo.address}
                                    onChange={(e) => {
                                      setForm((prevData) => {
                                        prevData.settings.BusinessInfo.address =
                                          e.target.value;
                                        return {
                                          ...prevData,
                                        };
                                      });
                                    }}
                                    type="text"
                                    placeholder="1635 Wise Rd., Ste 8"
                                  />
                                </Col>
                                <Col
                                  xxl={6}
                                  xl={3}
                                  lg={3}
                                  className="d-lg-block d-none"
                                >
                                  &nbsp;
                                </Col>
                              </Row>
                            </Form.Group>
                            <Form.Group className="form-group">
                              <Row>
                                <Col lg={3}>
                                  <Form.Label>City</Form.Label>
                                </Col>
                                <Col xxl={3} xl={6} lg={6} className="extraPX">
                                  <Form.Control
                                    value={form.settings.BusinessInfo.city}
                                    onChange={(e) => {
                                      const alphabetRegex = /^[a-zA-Z ]*$/;
                                      if (e.target.value.match(alphabetRegex)) {
                                        setForm((prevData) => {
                                          prevData.settings.BusinessInfo.city =
                                            e.target.value;
                                          return {
                                            ...prevData,
                                          };
                                        });
                                      }
                                    }}
                                    type="text"
                                    placeholder="Schaumburg"
                                  />
                                </Col>
                                <Col
                                  xxl={6}
                                  xl={3}
                                  lg={3}
                                  className="d-lg-block d-none"
                                >
                                  &nbsp;
                                </Col>
                              </Row>
                            </Form.Group>
                            <Form.Group className="form-group">
                              <Row>
                                <Col lg={3}>
                                  <Form.Label>State</Form.Label>
                                </Col>
                                <Col xxl={3} xl={6} lg={6} className="extraPX">
                                  <Form.Control
                                    type="text"
                                    placeholder="IL"
                                    value={form.settings.BusinessInfo.stateId}
                                    onChange={(e) => {
                                      const alphabetRegex = /^[a-zA-Z ]*$/;
                                      if (e.target.value.match(alphabetRegex)) {
                                        setForm((prevData) => {
                                          prevData.settings.BusinessInfo.stateId =
                                            e.target.value;
                                          return {
                                            ...prevData,
                                          };
                                        });
                                      }
                                    }}
                                  />
                                </Col>
                                <Col
                                  xxl={6}
                                  xl={3}
                                  lg={3}
                                  className="d-lg-block d-none"
                                >
                                  &nbsp;
                                </Col>
                              </Row>
                            </Form.Group>
                            <Form.Group className="form-group">
                              <Row>
                                <Col lg={3}>
                                  <Form.Label>Zip Code</Form.Label>
                                </Col>
                                <Col xxl={3} xl={6} lg={6} className="extraPX">
                                  <Form.Control
                                    type="number"
                                    placeholder="60193"
                                    value={form.settings.BusinessInfo.zipcode}
                                    onKeyDown={(evt) =>
                                      (evt.key === "e" || evt.key === "E") &&
                                      evt.preventDefault()
                                    }
                                    onChange={(e) =>
                                      setForm((prevData) => {
                                        prevData.settings.BusinessInfo.zipcode =
                                          e.target.value;
                                        return {
                                          ...prevData,
                                        };
                                      })
                                    }
                                  />
                                </Col>
                                <Col
                                  xxl={6}
                                  xl={3}
                                  lg={3}
                                  className="d-lg-block d-none"
                                >
                                  &nbsp;
                                </Col>
                              </Row>
                            </Form.Group>
                            <Form.Group className="form-group">
                              <Row>
                                <Col lg={3}>
                                  <Form.Label>Country</Form.Label>
                                </Col>
                                <Col xxl={3} xl={6} lg={6} className="extraPX">
                                  <Select
                                    className="select2"
                                    placeholder="Please Select Country"
                                    options={countries}
                                    value={countries.find(
                                      (item) =>
                                        item.value ==
                                        form.settings.BusinessInfo.country
                                    )}
                                    labelledBy="Select"
                                    onChange={(e) => {
                                      setForm((prevData) => {
                                        prevData.settings.BusinessInfo.country =
                                          e.value;
                                        prevData.settings.BusinessInfo.countrycode =
                                          e.code;
                                        // console.log("e----->", e);
                                        return {
                                          ...prevData,
                                        };
                                      });
                                    }}
                                  />
                                </Col>
                                <Col
                                  xxl={6}
                                  xl={3}
                                  lg={3}
                                  className="d-lg-block d-none"
                                >
                                  &nbsp;
                                </Col>
                              </Row>
                            </Form.Group>
                            <Form.Group className="form-group">
                              <Row>
                                <Col lg={3}>
                                  <Form.Label>Phone</Form.Label>
                                </Col>
                                <Col xxl={3} xl={6} lg={6} className="extraPX">
                                  <PhoneInput
                                    country="us"
                                    value={form.settings.BusinessInfo.phone}
                                    onChange={handleOnChange}
                                    enableSearch={true}
                                    inputStyle={{ width: "100%" }}
                                  />
                                  {!isPhoneNumberValid && form.settings.BusinessInfo.phone && (
                                    <small style={{ color: "red" }}>
                                      {phoneValidatError}
                                    </small>
                                  )}
                                </Col>
                                <Col
                                  xxl={6}
                                  xl={3}
                                  lg={3}
                                  className="d-lg-block d-none"
                                >
                                  &nbsp;
                                </Col>
                                {/* {console.log('----3021---', !isPhoneNumberValid && form.settings.BusinessInfo.phone,
                                  !isPhoneNumberValid, form.settings.BusinessInfo.phone)} */}

                              </Row>
                            </Form.Group>
                          </Form>
                        </Tab.Pane>
                        <Tab.Pane eventKey="kitchenApp">
                          <Form>
                            <Form.Group className="form-group">
                              <Row>
                                <Col lg={3}>
                                  <Form.Label> Enable Kitchen Display</Form.Label>
                                </Col>
                                <Col xxl={3} xl={6} lg={6} className="extraPX">
                                  <Form.Check
                                    onClick={(e) => {
                                      setForm((prevData) => {
                                        prevData.settings.KitchenApp.enablekitchendisplay =
                                          e.target.checked == true ? 1 : 0; //eslint-disable-line
                                        return {
                                          ...prevData,
                                        };
                                      });
                                    }}
                                    checked={
                                      form.settings.KitchenApp.enablekitchendisplay == 1
                                        ? true
                                        : false
                                    } //eslint-disable-line
                                    onChange={() => { }}
                                    type="switch"
                                    id="custom-switch"
                                    className="enabled"
                                    label=""
                                  />
                                </Col>
                                <Col
                                  xxl={6}
                                  xl={3}
                                  lg={3}
                                  className="d-lg-block d-none"
                                >
                                  &nbsp;
                                </Col>
                              </Row>
                            </Form.Group>
                            <Form.Group className="form-group">
                              <Row>
                                <Col lg={3}>
                                  <Form.Label>Order Notification Sound</Form.Label>
                                </Col>
                                <Col xxl={3} xl={6} lg={6} className="extraPX">
                                  <Form.Check
                                    onClick={(e) => {
                                      setForm((prevData) => {
                                        prevData.settings.KitchenApp.ordernotificationsound =
                                          e.target.checked == true ? 1 : 0; //eslint-disable-line
                                        console.log('-----2659---', prevData.settings.KitchenApp)
                                        if (!prevData.settings.KitchenApp.ordernotificationsound) {
                                          prevData.settings.KitchenApp.notificationsound = { value: '', label: '' }
                                        }
                                        else {
                                          if (currentlySelectedNotification.value) {
                                            prevData.settings.KitchenApp.notificationsound =
                                              { value: currentlySelectedNotification.value, label: currentlySelectedNotification.label }
                                          }
                                          else {
                                            prevData.settings.KitchenApp.notificationsound =
                                              { value: notificationSoundList[0].value, label: notificationSoundList[0].label }
                                          }
                                        }
                                        return {
                                          ...prevData,
                                        };
                                      });
                                    }}
                                    checked={
                                      form.settings.KitchenApp.ordernotificationsound == 1
                                        ? true
                                        : false
                                    } //eslint-disable-line
                                    onChange={() => { }}
                                    type="switch"
                                    id="custom-switch"
                                    className="enabled"
                                    label=""
                                  />
                                </Col>
                                <Col
                                  xxl={6}
                                  xl={3}
                                  lg={3}
                                  className="d-lg-block d-none"
                                >
                                  &nbsp;
                                </Col>
                              </Row>
                            </Form.Group>
                            <Form.Group className="form-group">
                              <Row>
                                <Col lg={3}>
                                  <Form.Label>Select Notification Sound</Form.Label>
                                </Col>
                                <Col xxl={3} xl={6} lg={6} className="extraPX">
                                  <Select
                                    isDisabled={form.settings.KitchenApp.ordernotificationsound == 1 ? false : true}
                                    className="select2"
                                    placeholder="Select Notification Sound"
                                    options={notificationSoundList}
                                    value={
                                      notificationSoundList &&
                                      notificationSoundList.find((item) => item.label == form.settings.KitchenApp.notificationsound.label)
                                    }
                                    onChange={(e) => {
                                      // playNotificationSound
                                      setForm((prevData) => {
                                        prevData.settings.KitchenApp.notificationsound = e
                                        return {
                                          ...prevData,
                                        };
                                      });
                                      playNotificationSound()
                                    }}
                                    labelledBy="Select"
                                  />
                                </Col>
                                <div>
                                  <audio
                                    ref={audioRef}
                                    src={form.settings.KitchenApp.notificationsound.value !== undefined &&
                                      form.settings.KitchenApp.notificationsound.value}
                                    type="audio/mpeg"
                                  />
                                </div>
                                <Col
                                  xxl={6}
                                  xl={3}
                                  lg={3}
                                  className="d-lg-block d-none"
                                >
                                  &nbsp;
                                </Col>
                              </Row>
                            </Form.Group>
                            <Form.Group className="form-group">
                              <Row>
                                <Col lg={3}>
                                  <Form.Label>Time until Warning color(sec)</Form.Label>
                                </Col>
                                <Col xxl={3} xl={6} lg={6} className="extraPX">
                                  <Form.Control
                                    type="text"
                                    placeholder="Time in Seconds"
                                    value={
                                      form.settings.KitchenApp.timeuntilwarningcolor
                                    }
                                    onChange={(e) => {
                                      const re = /[^0-9%.]/g;
                                      setForm((prevData) => {
                                        prevData.settings.KitchenApp.timeuntilwarningcolor =
                                          e.target.value.replace(re, "");
                                        return {
                                          ...prevData,
                                        };
                                      });
                                    }}
                                  />
                                </Col>
                                <Col
                                  xxl={6}
                                  xl={3}
                                  lg={3}
                                  className="d-lg-block d-none"
                                >
                                  &nbsp;
                                </Col>
                              </Row>
                            </Form.Group>
                            <Form.Group className="form-group">
                              <Row>
                                <Col lg={3}>
                                  <Form.Label>Time until late color (sec)</Form.Label>
                                </Col>
                                <Col xxl={3} xl={6} lg={6} className="extraPX">
                                  <Form.Control
                                    type="text"
                                    placeholder="Time in Seconds"
                                    value={
                                      form.settings.KitchenApp.timeuntillatecolor
                                    }
                                    onChange={(e) => {
                                      const re = /[^0-9%.]/g;
                                      setForm((prevData) => {
                                        prevData.settings.KitchenApp.timeuntillatecolor =
                                          e.target.value.replace(re, "");
                                        return {
                                          ...prevData,
                                        };
                                      });
                                    }}
                                  />
                                </Col>
                                <Col
                                  xxl={6}
                                  xl={3}
                                  lg={3}
                                  className="d-lg-block d-none"
                                >
                                  &nbsp;
                                </Col>
                              </Row>
                            </Form.Group>
                            <Form.Group className="form-group">
                              <Row>
                                <Col lg={3}>
                                  <Form.Label>Auto Approve</Form.Label>
                                </Col>
                                <Col
                                  xxl={3}
                                  xl={6}
                                  lg={6}
                                  className="d-flex extraPX"
                                >
                                  <Form.Check
                                    onClick={(e) => {
                                      setForm((prevData) => {
                                        prevData.settings.KitchenApp.autoapprove =
                                          e.target.checked == true ? 1 : 0; //eslint-disable-line
                                        return {
                                          ...prevData,
                                        };
                                      });
                                    }}
                                    checked={
                                      form.settings.KitchenApp
                                        .autoapprove == 1
                                        ? true
                                        : false
                                    } //eslint-disable-line
                                    onChange={() => { }}
                                    type="switch"
                                    id="custom-switch"
                                    className="enabled"
                                    label=""
                                  />
                                </Col>
                                <Col
                                  xxl={6}
                                  xl={3}
                                  lg={3}
                                  className="d-lg-block d-none"
                                >
                                  &nbsp;
                                </Col>
                              </Row>
                            </Form.Group>

                          </Form>
                        </Tab.Pane>
                        <Tab.Pane eventKey="websiteTemplate">
                          <Form>
                            <Form.Group className="form-group">
                              <Row>
                                <Col lg={3}>
                                  <Form.Label>Enable Website Template</Form.Label>
                                </Col>
                                <Col xxl={3} xl={6} lg={6} className="extraPX">
                                  <Form.Check
                                    onClick={(e) => {
                                      setForm((prevData) => {
                                        prevData.settings.WebsiteTemplate.enableWebsiteTemplate = e.target.checked == true ? 1 : 0
                                        return {
                                          ...prevData,
                                        }
                                      })
                                    }}
                                    checked={form.settings.WebsiteTemplate.enableWebsiteTemplate == 1 ? true : false}
                                    onChange={() => { }}
                                    type="switch"
                                    id="custom-switch"
                                    className="enabled"
                                    label=""
                                  />
                                </Col>
                                <Col
                                  xxl={6}
                                  xl={3}
                                  lg={3}
                                  className="d-lg-block d-none"
                                >
                                  &nbsp;
                                </Col>
                              </Row>
                            </Form.Group>
                            {/* --------------------------------------------------iframe link-------------------------------------------- */}
                            <Form.Group className="form-group">
                              <Row className="cardOuterRow">
                                <Col lg={3}>
                                  <Form.Label>Iframe url</Form.Label>
                                </Col>
                                <Col xxl={9} xl={9} lg={9} className="extraPX">
                                  <Card border="light" style={{}}>
                                    <Card.Body>
                                      <div style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                      }}>
                                      </div>
                                      <Card.Text>
                                        <div className="iframeSection">
                                          <div>{`<iframe src=${url}menu-order?token=${token}   title="online ordering"/>`} </div>
                                          <div className="iconSection">
                                            <FiCopy
                                              onClick={() => {
                                                // let copyText= {`<iframe src=${url}menu-order?token=${token}   title="online ordering"/>`} 
                                                copyTextToClipboard().then(() => {
                                                  toastMessages('success', 'text copied successfully')

                                                })
                                              }}
                                              style={{
                                                cursor: 'pointer',
                                                marginLeft: '10px'
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </Card.Text>
                                    </Card.Body>
                                  </Card>
                                </Col>
                                <Col
                                  xxl={6}
                                  xl={3}
                                  lg={3}
                                  className="d-lg-block"
                                >
                                  &nbsp;
                                </Col>
                              </Row>
                            </Form.Group>
                            {/* -------------------------------------------------------------------------------------------------------- */}
                            <Form.Group className="form-group">
                              <Row>
                                <Col lg={3}>
                                  <Form.Label>Subdomain</Form.Label>
                                </Col>
                                <Col xxl={6} xl={6} lg={6} className="extraPX">
                                  <div className="templateOuter">
                                    <div>
                                      <InputGroup className="">
                                        <div style={{
                                          display: 'flex'
                                        }}>
                                          <Form.Control
                                            style={{
                                              borderRightStyle: ''
                                            }}
                                            autocomplete="off"
                                            type="text"
                                            className="notCapital subdomainFeild"
                                            placeholder="subdomain"
                                            // className="subdomainFeild"
                                            aria-label="subdomain"
                                            id="copySubDomain"
                                            ref={inputRef}
                                            aria-describedby="basic-addon2"
                                            value={form.settings.WebsiteTemplate.domainName}
                                            onChange={(e) => {
                                              setForm((prevData) => {
                                                prevData.settings.WebsiteTemplate.domainName =
                                                  e.target.value
                                                return {
                                                  ...prevData,
                                                };
                                              });
                                            }}
                                          />
                                          <InputGroup.Text id="basic-addon2">.cloudpos.app</InputGroup.Text>
                                        </div>
                                      </InputGroup>
                                    </div>
                                    <div className="iconSection">
                                      <FiCopy
                                        style={{
                                          cursor: 'pointer',
                                          marginLeft: '10px'
                                        }}
                                        onClick={() => {
                                          if (inputRef.current) {
                                            const textToCopy = inputRef.current.value + '.cloudpos.app';
                                            inputRef.current.value = textToCopy;
                                            inputRef.current.select();
                                            document.execCommand('copy');
                                            toastMessages("success", "Copied Successfully")
                                            inputRef.current.value = inputRef.current.value.replace('.cloudpos.app', '');
                                          }
                                        }
                                        }
                                      />
                                    </div>

                                  </div>
                                </Col>
                                <Col
                                  xxl={6}
                                  xl={3}
                                  lg={3}
                                  className="d-lg-block"
                                >
                                  &nbsp;
                                </Col>
                              </Row>
                            </Form.Group>
                            <Form.Group className="form-group">
                              <Row>
                                <Col lg={3}>
                                  <Form.Label>Domain</Form.Label>
                                </Col>
                                <Col xxl={6} xl={8} lg={9} className="extraPX">
                                  {console.log("3403", form.settings.WebsiteTemplate.haveDomain)}
                                  <Form.Check
                                    onClick={(e) => {
                                      setForm((prevData) => {
                                        prevData.settings.WebsiteTemplate.haveDomain = e.target.checked == true ? 1 : 0
                                        if (e.target.checked == false) {
                                          prevData.settings.WebsiteTemplate.suffix = {}
                                          prevData.settings.WebsiteTemplate.clientDomain = ''
                                        }
                                        else {
                                          prevData.settings.WebsiteTemplate.clientDomain = prevData.settings.WebsiteTemplate.domainName
                                        }
                                        return {
                                          ...prevData,
                                        }
                                      })
                                    }}
                                    // disabled
                                    checked={form.settings.WebsiteTemplate.haveDomain == 1 ? true : false}
                                    onChange={() => { }}
                                    type="switch"
                                    id="custom-switch"
                                    className="enabled"
                                    label=""
                                  />
                                  {!!form.settings.WebsiteTemplate.haveDomain &&
                                    <div className="domainStyling">
                                      <div className="templateOuter">
                                        <div>
                                          <InputGroup className="">
                                            <div style={{
                                              display: 'flex'
                                            }}>
                                              <Form.Control
                                                style={{
                                                  borderRightStyle: ''
                                                }}
                                                // disabled
                                                type="text"
                                                className="notCapital subdomainFeild"
                                                placeholder="domain"
                                                aria-label="domain"
                                                autocomplete="off"
                                                id="copySubDomain"
                                                // ref={inputRef}
                                                aria-describedby="basic-addon2"
                                                value={form.settings.WebsiteTemplate.clientDomain}
                                                onChange={(e) => {
                                                  setForm((prevData) => {
                                                    prevData.settings.WebsiteTemplate.clientDomain =
                                                      e.target.value
                                                    return {
                                                      ...prevData,
                                                    };
                                                  });
                                                }}
                                              />
                                            </div>
                                            <div className="domainOuter ms-0">
                                              <Select
                                                isSearchable={true}
                                                className="select2 singleSelect"
                                                placeholder="Suffix"
                                                classNames="subdomainFeildSelect"
                                                // menuIsOpen={true}
                                                options={domainSuffixList}
                                                value={
                                                  form.settings.WebsiteTemplate.suffix
                                                }
                                                onChange={(e) => {
                                                  setForm((prevData) => {
                                                    prevData.settings.WebsiteTemplate.suffix = e;
                                                    return {
                                                      ...prevData,
                                                    };
                                                  });
                                                }}
                                                labelledBy="Select"
                                              />
                                            </div>
                                            {['top'].map((placement) => (
                                              <OverlayTrigger
                                                key={placement}
                                                placement={placement}
                                                overlay={
                                                  <Tooltip id={`tooltip-${placement}`}>
                                                    Subdomain and Domain should have same name.
                                                  </Tooltip>
                                                }>
                                                <span className="infoIcon ms-2 mt-2"><i className="fa-solid fa-circle-info"></i></span>
                                              </OverlayTrigger>
                                            ))}
                                          </InputGroup>
                                          {Object.keys(domainRecords).length ?
                                            <Button type="button"
                                              className="commonButton commonButton--small w-40 mt-4 deleteButtonSettings"
                                              onClick={onDeleteSubdomainHandler}
                                            >Delect Records</Button>
                                            :
                                            <Button type="button"
                                              className="commonButton commonButton--small w-40 mt-4"
                                              onClick={onCreateHandler}
                                            >Create Records</Button>}

                                        </div>
                                      </div>
                                    </div>
                                  }
                                </Col>
                                <Col
                                  xxl={6}
                                  xl={3}
                                  lg={3}
                                  className="d-lg-block"
                                >
                                </Col>
                              </Row>
                            </Form.Group>
                            {/* start */}
                            {(isDomainRegisrationStarted &&
                              isDomainRegisrationStarted.status) &&
                              <Form.Group className="form-group">


                                {/* sadasdfs */}
                                <Row className="cardOuterRow">
                                  <Col lg={3}>
                                    <Form.Label></Form.Label>
                                  </Col>
                                  <Col xxl={9} xl={6} lg={6} className="extraPX">
                                    {isDomainRegisrationStarted && isDomainRegisrationStarted.messsage !== "AVAILABLE" &&
                                      <Card border="light" style={{ marginBottom: '5px' }}>
                                        <Card.Body>Domain Configuration is under Process It may take 5-10 minutes.please come after some time</Card.Body>
                                      </Card>
                                    }
                                    {isDomainRegisrationStarted &&
                                      <Card border="light" style={{ marginBottom: '5px' }}>
                                        {/* Display dns records */}
                                        <Card.Body>
                                          <Card.Title>Certificate Verification</Card.Title>
                                          <Card.Text>{domainRecords.certificateVerificationDNSRecord}</Card.Text>
                                          <Card.Title>Sub Domains DNS Records </Card.Title>
                                          {domainRecords.subDomains.map((item) => {
                                            return (
                                              <Card.Text>{item.dnsRecord}</Card.Text>
                                            )
                                          })
                                          }
                                          <Card.Text>{ }</Card.Text>
                                        </Card.Body>
                                      </Card>
                                    }
                                    {/* {isDomainRegisrationStarted && isDomainRegisrationStarted.messsage == "AVAILABLE" &&
                                      <Card border="light" style={{ marginBottom: '5px' }}>
                                         <Card.Body>
                                      <div style={{ display: 'flex', marginBottom: "10px" }}>
                                        <p>Delete the Domain Assocaiation </p>
                                        <i
                                          className="fa-solid fa-trash-can"
                                          style={{ color: 'red', marginLeft: '16px', marginTop: '6px', cursor: 'pointer' }}
                                          onClick={onDeleteSubdomainHandler}>
                                        </i>
                                      </div>
                                    } */}
                                    <Card border="light" style={{}}>
                                      <Card.Body>
                                        <div style={{
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                          // cursor: 'pointer'
                                        }}>
                                          <Card.Title>Current Status</Card.Title>
                                          <GrRefresh
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                              getAllRegistratedDomain()
                                            }} />
                                        </div>
                                        <Card.Text>
                                          {titleCase(isDomainRegisrationStarted.messsage)}
                                          {isDomainRegisrationStarted.messsage == "AVAILABLE" ?
                                            <AiFillCheckCircle
                                              style={{
                                                color: 'green',
                                                marginLeft: '10px'
                                              }}
                                            /> :
                                            isDomainRegisrationStarted.messsage == "PENDING_VERIFICATION" ? <MdPending style={{
                                              color: 'red',
                                              marginLeft: "10px"
                                            }}
                                            /> : isDomainRegisrationStarted.messsage == "FAILED" ? <MdSmsFailed style={{
                                              color: "red",
                                              marginLeft: '10px'
                                            }}
                                            /> : ''
                                          }
                                          {
                                            isDomainRegisrationStarted.messsage == "FAILED" ?
                                              <p>{domainRecords?.statusReason}</p> : ''
                                          }
                                        </Card.Text>
                                      </Card.Body>
                                    </Card>
                                  </Col>
                                  <Col
                                    xxl={3}
                                    xl={3}
                                    lg={3}
                                    className="d-lg-block"
                                  >
                                    &nbsp;
                                  </Col>
                                </Row>
                              </Form.Group>
                            }
                            <Row>
                              <Col lg={3}>
                                <Form.Label></Form.Label>
                              </Col>
                              <Col xxl={9} xl={6} lg={6} className="extraPX">
                                <div style={{
                                  display: 'flex',
                                  justifyContent: 'space-between'
                                }}>
                                  <div>
                                    {(form.settings.WebsiteTemplate.enableWebsiteTemplate == 1) ?
                                      <Button type="button"
                                        disabled={!isWebsiteTemplateEnabled}
                                        className="commonButton commonButton--small w-40 mt-4"
                                        onClick={() => {
                                          setPreviewTemplate(true)
                                        }}
                                      >Preview</Button> : ''
                                    }
                                  </div>
                                </div>
                              </Col>
                              <Col
                                xxl={3}
                                xl={3}
                                lg={3}
                                className="d-lg-block d-none"
                              >
                                &nbsp;
                              </Col>
                            </Row>
                          </Form>
                        </Tab.Pane>
                        <Tab.Pane eventKey="settlement">
                          {!Object.keys(settlementList).length ?
                            <Form>
                              <Form.Group className="form-group">
                                <Row>
                                  <Col lg={3}>
                                    <Form.Label></Form.Label>
                                  </Col>
                                  <Col xxl={6} xl={6} lg={6} className="extraPX">
                                    <p>Please configure your stripe key's to get the balance</p>
                                  </Col>
                                  <Col
                                    xxl={3}
                                    xl={3}
                                    lg={3}
                                    className="d-lg-block d-none"
                                  >
                                    &nbsp;
                                  </Col>
                                </Row>
                              </Form.Group>
                            </Form> :
                            <Form>
                              <Form.Group className="form-group">
                                <Row>
                                  <Col lg={3}>
                                    <Form.Label>Total Amount</Form.Label>
                                  </Col>
                                  <Col xxl={3} xl={6} lg={6} className="extraPX">
                                    <p>${(parseFloat(settlementList?.card?.availablePayout)).toFixed(2)}</p>
                                    {/* <p>${(parseFloat(settlementList?.balance?.available[0]?.source_types?.card / 100)).toFixed(2)}</p> */}
                                  </Col>
                                  <Col
                                    xxl={6}
                                    xl={3}
                                    lg={3}
                                    className="d-lg-block d-none"
                                  >
                                    &nbsp;
                                  </Col>
                                </Row>
                              </Form.Group>
                              <Form.Group className="form-group">
                                <Row>
                                  <Col lg={3}>
                                    <Form.Label>No. of Transactions</Form.Label>
                                  </Col>
                                  <Col xxl={3} xl={6} lg={6} className="extraPX">
                                    <p
                                    >{settlementList?.card?.transactionCount}</p>
                                  </Col>
                                  <Col
                                    xxl={6}
                                    xl={3}
                                    lg={3}
                                    className="d-lg-block d-none"
                                  >
                                    &nbsp;
                                  </Col>
                                </Row>
                              </Form.Group>
                              {(form.settings &&
                                form.settings.Settlement &&
                                form.settings.Settlement.lastsettletime
                                &&
                                form.settings.Settlement.lastsettletime.toString().trim() !== ""
                              ) &&
                                <Form.Group className="form-group">
                                  <Row>
                                    <Col lg={3}>
                                      <Form.Label>Last Settlement Time</Form.Label>
                                    </Col>
                                    <Col xxl={3} xl={6} lg={6} className="extraPX">
                                      <p>{formatDate_mm_dd_yyyy(form.settings.Settlement.lastsettletime, true)}</p>
                                    </Col>
                                    <Col
                                      xxl={6}
                                      xl={3}
                                      lg={3}
                                      className="d-lg-block d-none"
                                    >
                                      &nbsp;
                                    </Col>
                                  </Row>
                                </Form.Group>}
                              <Form.Group className="form-group">
                                <Row>
                                  <Col lg={3}>
                                    <Form.Label></Form.Label>
                                  </Col>
                                  <Col xxl={3} xl={6} lg={6} className="extraPX">
                                    <div style={{
                                      display: "",
                                      justifyContent: 'space-between'
                                    }}>
                                      <div>
                                        <CommonButton
                                          disabled={((settlementList?.balance?.available[0]?.source_types?.card / 100) == settlementList?.card?.availablePayout) ? false : true}
                                          onClick={(e) => onSettlementHandler(e)}
                                          buttonName="Settle"
                                          type="HalfWidth"
                                          loader={settlementLoader}
                                        />
                                      </div>
                                      <div>
                                        {
                                          ((settlementList?.balance?.available[0]?.source_types?.card / 100) !== settlementList?.card?.availablePayout) &&
                                          <span>The amount cannot be settled</span>
                                        }
                                      </div>
                                    </div>
                                  </Col>

                                  <Col
                                    xxl={6}
                                    xl={3}
                                    lg={3}
                                    className="d-lg-block d-none"
                                  >
                                    &nbsp;
                                  </Col>
                                </Row>
                              </Form.Group>
                            </Form>
                          }

                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                )}
              </div>
              <AdvanceSettingsDetails />
            </div>
          </div>
        </div>
        {!prevuewTemplate &&
          <SidebarTwo
            activePage=""
            setSidebarType={setSidebarType}
            sidebarType={sidebarType}
            setPermissionData={setPermissionData}
          />
        }
      </div>
    </React.Fragment >
  );
}
const CustomInputBoxes = ({
  value,
  index,
  onChange,
  onClick,
  placeholder,
  count,
  disabled,
  onInput,
  grauityAmountCount,
}) => {
  return (
    <Col lg={4} md={6} className="d-flex align-items-center">
      {/* <span className="numberText">{index + 1}.</span>{" "} */}
      <Form.Control
        value={value}
        disabled={disabled}
        onInput={onInput}
        onChange={onChange}
        type="number"
        placeholder={placeholder}
      />
      {grauityAmountCount == 1 && count == 0 ? ( //eslint-disable-line
        ""
      ) : (
        <div>
          <button
            type="button"
            className="btn btn-generate ms-3 shadow-sm delIcon"
            onClick={onClick}
          >
            <i className="fa-solid fa-trash"></i>
          </button>
        </div>
      )}
    </Col>
  );
};
export default Settings;