import React from 'react';
import { Col } from 'react-bootstrap';
import loginImage from '../../shared/assets/images/login-img.jpg';

function SideBanner() {
    return (
        <Col xl={7} lg={6} className="formGrid px-0">
            <div className="formImage">
                <img src={loginImage} alt="loginImage" />
                <div className="formContent">
                    <h2>Welcome To Group Redbox POS</h2>
                </div>
            </div>
        </Col>
    )
}
export default SideBanner;