import React, { useEffect, useState } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { ForgotPassword, ChangePassword, Login } from "./components/auth";

//CSS
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./components/common/container/common.scss";
// COMPONENT
import Container from "./components/common/container";
//react-toast
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { setupLogging } from "./features/helper";
import Template from "./components/template";
import LoadingPage from "./components/loadingPage";
import { apiGetMethod, header } from "./api/rest";
import { allApiUrl } from "./api/apiRoute";
import { useDispatch, useSelector } from "react-redux";
import { saveDomainData } from "./features/todoSlice";
import { HandlerSidebarToggle } from "./components/shared/sidebarToggle";
function App() {
  let dispatch = useDispatch();
  const [dataLoadedSuccessfully, setDataLoadedSuccessfully] = useState(null);
  // const [subdomainRelatedData, setSubDomainRelatedData] = useState([]);
  let sub_domain = null;
  const host = window.location.host;
  const domainParts = host.split(".");
  console.log("domainParts", domainParts);
  if (domainParts.length > 1) {
    if (domainParts[0] === "www") {
      console.log("inside if", domainParts[0]);
      sub_domain = domainParts[1];
    } else {
      console.log("inside else", domainParts[1]);
      sub_domain = domainParts[0];
    }

    // Handle specific subdomain cases
    console.log("sud_domain--40");
    if (
      sub_domain === "cloudpos-modifier" ||
      sub_domain === "cloudpos" ||
      sub_domain === "portal" ||
      sub_domain === 192 ||
      sub_domain === "192" ||
      sub_domain == "db2vpgk4asi37"
    ) {
      console.log("sud_domain--48-inside if");
      sub_domain = null;
    }
  }
  // const checkForDomain = window.location.host.split(".");
  // console.log("host", host);
  //for subdomain
  // const domain = host
  //   .split(".")
  //   .slice(
  //     0,
  //     host.includes("localhost", "softuvo", "americloud", "") ? -1 : -2
  //   );
  // console.log("38", domain);
  // if (domain.length > 0) {
  //   if (domain[0] == "www") {
  //     //check if subdomain is cloudpos then subdomain wil be null
  //     if (domain[1] == "cloudpos" || domain[1] == "cloudpos-modifier") {
  //       console.log("---40--");
  //       sub_domain = null;
  //     }
  //     //check if subdomain is portal then subdomain wil be null
  //     if (domain[1] == "portal-v1") {
  //       console.log("---45--");
  //       sub_domain = null;
  //     } else {
  //       console.log("---46--");
  //       sub_domain = domain[1];
  //     }
  //   } else {
  //     //check if subdomain is cloudpos then subdomain wil be null
  //     if (domain[0] == "cloudpos" || domain[0] == "cloudpos-modifier") {
  //       console.log("---54--");
  //       sub_domain = null;
  //     } else if (domain[0] == 192) {
  //       console.log("---57--");
  //       sub_domain = null;
  //       //check if subdomain is portal then subdomain wil be null
  //     } else if (domain[0] == "portal-v1") {
  //       console.log("---61--");
  //       sub_domain = null;
  //     } else {
  //       console.log("---64--");
  //       sub_domain = domain[0];
  //     }
  //   }
  // }
  async function getSubDomainInfo() {
    let header = {};
    try {
      const response = await apiGetMethod(
        `${allApiUrl.getSubdomainDetail}/${sub_domain}`,
        "",
        header
      );
      if (response.status == 200) {
        setDataLoadedSuccessfully(true);
        console.log(
          "🚀 ~ file: App.js:67 ~ getSubDomainInfo ~ setDataLoadedSuccessfully:"
        );
        await dispatch(
          saveDomainData({ isSubdomainAvaialble: true, data: response.data })
        );
      }
    } catch (err) {
      // console.log("----61-----", err.data.message);
      setDataLoadedSuccessfully(false);
      // console.log(
      //   "🚀 ~ file: App.js:75 ~ getSubDomainInfo ~ setDataLoadedSuccessfully:"
      // );
      if (err.data.message == "No Subdomain Found") {
        console.log("----64-----");
        await dispatch(
          saveDomainData({ isSubdomainAvaialble: false, data: { sub_domain } })
        );
      }
    }
  }
  // console.log(
  //   "🚀 ~ file: App.js:74 ~ getSubDomainInfo ~ setDataLoadedSuccessfully:",
  //   dataLoadedSuccessfully
  // );
  // setTimeout(() => {
  //   setFullPageLoader(false);
  // }, 2000);templateOuter
  useEffect(() => {
    setupLogging();
    if (sub_domain) getSubDomainInfo(sub_domain);
  }, []);
  if (process.env.NODE_ENV === "production") {
    console.log = function () {};
  }
  console.log("sub_domain[0]", sub_domain);
  // if (fullPageLoader) {
  //   return <LoadingPage />;
  // }
  HandlerSidebarToggle();
  console.log("testing 117");
  return (
    <div className="App">
      <ToastContainer
        position="top-center"
        hideProgressBar={true}
        newestOnTop={false}
        autoClose={2000}
        bodyClassName="toastBody"
      />
      <BrowserRouter>
        {sub_domain === null ? ( // No sub_domain available, render directly
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/changepassword" component={ChangePassword} />
            <Route path="/forgotpassword" component={ForgotPassword} />
            <Route path="/" component={Container} />
          </Switch>
        ) : dataLoadedSuccessfully === null ? (
          <LoadingPage />
        ) : (
          <Switch>
            <Route
              path="/"
              exact
              render={(props) => (
                <Template
                  {...props}
                  dataLoadedSuccessfully={dataLoadedSuccessfully}
                />
              )}
            />
            <Route
              path="/login"
              render={(props) => (
                <Template
                  {...props}
                  dataLoadedSuccessfully={dataLoadedSuccessfully}
                />
              )}
            />
            <Route
              path="*"
              render={(props) => (
                <Template
                  {...props}
                  dataLoadedSuccessfully={dataLoadedSuccessfully}
                />
              )}
            />
          </Switch>
        )}
      </BrowserRouter>
    </div>
  );
}
export default App;
