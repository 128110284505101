import React, { useState } from "react";
import { Button, Table, Nav, Tab, Container, Row, Col, OverlayTrigger, Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Tooltip from 'react-bootstrap/Tooltip';
//Header
import Header from '../common/header';
import Sidebar from '../common/sidebar';
import { apiGetMethod, apiPostMethod } from "../../api/rest";

import { allApiUrl } from "../../api/apiRoute";
import toastMessages from "../toastMessages";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import CommonButton from "../common-ui/Button";
import AddOnTable from "../Tables/AddOnTable";
import SidebarTwo from "../common/sidebarTwo";
import Loader from "../shared/spinner";
import { countries } from "../../features/constant";
import Select from 'react-select';
import { RiDeleteBin5Fill } from 'react-icons/ri';
import { titleCase } from "../../features/helper";

const AddChoiceType = (props) => {
    let {
        form,
        setForm,
        addChoiceCount,
        setAddChoiceCount,
        smallStyle,
        isShowError,
        modifierList,
        id
    } = props
    function increment(event) {
        event.preventDefault();
        setForm((prev) => {
            form.choices.forEach(item => {
                if (item.price === "") {
                    item.price = "0";
                }
            });
            return { ...prev }
        })
        setAddChoiceCount(addChoiceCount + 1);
        setForm({
            ...form,
            choices: [...form.choices, {
                name: '',
                preselect: 1,
                modifierSet: []
            }],
        });
    }
    const decrement = (event, index) => {
        event.preventDefault();
        let backupData = [...form.choices];
        backupData.splice(index, 1);
        const updatedForm = { ...form, choices: backupData };
        setForm(updatedForm);
        setAddChoiceCount((prev) => prev - 1);
    };
    let modifierListing = modifierList.map((item) => { return { label: item.modifiername, value: item.SK } })
    modifierListing = modifierListing.filter((item) => item.value !== parseInt(id))
    console.log('modifierListing', modifierListing)
    return (
        <Form.Group className="form-group">
            <Form.Label>Add Choices<sup>*</sup></Form.Label>
            {!form.choices.length && isShowError && <small style={smallStyle}>Add Choices is required</small>}
            <div className="addChoicesTable commonTableResponsive text-start">
                <Table className="basicTable">
                    <thead >
                        <tr>
                            <th>Name of Choice</th>
                            <th>Pre Select</th>
                            <th>Modifier Set(s)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {[...Array(addChoiceCount)].map((value, index) => {
                            return (
                                <>
                                    <tr key={index}>
                                        <td data-label="Name of Choice"><Form.Control type="text" placeholder=""
                                            value={form.choices[index]?.name}
                                            className={!form.choices[index]?.name && isShowError && "error"}
                                            onChange={(e) => {
                                                setForm((prevData) => {
                                                    let choices = [...prevData.choices]
                                                    choices[index].name = e.target.value
                                                    choices[index].id = new Date().getTime()
                                                    return {
                                                        ...prevData, choices
                                                    }
                                                })
                                            }}
                                        /></td>
                                        <td data-label="Pre Select">
                                            <Form.Group className="form-group formCheckbox">
                                                <Form.Check
                                                    id="custom-switch_1"
                                                    checked={form?.choices[index]?.preselect == 1 ? true : false}
                                                    type='checkbox'
                                                    name="preselect"
                                                    onClick={(e) => {
                                                        console.log('e->', e.target.checked)
                                                        setForm((prevData) => {
                                                            prevData.choices[index].preselect = e.target.checked == true ? 1 : 0
                                                            return { ...prevData }
                                                        })
                                                    }}
                                                />
                                            </Form.Group>
                                        </td>
                                        <td data-label="Modifier Set (s)">
                                            <Form.Group className="form-group selectDropdown">
                                                <Select
                                                    className="select2 multiSelect"
                                                    isMulti
                                                    // menuIsOpen
                                                    placeholder="Select"
                                                    options={modifierListing}
                                                    value={form?.choices[index]?.modifierSet}
                                                    labelledBy="Select"
                                                    onChange={(e) => {
                                                        console.log('e', e)
                                                        setForm((prevData) => {
                                                            prevData.choices[index].modifierSet = e
                                                            return { ...prevData }
                                                            // let modifierSetList= [...prevData.modifierSet]

                                                        })
                                                    }}
                                                />
                                            </Form.Group>
                                        </td>
                                        <td><Button
                                            className="deleteButton"
                                            type="button"
                                            onClick={(event) => decrement(event, index)}
                                        ><i className="fa-solid fa-trash-can"
                                        ></i></Button></td>
                                    </tr>
                                    {!form.choices[index]?.name && isShowError && <small style={smallStyle}>Name of Choice is required</small>}
                                </>
                            )
                        })
                        }
                        <div className="">
                            <Button type="button"
                                onClick={(event) => { increment(event) }}
                                className="commonButton commonButton--small addCommonBtn">
                                <span>Add</span> <i className="fa-solid fa-plus"></i>
                            </Button>
                        </div>
                    </tbody>

                </Table>
            </div >
        </Form.Group >
    )
}
const VariationType = (props) => {
    let {
        form,
        setForm,
        smallStyle,
        isShowError,
        addVariationCount,
        setAddVariationCount,
        modifierList,
        id
    } = props
    function incremenVariation(event) {
        event.preventDefault();
        setForm((prev) => {
            form.variation.forEach(item => {
                if (item.price === "") {
                    item.price = "0";
                }
            });
            return { ...prev }
        })
        setAddVariationCount((prev) => prev + 1);
        setForm({
            ...form,
            variation: [...form.variation, {
                name: '',
                price: "0",
                modifierSet: []
            }],
        });
    }
    const decrement = (event, index) => {
        event.preventDefault();
        let backupData = [...form.variation];
        backupData.splice(index, 1);
        const updatedForm = { ...form, variation: backupData };
        setForm(updatedForm);
        setAddVariationCount((prev) => prev - 1);

    };
    let modifierListing = modifierList.map((item) => { return { label: item.modifiername, value: item.SK } })
    modifierListing = modifierListing.filter((item) => item.value !== parseInt(id))
    console.log('==========185--------', form)
    return (
        <Form.Group className="form-group">
            <Form.Label>Add Variations<sup>*</sup></Form.Label>
            {!form.variation.length && isShowError && <small style={smallStyle}>Variations is required</small>}
            <div className="addChoicesTable commonTableResponsive text-start">
                <Table className="basicTable">
                    <thead >
                        <tr>
                            <th>Name</th>
                            <th>Amount</th>
                            <th className="sizePrice"> Modifier Set(s) </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {[...Array(addVariationCount)].map((value, index) => {
                            return (
                                <>
                                    <tr key={index}>
                                        <td data-label="Name"><Form.Control type="text" placeholder=""
                                            value={form.variation[index]?.name}
                                            className={!form.variation[index]?.name && isShowError && "error"}
                                            onChange={(e) => {
                                                setForm((prevData) => {
                                                    let variation = [...prevData.variation]
                                                    variation[index].name = e.target.value
                                                    variation[index].id = new Date().getTime()
                                                    return {
                                                        ...prevData, variation
                                                    }
                                                })
                                            }}
                                        /></td>
                                        <td data-label="Amount"><Form.Control type="text" placeholder=""
                                            value={form.variation[index]?.price}
                                            onChange={(e) => {
                                                setForm((prevData) => {
                                                    let variation = [...prevData.variation]
                                                    const re = /[^0-9 .,]/g;
                                                    variation[index].price = e.target.value.replace(re, "");
                                                    return {
                                                        ...prevData, variation
                                                    }
                                                })
                                            }}

                                        /></td>
                                        <td data-label="Modifier Set (s)">
                                            <Form.Group className="form-group selectDropdown">
                                                <Select
                                                    className="select2 multiSelect"
                                                    isMulti
                                                    placeholder="Select"
                                                    options={modifierListing}
                                                    value={form?.variation[index]?.modifierSet}
                                                    labelledBy="Select"
                                                    onChange={(e) => {
                                                        console.log('e', e)
                                                        setForm((prevData) => {
                                                            prevData.variation[index].modifierSet = e
                                                            return { ...prevData }
                                                            // let modifierSetList= [...prevData.modifierSet]

                                                        })
                                                    }}
                                                />
                                            </Form.Group>
                                        </td>
                                        <td>
                                            <Button
                                                onClick={(e) => {
                                                    console.log('----------271------')
                                                    decrement(e, index)
                                                }}
                                                className="deleteButton"
                                                type="button">
                                                <i
                                                    className="fa-solid fa-trash-can"
                                                >
                                                </i>
                                            </Button>
                                        </td>
                                    </tr >
                                    {!form.variation[index]?.name && isShowError && <small style={smallStyle}>Name of variation is required</small>
                                    }

                                </>
                            )
                        })
                        }
                        <div className="">
                            <Button type="button"
                                onClick={(event) => { incremenVariation(event) }}
                                className="commonButton commonButton--small addCommonBtn">
                                <span>Add</span> <i className="fa-solid fa-plus"></i>
                            </Button>
                        </div>
                    </tbody>

                </Table>
            </div>
        </Form.Group >
    )
}
const AddonChoiceType = (props) => {
    let {
        form,
        setForm,
        smallStyle,
        isShowError,
        setAddAddonCount,
        addAddonCount,
        id
    } = props

    function increment(event) {
        event.preventDefault();
        setForm((prev) => {
            form.addon.forEach(item => {
                if (item.price === "") {
                    item.price = "0";
                }
            });
            return { ...prev }
        })
        setAddAddonCount((prev) => prev + 1);
        setForm({
            ...form,
            addon: [...form.addon, {
                name: '',
                price: '0',
                preselect: 1,
                allowmultiple: 1
            }],
        });
    }
    const decrement = (event, index) => {
        event.preventDefault();
        let backupData = [...form.addon];
        backupData.splice(index, 1);
        const updatedForm = { ...form, addon: backupData };
        setForm(updatedForm);
        setAddAddonCount((prev) => prev - 1);
    };
    console.log('====form====353=====', form)
    return (
        <Form.Group className="form-group">
            <Form.Label>Add-ons<sup>*</sup></Form.Label>
            {!form.addon.length && isShowError && <small style={smallStyle}>Add on is required</small>}
            <div className="addChoicesTable commonTableResponsive text-start">
                <Table className="basicTable">
                    <thead >
                        <tr>
                            <th>Name</th>
                            <th>Amount</th>
                            <th>Pre-Selected</th>
                            <th>Allow-Multiple</th>
                            {addAddonCount > 1 ? <th>Action</th> : ''}
                        </tr>
                    </thead>
                    <tbody>
                        {[...Array(addAddonCount)].map((value, index) => {
                            return (
                                <>
                                    <tr key={index}>
                                        <td data-label="Name"><Form.Control type="text" placeholder=""
                                            value={form.addon[index]?.name}
                                            className={!form.addon[index]?.name && isShowError && "error"}
                                            onChange={(e) => {
                                                setForm((prevData) => {
                                                    let addon = [...prevData.addon]
                                                    addon[index].name = e.target.value
                                                    addon[index].id = new Date().getTime()
                                                    return {
                                                        ...prevData, addon
                                                    }
                                                })
                                            }}
                                        /></td>
                                        <td data-label="Amount"><Form.Control type="text" placeholder=""
                                            value={form.addon[index]?.price}
                                            onChange={(e) => {
                                                setForm((prevData) => {
                                                    let addon = [...prevData.addon]
                                                    const re = /[^0-9 .,]/g;
                                                    addon[index].price = e.target.value.replace(re, "");
                                                    return {
                                                        ...prevData, addon
                                                    }
                                                })
                                            }}

                                        /></td>
                                        <td data-label="Pre Selected">
                                            <Form.Group className="form-group formCheckbox">
                                                <Form.Check
                                                    checked={form?.addon[index]?.preselect == 1 ? true : false}
                                                    id="custom-switch_2"
                                                    type='checkbox'
                                                    name="preselected"
                                                    onClick={(e) => {
                                                        console.log('e->', e.target.checked)
                                                        setForm((prevData) => {
                                                            prevData.addon[index].preselect = e.target.checked == true ? 1 : 0
                                                            return { ...prevData }
                                                        })
                                                    }}
                                                />
                                            </Form.Group>
                                        </td>
                                        <td data-label="Allow Multiple">
                                            <Form.Group className="form-group formCheckbox">
                                                <Form.Check
                                                    checked={form?.addon[index]?.allowmultiple == 1 ? true : false}
                                                    type='checkbox'
                                                    id="custom-switch_2"
                                                    name="allowmultiple"
                                                    onClick={(e) => {
                                                        console.log('e->', e.target.checked)
                                                        setForm((prevData) => {
                                                            prevData.addon[index].allowmultiple = e.target.checked == true ? 1 : 0
                                                            return { ...prevData }
                                                        })
                                                    }}
                                                />
                                            </Form.Group>
                                        </td>
                                        <td>
                                            <Button
                                                onClick={(event) => decrement(event, index)}
                                                className="deleteButton"
                                                type="button">
                                                <i className="fa-solid fa-trash-can" ></i>
                                            </Button>
                                        </td>
                                    </tr>
                                    {!form.addon[index]?.name && isShowError && <small style={smallStyle}>Name is required</small>}
                                </>
                            )
                        })}
                        <div>
                            <Button type="button"
                                onClick={(event) => { increment(event) }}
                                className="commonButton commonButton--small addCommonBtn">
                                <span>Add</span> <i className="fa-solid fa-plus"></i>
                            </Button>
                        </div>
                    </tbody>

                </Table>
            </div>
        </Form.Group>
    )
}
function AddNewChoicesAddons() {
    const history = useHistory()
    let sidebarToggle = useSelector((state) => state.counter.sidebarToggle)
    const branchArray = useSelector((state) => state?.counter?.branchArray)
    const [permissionData, setPermissionData] = useState({})
    let branches = branchArray.map((item) => item.value)
    const header = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        branchid: JSON.stringify(branches)
    };
    const currBranch = useSelector((state) => state.counter.currBranch)
    const [loaderForFetchMore, setLoaderForFetchMore] = useState(true)
    const [lastEvaluatedKey, setLastEvaluatedKey] = useState('')
    const [addChoiceCount, setAddChoiceCount] = useState(0)
    const [addVariationCount, setAddVariationCount] = useState(0)
    const [addAddonCount, setAddAddonCount] = useState(0)
    const [modifierList, setModifierList] = useState([])
    const [loader, setLoader] = useState({ save: false })
    const [isShowError, setisShowError] = useState(false)
    const [pageRefresh, setPageRefresh] = useState(false)
    const [form, setForm] = useState({
        modifiername: '',
        setName: '',
        modifiertype: 'variation',
        modifierstatus: {
            modifiretype: "optional",
            forceminimum: "0",
            forcemaximum: "0"
        },
        required: 0,
        maximum: 0,
        variation: [],
        addon: [],
        choices: [],
        productset: [],
        modifierId: new Date().getTime()
    })
    const [productList, setProductList] = useState([])
    const [initialProductList, setInitialProductList] = useState([])
    const [displayProduct, setDisplayProduct] = useState([])
    useEffect(() => {
        getAllModifier()
        getAllProducts()
        if ((branchArray.length > 1) || (branchArray && branchArray[0].value == "All")) {
            history.push('/dashboard')
        }
    }, [pageRefresh, currBranch.value, branchArray])
    async function getAllProducts() {
        try {
            const response = await apiGetMethod(`${allApiUrl.view_all_product}`, "", header);
            if (response.status === 200) {
                let updatedProductList = response.data.categories.Items.map((item) => {
                    return {
                        value: item.SK,
                        label: item.productname,
                        modifierset: item.modifierset
                    }
                })
                setInitialProductList(updatedProductList)
                setProductList(updatedProductList)
            }
        } catch (err) {
            console.log("err", err);
        }
    }
    async function getAllModifier() {
        try {
            const response = await apiGetMethod(`${allApiUrl.view_all_modifier}`, "", header);
            if (response.status === 200) {
                if (response.data.categories.LastEvaluatedKey) {
                    setLastEvaluatedKey(response.data.categories.LastEvaluatedKey.SK)
                }
                setModifierList(response.data.categories.Items)
                if (response.data.categories.Items.length < 5) {
                    setLoaderForFetchMore(false)
                }
                else {
                    setLoaderForFetchMore(true)
                }
                toastMessages("success", response.data.message);
            }
        } catch (err) {
            console.log("err", err);
        }
    }
    async function onSubmit(e) {
        e.preventDefault();
        setisShowError(true)
        setLoader({ ...loader, save: true });
        const allNameAndPriceFilled = form.choices.every(item => item.name);
        // let defaultSelect = form.choices.filter((item) => item.preselect == 1).length
        async function addProductFunction() {
            if (form.modifierstatus.forcemaximum == "") {
                form.modifierstatus.forcemaximum = "0"
            }
            if (form.modifierstatus.forceminimum == "") {
                form.modifierstatus.forceminimum = "0"
            }
            let type = form.modifiertype.toLowerCase()
            let count = 0
            form[type].forEach((item) => {
                if (item.preselect) {
                    count = count + 1
                }
            })
            if (count > parseFloat(form.maximum) && parseFloat(form.maximum) !== 0) {
                toastMessages('error', `preselect ${type} cannot be greater than maximum`)
                setLoader({ ...loader, save: false });

            }

            else {
                // console.log('form--->', form)
                // let updateForm = { ...form }
                try {
                    const response = await apiPostMethod(allApiUrl.addModifier, form, header);
                    if (response.status === 200) {
                        setLoader({ ...loader, save: false });
                        setPageRefresh(!pageRefresh)
                        history.push('/modifiers')
                        setForm({
                            modifiername: '',
                            setName: '',
                            modifiertype: 'choices',
                            modifierstatus: {
                                modifiretype: "optional",
                                forceminimum: "0",
                                forcemaximum: "0"
                            },
                            choices: [
                                {
                                    name: '',
                                    price: "0",
                                    preselect: 1
                                }
                            ]
                        })
                        setAddChoiceCount(1)
                        toastMessages("success", response.data.message);
                    }
                } catch (err) {
                    setLoader({ ...loader, save: false });
                    toastMessages("error", err.data.message);
                    console.log("err", err);
                }
            }
        }
        // if (form.modifiername && form.modifierstatus && allNameAndPriceFilled == true) {   //eslint-disable-line
        //check if variation ,addon ,choices are empty if empty then show error
        let isModifierEmpty = false
        if (form.modifiertype == "choices") {
            if (!form.choices.length) isModifierEmpty = true
        } else if (form.modifiertype == "addOn") {
            if (!form.addon.length) isModifierEmpty = true
        } else {
            if (!form.variation.length) isModifierEmpty = true
        }

        //check if yours modifier names are empty or not 
        let isNameAndPriceFilledforModifiers = false
        if (form.modifiertype == "choices") {
            let choiceCheck = form.choices.every((item) => item.name)
            if (choiceCheck) isNameAndPriceFilledforModifiers = true
        } else if (form.modifiertype == "addOn") {
            let addonCheck = form.addon.every((item) => item.name)
            let checkprice = form.addon.every((item) => item.price)
            if (addonCheck && checkprice) isNameAndPriceFilledforModifiers = true
        } else {
            let variationCheck = form.variation.every((item) => item.name)
            if (variationCheck) isNameAndPriceFilledforModifiers = true
        }
        // let isFilledEmpty = true
        // if (form.addon.length || form.variation.length || form.choices.length) {
        //     console.l
        //     let addonCheck = form.addon.every((item) => item.name)
        //     let checkprice = form.addon.every((item) => item.price)
        //     let choiceCheck = form.choices.every((item) => item.name)
        //     let variationCheck = form.variation.every((item) => item.name)
        //     console.log('----607---', addonCheck, checkprice, choiceCheck, variationCheck)
        //     if (addonCheck && checkprice && choiceCheck && variationCheck) isFilledEmpty = false
        // }
        // console.log('isFilledEmpty', isFilledEmpty)
        if (form.modifiername && form.setName && !isModifierEmpty && isNameAndPriceFilledforModifiers) {   //eslint-disable-line
            //check if type is variation or not .if variation then dont check the force min or force max
            if (form.modifiertype == "variation") {
                addProductFunction()
            }
            else {
                //To check if Force minimum cannot be greater than Force maximum
                // console.log("644", (parseInt(form.required) > parseInt(form.maximum)) &&
                //     (parseInt(form.maximum) != 0),)
                if ((parseInt(form.required) > parseInt(form.maximum)) &&
                    (parseInt(form.maximum) != 0)) { //eslint-disable-line
                    toastMessages("error", 'required cannot be greater than maximum')
                    setLoader({ ...loader, save: false });
                }
                //case to handle the both the zero in maximum and minimum
                else if (parseInt(form.maximum) == 0 && parseInt(form.required) == 0) {
                    addProductFunction()
                }
                //To check the number of pre-selected choices should be less than the force maximum
                // else if ((form.modifierstatus.modifiretype == "mandatory")
                //     && (defaultSelect > parseInt(form.modifierstatus.forcemaximum))
                //     && parseInt(form.modifierstatus.forceminimum) == 0) {
                //     toastMessages("error", 'The number of Pre Selected choices should be less than the Force Maximum.')
                //     setLoader({ ...loader, save: false });
                // }
                else {
                    //force minimum check
                    if (form.modifiertype == "addOn") {
                        if (parseInt(form.required) > form.addon.length) {
                            toastMessages("error", `Select at least ${form.required} addOn to continue.`)
                            setLoader({ ...loader, save: false });
                        } else {
                            addProductFunction()
                        }
                    }
                    else {
                        if (parseInt(form.required) > form.choices.length) {
                            toastMessages("error", `Select at least ${form.required} choices to continue.`)
                            setLoader({ ...loader, save: false });
                        } else {
                            addProductFunction()
                        }
                    }
                }
            }
        }
        else {
            toastMessages("error", "Please fill the required fields")
            setLoader({ ...loader, save: false });
        }

    }
    let smallStyle = {
        display: "block",
        fontSize: "13px",
        color: "#E02424"
    }
    // function onProductSelect(e, dataSet) {
    //     e.preventDefault()
    //     // console.log('-----655----', dataSet)
    //     setForm((prevData) => {
    //         let { value, label, modifierset } = dataSet
    //         let isAlreadyExist = modifierset.find((item) => item.value == form.modifierId)
    //         console.log('isAlreadyExist', isAlreadyExist)
    //         if (!isAlreadyExist) {
    //             modifierset.push({
    //                 value: form.modifierId,
    //                 label: form.modifiername
    //             })
    //         }
    //         let newEntry = {
    //             productName: label,
    //             productId: value,
    //             modifierset
    //         }
    //         // console.log('newEntry', newEntry)
    //         prevData.productset = [...prevData.productset, newEntry]
    //         return { ...prevData }
    //     })
    // }
    function onProductSelect(e, dataSet) {
        e.preventDefault()
        let { value, label, modifierset } = dataSet
        console.log('-----655----', dataSet)
        if (displayProduct) {
            let newDisplayProduct = {
                productName: label,
                productId: value,
                modifierset
            }
            setDisplayProduct((prev) => [...prev, newDisplayProduct])
        }
        setForm((prevData) => {
            let isAlreadyExist = modifierset.find((item) => item.value == form.modifierId)
            //check is modifier is already exist 
            if (!isAlreadyExist) {
                modifierset.push({
                    value: form.modifierId,
                    label: form.modifiername
                })
            }
            let newEntry = {
                productName: label,
                productId: value,
                modifierset
            }
            //checking id product is already existin in product set array if exist them add the modifier there only dont create new listing
            let isProductExist = prevData.productset.find((item) => item.productId == value)
            if (isProductExist) {
                isProductExist.modifierset = modifierset
            } else {
                prevData.productset = [...prevData.productset, newEntry]
            }
            return { ...prevData }
        })
    }
    // function onDeleteSelectedProductHandler(e, dataSet) {
    //     console.log('dataSet', dataSet)
    //     e.preventDefault()
    //     setForm((prevData) => {
    //         if (form.productset.length) {
    //             let updatedProductset = prevData.productset.filter((item) => item.productId !== dataSet.productId)

    //             console.log('------678----', updatedProductset)
    //             prevData.productset = updatedProductset
    //         }
    //         return { ...prevData }
    //     })
    // }
    function onDeleteSelectedProductHandler(e, dataSet) {
        e.preventDefault()
        //for handling display product deletion 
        if (form.productset.length) {
            let updatedProductset = displayProduct.filter((item) => item.productId !== dataSet.productId)
            setDisplayProduct(updatedProductset)
        }
        //for handling the modifier 
        setForm((prevData) => {
            prevData.productset.map((prodItem) => {
                prodItem.modifierset = prodItem.modifierset.filter((item) => {
                    if (prodItem.productId == dataSet.productId) {
                        return item.value != form.modifierId
                    } else {
                        return item
                    }
                })
            })
            return { ...prevData }
        })
    }
    function onSearchHandler(e) {
        if (e.target.value !== "") {
            setProductList(productList.filter((item) => {
                if (item.label.toLowerCase().includes(e.target.value.toLowerCase())) {
                    return item
                }
            }))
        } else {
            setProductList(initialProductList)
        }
    }
    let props = {
        form,
        setForm,
        addChoiceCount,
        setAddChoiceCount,
        isShowError,
        setisShowError,
        addVariationCount,
        setAddVariationCount,
        smallStyle,
        setAddAddonCount,
        addAddonCount,
        productList,
        modifierList,
        onProductSelect,
        onDeleteSelectedProductHandler,
        onSearchHandler,
        displayProduct,
    }
    console.log('form------166-----', form)
    const [sidebarType, setSidebarType] = useState('singleSidebar')
    let sidebarTypeRedux = useSelector((state) => state.counter.sidebarType)
    console.log('productList', productList)
    return (
        <React.Fragment>
            <Header active={sidebarToggle} />
            <div className="interFaceWrap">
                <Sidebar active={sidebarToggle} activePage="modifiers" permissionData={permissionData} />
                <div className={`interFaceWrap__inner ${sidebarTypeRedux == false ? "doubleSidebar" : "singleSidebar"}`}>
                    <div className="commonOuter">
                        <div className="pageHeader">
                            <Container fluid className="px-0">
                                <Row className="align-items-center">
                                    <div className="col">
                                        <div className="headingText headingText--textLeft mb-0">
                                            <h1>Add Modifiers</h1>
                                        </div>
                                    </div>
                                    <div className="col-auto ms-auto onlyButtons">
                                        <div className="pageHeader__elements">
                                            <CommonButton
                                                buttonName="Save"
                                                type="half"
                                                onClick={onSubmit}
                                                loader={loader.save}
                                            />
                                        </div>
                                    </div>
                                </Row>
                            </Container>
                        </div>
                        <AddChoiceItem {...props} />
                        {/* <AddOnTable
                            page="add"
                            modifierList={modifierList}
                            setModifierList={setModifierList}
                            setPageRefresh={setPageRefresh}
                            pageRefresh={pageRefresh}
                            lastEvaluatedKey={lastEvaluatedKey}
                            setLastEvaluatedKey={setLastEvaluatedKey}
                            setLoaderForFetchMore={setLoaderForFetchMore}
                            loaderForFetchMore={loaderForFetchMore}
                        /> */}
                    </div>
                </div>
                <SidebarTwo activePage="" setSidebarType={setSidebarType} sidebarType={sidebarType} setPermissionData={setPermissionData} />
            </div>
        </React.Fragment>
    )
}
const AddChoiceItem = ({
    isLoading,
    form,
    setForm,
    addChoiceCount,
    setAddChoiceCount,
    isShowError,
    setisShowError,
    addVariationCount,
    setAddVariationCount,
    smallStyle,
    setAddAddonCount,
    addAddonCount,
    productList,
    modifierList,
    onProductSelect,
    onDeleteSelectedProductHandler,
    displayProduct,
    onSearchHandler,
    id
}) => {
    let props = {
        form,
        setForm,
        addChoiceCount,
        setAddChoiceCount,
        isShowError,
        setisShowError,
        smallStyle,
        addVariationCount,
        setAddVariationCount,
        setAddAddonCount,
        addAddonCount,
        productList,
        modifierList,
        onProductSelect,
        onDeleteSelectedProductHandler,
        displayProduct,
        onSearchHandler,
        id
    }
    return (
        <div className="selectModifierOpetions">
            {isLoading ?
                <Loader /> :
                <Row>
                    <Col xl={8} lg={8}>
                        <div
                            className="whiteBg marginBottom"
                        // style={{ marginBottom: '12px' }}
                        >
                            <div className="">
                                <Form>
                                    <Row>
                                        <Col md={6}>
                                            <Form.Group className="form-group">
                                                <Form.Label>Modifier Name<sup>*</sup></Form.Label>
                                                <Form.Control type="text" placeholder="Salad"
                                                    className={`${!form.modifiername && isShowError && "error"}`}
                                                    value={form.modifiername}
                                                    onChange={(e) => {
                                                        setisShowError(false)
                                                        // if (e.target.value.match(alphabetRegex)) {
                                                        setForm({
                                                            ...form,
                                                            modifiername: (e.target.value),
                                                            setName:
                                                                (form.setName == form.modifiername) ?
                                                                    e.target.value : form.setName
                                                        });
                                                        // }
                                                    }}
                                                />
                                                {!form.modifiername && isShowError && <small>Modifier name is required !</small>}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </div>
                        <div className="whiteBg">
                            <Form>
                                <Row>
                                    <Col md={6}>
                                        <Form.Group className="form-group">
                                            <Form.Label>Set Name<sup>*</sup><small><i>This name will display on the POS</i></small></Form.Label>
                                            <Form.Control type="text" placeholder="Salad"
                                                className={`${!form.setName && isShowError && "error"}`}
                                                value={form.setName}
                                                onChange={(e) => {
                                                    setisShowError(false)
                                                    // if (e.target.value.match(alphabetRegex)) {
                                                    setForm({
                                                        ...form,
                                                        setName: (e.target.value),
                                                    });
                                                    // }
                                                }}
                                            />
                                            {!form.setName && isShowError && <small>Set Name is required !</small>}
                                        </Form.Group>

                                    </Col>
                                    <Col sm={12} className="form-group">
                                        {
                                            form.modifiertype !== "variation" ?
                                                <div className="formTabs commonMinHeight">
                                                    <Form.Group className="form-group">
                                                        <Form.Label>Required<sup>*</sup></Form.Label>
                                                        <Form.Control type="text" placeholder="0"
                                                            // className={`${form.required || isShowError && "error"}`}
                                                            value={form.required}
                                                            onChange={(e) => {
                                                                setisShowError(false)
                                                                setForm({
                                                                    ...form,
                                                                    required: (e.target.value),
                                                                });
                                                                // }
                                                            }}
                                                        />
                                                        {/* {(form.required || isShowError) && <small>This field is required !</small>} */}
                                                    </Form.Group>
                                                    <Form.Group className="form-group">
                                                        <Form.Label>Maximum <sup>*</sup></Form.Label>
                                                        <Form.Control type="text" placeholder='0'
                                                            // className={`${form.maximum || isShowError && "error"}`}
                                                            value={form.maximum}
                                                            onChange={(e) => {
                                                                setisShowError(false)
                                                                setForm({
                                                                    ...form,
                                                                    maximum: (e.target.value),
                                                                });
                                                                // }
                                                            }}
                                                        />
                                                        {/* {(form.maximum || isShowError) && <small>This field is required !</small>} */}
                                                    </Form.Group>
                                                    {/* <Tab.Container id="sizesTab" defaultActiveKey="optional">
                                                        <Nav className="commonTabs">
                                                            <Nav.Item>
                                                                <Nav.Link eventKey="optional"
                                                                    active={form.modifierstatus.modifiretype == "optional" ? true : false} //eslint-disable-line
                                                                    onClick={() => {
                                                                        if (form.modifierstatus.forcemaximum === "" || form.modifierstatus.forceminimum === "") {
                                                                            if (form.modifierstatus.forceminimum === "" && form.modifierstatus.forcemaximum === "") {
                                                                                setForm({
                                                                                    ...form, modifierstatus: {
                                                                                        ...form.modifierstatus,
                                                                                        modifiretype: 'optional',
                                                                                        forcemaximum: "0",
                                                                                        forceminimum: "0",
                                                                                    }
                                                                                })
                                                                            }
                                                                            else if (form.modifierstatus.forceminimum === "") {
                                                                                setForm({
                                                                                    ...form, modifierstatus: {
                                                                                        ...form.modifierstatus,
                                                                                        modifiretype: 'optional',
                                                                                        forceminimum: "0",
                                                                                    }
                                                                                })
                                                                            }
                                                                            else {
                                                                                setForm({
                                                                                    ...form, modifierstatus: {
                                                                                        ...form.modifierstatus,
                                                                                        modifiretype: 'optional',
                                                                                        forcemaximum: "0",
                                                                                    }
                                                                                })
                                                                            }
                                                                        }
                                                                        else {
                                                                            setForm({
                                                                                ...form, modifierstatus: {
                                                                                    ...form.modifierstatus,
                                                                                    modifiretype: 'optional'
                                                                                }
                                                                            })
                                                                        }
                                                                    }}
                                                                >Optional </Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item >
                                                                <Nav.Link
                                                                    active={form.modifierstatus.modifiretype == "mandatory" ? true : false} //eslint-disable-line
                                                                    eventKey="mandatory" onClick={() => setForm({
                                                                        ...form, modifierstatus: {
                                                                            ...form.modifierstatus, modifiretype: 'mandatory'
                                                                        }
                                                                    })

                                                                    }>Mandatory</Nav.Link>
                                                            </Nav.Item>
                                                        </Nav>
                                                        <Tab.Content className="" >
                                                            <Tab.Pane eventKey="optional" active={form.modifierstatus.modifiretype == "optional" ? true : false} //eslint-disable-line
                                                            ></Tab.Pane>
                                                            <Tab.Pane eventKey="mandatory" active={form.modifierstatus.modifiretype == "mandatory" ? true : false} //eslint-disable-line
                                                            >
                                                                <Form.Group className="form-group group-flex">
                                                                    <Form.Label>
                                                                        Force Minimum
                                                                        {['top'].map((placement) => (
                                                                            <OverlayTrigger
                                                                                key={placement}
                                                                                placement={placement}
                                                                                overlay={
                                                                                    <Tooltip id={`tooltip-${placement}`}>
                                                                                        This forces your customer to select a certain minimum number of choices. E.g. if you set this to 2 then your customer must select at least 2 choices.
                                                                                    </Tooltip>
                                                                                }>
                                                                                <span className="infoIcon"><i className="fa-solid fa-circle-info"></i></span>
                                                                            </OverlayTrigger>
                                                                        ))}
                                                                    </Form.Label>
                                                                    <Form.Control type="text" placeholder=""
                                                                        value={form.modifierstatus.forceminimum}
                                                                        onChange={(e) => {
                                                                            const value = e.target.value.replace(/\D/g, "");
                                                                            setForm({
                                                                                ...form, modifierstatus: {
                                                                                    ...form.modifierstatus, forceminimum: (value)
                                                                                }
                                                                            })
                                                                        }}
                                                                    />
                                                                </Form.Group>
                                                                <Form.Group className="form-group group-flex mt-3">
                                                                    <Form.Label>
                                                                        Force Maximum
                                                                        {['top'].map((placement) => (
                                                                            <OverlayTrigger
                                                                                key={placement}
                                                                                placement={placement}
                                                                                overlay={
                                                                                    <Tooltip id={`tooltip-${placement}`}>
                                                                                        The maximum number of choices your customer can add. If you prefer not to set any limitations you can set it to 0. E.g. if you set this to 4 then your customer cannot select more than 4 choices.
                                                                                    </Tooltip>
                                                                                }>
                                                                                <span className="infoIcon"><i className="fa-solid fa-circle-info"></i></span>
                                                                            </OverlayTrigger>
                                                                        ))}
                                                                    </Form.Label>
                                                                    <Form.Control type="text" placeholder=""
                                                                        value={form.modifierstatus.forcemaximum}
                                                                        onChange={(e) => {
                                                                            const value = e.target.value.replace(/\D/g, "");
                                                                            setForm({
                                                                                ...form, modifierstatus: {
                                                                                    ...form.modifierstatus, forcemaximum: (value)
                                                                                }
                                                                            })
                                                                        }}
                                                                    />
                                                                </Form.Group>
                                                            </Tab.Pane>
                                                        </Tab.Content>
                                                    </Tab.Container> */}

                                                </div> :
                                                <div className="formTabs commonMinHeight">
                                                </div>
                                        }
                                    </Col>
                                    <Col md={12}>
                                        <Form.Group className="form-group">
                                            {/* {console.log('-------336------', form)} */}
                                            <Form.Label>Type<sup>*</sup></Form.Label>
                                            <div style={{
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                gap: '10px'
                                            }}>
                                                <div
                                                    key="default-1"
                                                    className="formCheckbox formCheckbox--inline"
                                                >
                                                    <Form.Check
                                                        checked={form.modifiertype == "variation" ? true : false}
                                                        onChange={() => { }}
                                                        onClick={(e) => {
                                                            setisShowError(false)
                                                            console.log('e-----------350-----', e.target.checked)
                                                            setForm({
                                                                ...form,
                                                                modifiertype: "variation",
                                                            });
                                                        }
                                                        }
                                                        name='variation'
                                                        id='variation'
                                                        type='radio'
                                                        label="Variation"
                                                    />
                                                </div>
                                                <div
                                                    key="default-2"
                                                    className="formCheckbox formCheckbox--inline"
                                                >
                                                    <Form.Check
                                                        checked={form.modifiertype == "choices" ? true : false}
                                                        onChange={() => { }}
                                                        onClick={(e) => {
                                                            setisShowError(false)
                                                            setForm({
                                                                ...form,
                                                                modifiertype: "choices",
                                                            });
                                                            // if (e.target.checked) {
                                                            //     setForm((prev) => {
                                                            //         prev.type == "choices"
                                                            //         return { ...prev }
                                                            //     })
                                                            // }
                                                        }
                                                        }
                                                        name='choices'
                                                        id='choices'
                                                        type='radio'
                                                        label="Choices"
                                                    />
                                                </div>
                                                <div
                                                    key="default-3"
                                                    className="formCheckbox formCheckbox--inline"
                                                >
                                                    <Form.Check
                                                        checked={form.modifiertype == "addOn" ? true : false}
                                                        onChange={() => { }}
                                                        onClick={(e) => {
                                                            setisShowError(false)
                                                            setForm({
                                                                ...form,
                                                                modifiertype: "addOn",
                                                            });
                                                        }
                                                        }
                                                        name='addOn'
                                                        id='addOn'
                                                        type='radio'
                                                        label="Add on"
                                                    />
                                                </div>
                                            </div>

                                        </Form.Group>
                                        {form.modifiertype == "choices" ?
                                            <AddChoiceType {...props} /> :
                                            form.modifiertype == "variation" ?
                                                <VariationType {...props} /> :
                                                <AddonChoiceType {...props} />}
                                    </Col>

                                    <div className="applyProduct">
                                        <label>Add to product</label>
                                        <div className="formTabs">
                                            <div className="applyProduct">
                                                <div className="applyProduct__outer">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Search..."
                                                        onChange={onSearchHandler} />
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="productListing">
                                                                <ul>
                                                                    {console.log('displayProduct', displayProduct)}
                                                                    {productList && productList.map((item) => {
                                                                        let isDisabled
                                                                        if (displayProduct.length) {
                                                                            isDisabled = displayProduct.find((prodItem) => prodItem.productId == item.value)
                                                                        }
                                                                        return (
                                                                            <li
                                                                                className={`${isDisabled && "disable"}`}
                                                                                onClick={(e) => {
                                                                                    if (!isDisabled) {
                                                                                        onProductSelect(e, item)
                                                                                    }
                                                                                }}
                                                                            >
                                                                                {titleCase(item.label)}
                                                                            </li>
                                                                        )
                                                                    })}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="productListingSelect">
                                                                <ul>
                                                                    {/* {form.productset &&
                                                                        form.productset.length ?
                                                                        form.productset.map((item) => {
                                                                            return (
                                                                                <li>{titleCase(item.productName)}
                                                                                    <span><RiDeleteBin5Fill
                                                                                        onClick={(event) => onDeleteSelectedProductHandler(event, item)}
                                                                                    /></span>
                                                                                </li>
                                                                            )
                                                                        }) : <li></li>
                                                                    } */}
                                                                    {console.log('displayProduct---1155--', displayProduct)}
                                                                    {displayProduct &&
                                                                        displayProduct.map((item) => {
                                                                            return (
                                                                                <li>{titleCase(item.productName)}
                                                                                    <span><RiDeleteBin5Fill
                                                                                        onClick={(event) => onDeleteSelectedProductHandler(event, item)}
                                                                                    /></span>
                                                                                </li>
                                                                            )
                                                                        })
                                                                    }
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </Row>
                            </Form>
                        </div>
                    </Col>
                    <Col xl={4} lg={4}></Col>
                </Row>
            }
        </div >
    )
}

export default AddNewChoicesAddons;
export { AddChoiceItem }
