import React, { useRef, useState } from "react";
import Select from 'react-select';
import { Button, Table, Nav, Tab, Container, Row, Col, Form } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

//Header
import Header from '../common/header';
import Sidebar from '../common/sidebar';
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { apiGetMethod, apiPostMethod } from "../../api/rest";
import { allApiUrl } from "../../api/apiRoute";
import toastMessages from "../toastMessages";
import CommonButton from "../common-ui/Button";
import { smallStyle, titleCase } from "../../features/helper";
import SidebarTwo from "../common/sidebarTwo";
import Loader from "../shared/spinner";
import { RiDeleteBin5Fill } from "react-icons/ri";

function AddNewProduct() {
    const history = useHistory()
    const [permissionData, setPermissionData] = useState({})
    const branchArray = useSelector((state) => state.counter.branchArray)
    const currBranch = useSelector((state) => state.counter.currBranch)
    const isInitialRender = useRef(true);
    useEffect(() => {
        if (isInitialRender.current) {
            isInitialRender.current = false;
        } else {
            if (branchArray.length > 1) {
                history.push('/users');
            }
        }
        getAllModifier()
    }, [branchArray])
    const [productSizeCount, setProductSizeCount] = useState(1)
    const [active, setActive] = React.useState(false);
    const [loader, setLoader] = useState(false)
    const [preview, setPreview] = useState([]);
    const [form, setForm] = useState({
        productname: '',
        price: '',
        description: '',
        modifierset: [],
        productsize: {
            type: 'single',
            parameters: [{
                size: '',
                price: "0",
                choices: [],
                default: 1
            }
            ],
            parametersMultiple: [{
                size: '',
                price: "0",
                choices: [],
                default: 1
            }
            ]
        },
        visibility: 1,
        category: [],
        file: ''
    })
    console.log('form-->', form)
    const [modifierList, setModifierList] = useState([])
    const [initialModifierList, setInitialModifierList] = useState([])
    const [isProductSavedButtonClicked, setIsProductSavedButtonClicked] = useState(false)
    const [isModifierLoadedSuccessfully, setIsModifierLoadedSuccessfully] = useState(false)
    console.log('---70---', form)
    async function saveProductHandler(e) {
        e.preventDefault()
        let branches = branchArray.map((item) => item.value)
        const header = {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            branchid: JSON.stringify(branches)
        };
        setIsProductSavedButtonClicked(true)  //to highlight the product fields that are empty.
        setLoader(true);
        let isNotEmpty = false
        let defaltIsOne = { status: true, errorMsg: '' }
        // You have to keep at least one size Default Selected. 
        //check if field are empty
        if (form.productsize.type == "single") {   //eslint-disable-line
            isNotEmpty = form.productsize.parameters.every(parameter => {
                return parameter.size !== '' && parameter.price !== ''
            });
            defaltIsOne = form?.productsize?.parameters.filter(obj => obj.default === 1);
            if (defaltIsOne.length == 1) {
                defaltIsOne = { status: true, errorMsg: '' }
            } else if (defaltIsOne.length == 0) {
                defaltIsOne = { status: false, errorMsg: 'You have to keep at least one size Default Selected.' }
            }
        }
        //for multiple product case 
        else {
            isNotEmpty = form.productsize.parametersMultiple.every(parameter => {
                return parameter.size !== '' && parameter.price !== ''
            });
            defaltIsOne = form?.productsize?.parametersMultiple.filter(obj => obj.default === 1);
            if (defaltIsOne.length == 1) {
                defaltIsOne = { status: true, errorMsg: '' }
            } else if (defaltIsOne.length == 0) {
                defaltIsOne = { status: false, errorMsg: 'You have to keep at least one size Default Selected.' }
            } else {
                defaltIsOne = { status: false, errorMsg: 'Default Selected can only be one .Please remove Multiple Default Selected.' }
            }
        }
        // if (defaltIsOne.status) {
        if (form.productname && form.price && form.category.length !== 0) {
            // if (form.productname && isNotEmpty && form.category.length !== 0) {
            const updatedForm = {
                ...form,
                productsize: {
                    ...form.productsize,
                    parameters: form.productsize.type == "multiple" //eslint-disable-line
                        ? form.productsize.parametersMultiple
                        : form.productsize.parameters,
                    parametersMultiple: undefined
                }
            }
            const formData = new FormData()
            formData.append("category", JSON.stringify(updatedForm.category))
            formData.append("productname", updatedForm.productname)
            formData.append("price", updatedForm.price)
            formData.append("productsize", JSON.stringify(updatedForm.productsize))
            formData.append("visibility", updatedForm.visibility)
            formData.append("description", updatedForm.description)
            formData.append('modifierset', JSON.stringify(updatedForm.modifierset))
            // formData.append('', JSON.stringify(updatedForm.modifierset))
            if (updatedForm.file) {
                formData.append("file", updatedForm.file);
            }
            if (updatedForm.preparationtime) {
                formData.append("preparationtime", updatedForm.preparationtime);
            }
            try {
                const response = await apiPostMethod(
                    allApiUrl.addProduct,
                    formData,
                    header
                );
                if (response.status === 200) {
                    toastMessages("success", response.data.message);
                    setForm({
                        productname: '',
                        productsize: {
                            type: 'single',
                            parameters: [{
                                size: '',
                                price: "0",
                                choices: [],
                                default: 1
                            }
                            ],
                            parametersMultiple: [{
                                size: '',
                                price: "0",
                                choices: [],
                                default: 1
                            }

                            ]
                        },
                        visibility: 0,
                        category: []
                    })
                    history.push('/products')
                    setLoader(false);
                }

            } catch (err) {
                setLoader(false);
                console.log("err", err);
                toastMessages("error", err.data.message);
            }
        }
        else {
            toastMessages("error", "Please fill the required fields")
            setLoader(false)
        }
        // }
        // else {
        //     toastMessages("error", defaltIsOne.errorMsg)
        //     setLoader(false)
        // }
    }
    async function getAllModifier() {
        let branches = branchArray.map((item) => item.value)
        const header = {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            branchid: JSON.stringify(branches)
        };
        try {
            const response = await apiGetMethod(`${allApiUrl.view_all_modifier}`, "", header);
            if (response.status === 200) {
                let modifier = response.data.categories.Items.map((item) => {
                    return ({
                        label: titleCase(item.modifiername),
                        value: item.SK
                    })
                })
                console.log('modifier--name----', modifier)
                setModifierList(modifier)
                setInitialModifierList(modifier)

            }
        } catch (err) {

            console.log("err", err);
        }
    }
    function onSearchHandler(e) {
        console.log('-----203-----')
        if (e.target.value !== "") {
            console.log('-----205-----')
            setModifierList(modifierList.filter((item) => {
                if (item.label.toLowerCase().includes(e.target.value.toLowerCase())) {
                    return item
                }
            }))
        } else {
            console.log('-----213-----', initialModifierList)
            setModifierList(initialModifierList)
        }
    }
    let sidebarToggle = useSelector((state) => state.counter.sidebarToggle)
    const [sidebarType, setSidebarType] = useState('singleSidebar')
    let sidebarTypeRedux = useSelector((state) => state.counter.sidebarType)
    return (
        <React.Fragment>
            <Header active={sidebarToggle} />
            <div className="interFaceWrap">
                <Sidebar active={sidebarToggle} activePage="products" permissionData={permissionData} />
                <div className={`interFaceWrap__inner ${sidebarTypeRedux == false ? "doubleSidebar" : "singleSidebar"}`}>
                    <div className="commonOuter">
                        <div className="pageHeader">
                            <Container fluid className="px-0">
                                <Row className="align-items-center">
                                    <div className="col">
                                        <div className="headingText headingText--textLeft mb-0">
                                            <h1>Add New Product</h1>
                                        </div>
                                    </div>
                                    <div className="col-auto ms-auto onlyButtons">
                                        <div className="pageHeader__elements">
                                            <Link to="/products" className="commonButton commonButton--grey">Back to Product</Link>
                                            <CommonButton
                                                buttonName="Save"
                                                onClick={saveProductHandler}
                                                loader={loader}
                                                type="half"
                                            />
                                        </div>
                                    </div>
                                </Row>
                            </Container>
                        </div>
                        <ProductItem
                            form={form}
                            setForm={setForm}
                            setProductSizeCount={setProductSizeCount}
                            productSizeCount={productSizeCount}
                            formType="Add"
                            setPreview={setPreview}
                            preview={preview}
                            isProductSavedButtonClicked={isProductSavedButtonClicked}
                            setIsProductSavedButtonClicked={setIsProductSavedButtonClicked}
                            modifierList={modifierList}
                            setModifierList={setModifierList}
                            onSearchHandler={onSearchHandler}
                        />
                    </div>
                </div>
                <SidebarTwo activePage="" setSidebarType={setSidebarType} sidebarType={sidebarType} setPermissionData={setPermissionData} />
            </div>
        </React.Fragment>
    )
}
const ProductItem = (props) => {
    const branchArray = useSelector((state) => state.counter.branchArray)
    const currBranch = useSelector((state) => state.counter.currBranch)
    const [allCategoriesList, setAllCategoriesList] = useState([])
    const history = useHistory()
    useEffect(() => {
        getAllCategories()
    }, [currBranch.value, branchArray])
    function titleCase(str) {
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        return splitStr.join(' ');
    }
    async function getAllCategories() {
        let branches = branchArray.map((item) => item.value)
        const header = {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            branchid: JSON.stringify(branches)
        };
        try {
            const response = await apiGetMethod(`${allApiUrl.view_all_categories}`, "", header);
            if (response.status === 200) {
                let categories = response.data.categories.Items.map((item) => {
                    return (
                        {
                            value: item.SK,
                            label: titleCase(item.categoryname)
                        }
                    )
                })
                setAllCategoriesList(categories)

            }
        } catch (err) {
            if (err.data.message == "Session Expired.") { //eslint-disable-line
                localStorage.clear();
                history.push('/login')
            }
            if (err.data.message == "ExpressionAttributeValues contains invalid value: The parameters cannot be converted to a numeric value: NaN for key :branchId") { //eslint-disable-line
                history.push('/dashboard')
            }
            console.log("err", err);
        }
    }
    let smallStyle = {
        display: "block",
        fontSize: "13px",
        color: "#E02424"
    }
    function onModifierSelect(e, data) {
        e.preventDefault()
        console.log('form', props.form)
        console.log('value', data)
        props.setForm((prev) => {
            let newModifier = [{ label: data.label, value: data.value }]
            if (prev.modifierset.length) {
                let isAlreadyExist = prev.modifierset.find((modifierItem) => modifierItem.value == data.value)
                if (isAlreadyExist) {
                    let updatedModiferSet
                    updatedModiferSet = prev.modifierset.filter((filterItem) => filterItem.value !== data.value)
                    prev.modifierset = updatedModiferSet
                } else {
                    prev.modifierset = [...prev.modifierset, { label: data.label, value: data.value }]
                }
            } else {
                prev.modifierset = newModifier
            }
            return { ...prev }
        })
    }
    //functionality to delete the selected modifer set
    function onDeleteSelectedModifierHandler(event, data) {
        event.preventDefault()
        if (props.form.modifierset.length) {
            props.setForm((prevData) => {
                let updatedmodifierset = prevData.modifierset.filter((item) => item.value !== data.value)
                prevData.modifierset = updatedmodifierset
                return { ...prevData }
            })
        }
    }
    console.log("🚀 ~ file: index.jsx:318 ~ ProductItem ~ form:", props.form)
    return (
        <div className="pageBody">
            {props.isLoading ?
                <Loader /> :
                <Row>
                    <Col lg={8}>
                        <div className="">
                            <div className="commonBgHead commonBgHead--bottomRadiusNone">Item Details</div>
                            <div className="whiteBg whiteBg--minHeight whiteBg--topRadiusNone small">
                                <Form className="">
                                    <Form.Group className="form-group group-flex">
                                        <Form.Label>Name <sup>*</sup></Form.Label>
                                        <div className="block">

                                            <Form.Control
                                                type="text"
                                                placeholder="Name"
                                                className={`${!props?.form?.productname && props.isProductSavedButtonClicked && "error"}`}
                                                value={(props?.form?.productname)}
                                                onChange={(e) => {
                                                    if (e.target.value.length <= 31) {
                                                        props.setForm((prev) => {
                                                            prev.productname = e.target.value
                                                            return { ...prev }
                                                        })
                                                    }
                                                    // props.setIsProductSavedButtonClicked(false)
                                                }}
                                            />
                                            {!props?.form?.productname && props.isProductSavedButtonClicked && <small>Product name is required !</small>}
                                        </div>
                                    </Form.Group>
                                    <Form.Group className="form-group group-flex">
                                        <Form.Label>Price <sup>*</sup></Form.Label>
                                        <div className="block">

                                            <Form.Control
                                                type="text"
                                                placeholder="Price"
                                                className={`${!props?.form?.price && props.isProductSavedButtonClicked && "error"}`}
                                                value={(props?.form?.price)}
                                                onChange={(e) => {
                                                    if (e.target.value.length < 7) {
                                                        const alphabetRegex = /^[0-9 .,]*$/;
                                                        if (e.target.value.match(alphabetRegex)) {
                                                            props.setForm((prev) => {
                                                                prev.price = e.target.value
                                                                return { ...prev }
                                                            })
                                                        }
                                                    }

                                                }}
                                            />
                                            {!props?.form?.price && props.isProductSavedButtonClicked && <small>Price is required !</small>}
                                        </div>
                                    </Form.Group>
                                    <Form.Group className="form-group group-flex">
                                        <Form.Label>Description</Form.Label>
                                        <Form.Control
                                            type="textarea"
                                            placeholder="Description"
                                            // className={`${!props?.form?.description && props.isProductSavedButtonClicked && "error"}`}
                                            value={props?.form?.description}
                                            onChange={(e) => {
                                                // const alphabetRegex = /^[0-9  a-z A-Z .]*$/;
                                                // if (e.target.value.match(alphabetRegex)) {
                                                props.setForm((prev) => {
                                                    prev.description = e.target.value
                                                    return { ...prev }
                                                })
                                                // }
                                            }
                                            }
                                        />
                                    </Form.Group>
                                    <Form.Group className="form-group group-flex">
                                        <Form.Label>Preparation Time </Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Preparation Time In Minutes"
                                            value={props?.form?.preparationtime}
                                            onChange={(e) => {
                                                const alphabetRegex = /^[0-9 ,.]*$/;
                                                if (e.target.value.match(alphabetRegex)) {
                                                    props.setForm((prev) => {
                                                        prev.preparationtime = e.target.value
                                                        return { ...prev }
                                                    })
                                                }
                                            }
                                            }
                                        />
                                    </Form.Group>
                                    <Form.Group className="form-group group-flex">
                                        <Form.Label>Image</Form.Label>
                                        <div className="imagePreview">
                                            <Form.Control type="file" placeholder=""
                                                accept="image/*"
                                                required
                                                onChange={(e) => {
                                                    if (e) {
                                                        const file = e.target.files;
                                                        if (file) {
                                                            props.setForm((prev) => {
                                                                prev.file = file[0]
                                                                prev.deleteImage = 0
                                                                return { ...prev }
                                                            })
                                                            props.setPreview(URL.createObjectURL(file[0]));
                                                        }
                                                    }
                                                }}
                                                style={{ display: "none" }}
                                                id="file-upload" />
                                            <label htmlFor="file-upload" className="custom-file-upload">
                                                Upload Image
                                            </label>
                                            <div className="imageView">
                                                {props.preview && props.preview.length > 0 && <img src={props.preview} alt="branchImage" />}
                                                {props.preview && props.preview.length > 0 && <i className="fa-solid fa-trash-can pointer" onClick={() => {
                                                    props.setPreview([]);
                                                    props.setForm({ ...props.form, deleteImage: 1, file: '' })
                                                }}></i>}
                                            </div>
                                        </div>
                                    </Form.Group>
                                    <Form.Group className="form-group group-flex group-flex--alignTop">
                                        <Form.Label>Modifier Sets</Form.Label>
                                        <div className="formTabs">
                                            <div className="applyProduct">
                                                <div className="applyProduct__outer">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Search..."
                                                        onChange={props.onSearchHandler}
                                                    />
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="productListing">
                                                                <ul>
                                                                    {props.modifierList &&
                                                                        props.modifierList.length > 0 ?
                                                                        props.modifierList.map((item) => {
                                                                            let isDisabled = props.form.modifierset.find((isDisabledItem) => isDisabledItem.value == item.value)
                                                                            return (
                                                                                <li
                                                                                    className={`${isDisabled && "disable"}`}
                                                                                    onClick={(e) => {
                                                                                        if (!isDisabled) {
                                                                                            onModifierSelect(e, item)
                                                                                        }
                                                                                    }
                                                                                    }>
                                                                                    {item.label}
                                                                                </li>
                                                                            )
                                                                        }) : <p className="noMatchFound">No matches found</p>}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="productListingSelect">
                                                                <ul>
                                                                    {props.form &&
                                                                        props.form.modifierset &&
                                                                        props.form.modifierset.map((item) => {
                                                                            return (
                                                                                <li>{item.label}
                                                                                    <span>
                                                                                        <RiDeleteBin5Fill
                                                                                            onClick={(event) => onDeleteSelectedModifierHandler(event, item)} /></span>
                                                                                </li>
                                                                            )
                                                                        })
                                                                    }
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <Tab.Container id="sizesTab" defaultActiveKey="single">
                                                <Nav className="commonTabs">
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="single"
                                                            active={props?.form?.productsize?.type == "single" ? true : false} //eslint-disable-line
                                                            onClick={(e) => {
                                                                props.setForm((prevData) => {
                                                                    prevData.productsize.type = 'single'
                                                                    return {
                                                                        ...prevData
                                                                    }
                                                                })
                                                            }}
                                                        >Single</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="multiple"
                                                            active={props?.form?.productsize?.type == "multiple" ? true : false} //eslint-disable-line
                                                            onClick={(e) => {
                                                                props.setForm((prevData) => {
                                                                    prevData.productsize.type = 'multiple'
                                                                    return {
                                                                        ...prevData
                                                                    }
                                                                })
                                                            }}
                                                        >Multiple</Nav.Link>
                                                    </Nav.Item>
                                                </Nav>
                                                <Tab.Content className="">
                                                    <Tab.Pane eventKey="single" active={props?.form?.productsize?.type == "single" ? true : false} //eslint-disable-line
                                                    >
                                                        <SingleAddItem
                                                            formType={props.formType}
                                                            type="single"
                                                            form={props.form}
                                                            setForm={props.setForm}
                                                            isProductSavedButtonClicked={props.isProductSavedButtonClicked}
                                                            setIsProductSavedButtonClicked={props.setIsProductSavedButtonClicked}
                                                        />
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="multiple" active={props?.form?.productsize?.type == "multiple" ? true : false} //eslint-disable-line
                                                    >
                                                        <AddProductItem
                                                            formType={props.formType}
                                                            type="multiple"
                                                            form={props.form}
                                                            setForm={props.setForm}
                                                            productSizeCount={props.productSizeCount}
                                                            setProductSizeCount={props.setProductSizeCount}
                                                            isProductSavedButtonClicked={props.isProductSavedButtonClicked}
                                                            setIsProductSavedButtonClicked={props.setIsProductSavedButtonClicked}
                                                        />
                                                    </Tab.Pane>
                                                </Tab.Content>
                                            </Tab.Container> */}
                                        </div>
                                    </Form.Group>

                                    <Form.Group className="form-group group-flex">
                                        <Form.Label>Visibility</Form.Label>
                                        <Form.Check
                                            onClick={(e) => {
                                                props.setForm((prevData) => {
                                                    prevData.visibility = e.target.checked == true ? 1 : 0 //eslint-disable-line
                                                    return {
                                                        ...prevData
                                                    }
                                                })
                                            }}
                                            checked={props.form.visibility == 1 ? true : false} //eslint-disable-line
                                            type="switch"
                                            onChange={() => { }}
                                            id="custom-switch"
                                            className="show"
                                            label=""

                                        />
                                    </Form.Group>
                                </Form>
                            </div>
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className="mt-lg-0 mt-4">
                            <div className="commonBgHead commonBgHead--bottomRadiusNone">Categories <sup>*</sup></div>
                            <div className="whiteBg whiteBg--minHeight whiteBg--topRadiusNone small">
                                <Form.Group className="form-group">
                                    <Select className="select2 multiSelect"
                                        isMulti={true}
                                        // menuIsOpen={true}
                                        // isClearable={false}
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        options={allCategoriesList}
                                        value={props.form.category}
                                        onChange={(e) => {
                                            props.setForm((prevData) => {
                                                prevData.category = e
                                                return {
                                                    ...prevData
                                                }
                                            })
                                        }}
                                        labelledBy="Select" />
                                </Form.Group>
                                {!props.form.category.length && props.isProductSavedButtonClicked && <small style={smallStyle}>Category is required !</small>}
                            </div>
                        </div>
                    </Col>
                </Row >
            }

        </div>
    )
}
const AddProductItem = (props) => {
    const unique_id = uuid();
    const small_id = unique_id.slice(0, 8)
    const history = useHistory()
    const branchArray = useSelector((state) => state.counter.branchArray)
    const currBranch = useSelector((state) => state.counter.currBranch)
    const [modifierList, setModifierList] = useState([])
    useEffect(() => {
        getAllModifier()
    }, [currBranch.value, branchArray])

    function increment(event) {
        event.preventDefault();
        props.setForm((prev) => {
            props.form.productsize.parametersMultiple.forEach(item => {
                if (item.price === "") {
                    item.price = "0";
                }
            });
            return { ...prev }
        })
        props.setProductSizeCount(props.productSizeCount + 1);
        props.setForm((prevData) => {
            prevData.productsize.parametersMultiple.push({
                price: "0",
                size: '',
                choices: [],
                default: 0
            })

            return { ...prevData }
        });

    }
    const decrement = (event, index) => {
        event.preventDefault();
        let backupData = [...props.form.productsize.parametersMultiple];
        backupData.splice(index, 1);
        props.setForm((prev) => {
            prev.productsize.parametersMultiple = backupData;
            return { ...prev };
        });
        props.setProductSizeCount((prev) => prev - 1);
    };
    function titleCase(str) {
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        return splitStr.join(' ');
    }
    async function getAllModifier() {
        let branches = branchArray.map((item) => item.value)
        const header = {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            branchid: JSON.stringify(branches)
        };
        try {
            const response = await apiGetMethod(`${allApiUrl.view_all_modifier}`, "", header);
            if (response.status === 200) {
                let modifier = response.data.categories.Items.map((item) => {
                    return ({
                        label: titleCase(item.modifiername),
                        value: item.SK
                    })
                })
                setModifierList(modifier)
            }
        } catch (err) {

            console.log("err", err);
        }
    }
    let smallStyle = {
        display: "block",
        fontSize: "13px",
        color: "#E02424"
    }
    return (
        <div className="choicesAddonsTable commonTableResponsive text-start">
            <Table className="basicTable">
                <thead>
                    <tr>
                        <th className="sizePrice">Size</th>
                        <th className="sizePrice">Price</th>
                        <th>Select Choices & Addons</th>
                        <th>Default Selected</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        [...Array(props.productSizeCount)].map((item, index) => {
                            return (
                                <>
                                    <tr key={`${index}`}>
                                        <td data-label="Size">
                                            <Form.Control type="text" placeholder="Size"
                                                value={props.form?.productsize?.parametersMultiple[index]?.size}
                                                className={`${!props.form?.productsize?.parametersMultiple[index]?.size && props.isProductSavedButtonClicked && "error"}`}
                                                onChange={(e) => {
                                                    if (e.target.value.length < 21) {
                                                        props.setForm((prevData) => {
                                                            prevData.productsize.parametersMultiple[index].size = e.target.value
                                                            return {
                                                                ...prevData
                                                            }
                                                        })
                                                    }
                                                }}
                                            /></td>
                                        <td data-label="Price"><Form.Control type="text" placeholder="Price"
                                            value={props.form?.productsize?.parametersMultiple[index]?.price}
                                            onChange={(e) => {
                                                props.setForm((prevData) => {
                                                    const re = /[^0-9!@#$%^&*(),.?":{}|<>]/g;
                                                    prevData.productsize.parametersMultiple[index].price = e.target.value.replace(re, "")
                                                    return {
                                                        ...prevData
                                                    }
                                                })
                                            }}

                                        /></td>
                                        <td data-label="Select Choices & Addons">
                                            <Select className="select2 multiSelect"
                                                isMulti={true}
                                                // menuIsOpen={true}
                                                isClearable={false}
                                                closeMenuOnSelect={false}
                                                hideSelectedOptions={false}
                                                options={modifierList}
                                                value={props.form?.productsize?.parametersMultiple[index]?.choices}
                                                onChange={(e) => {
                                                    props.setForm((prevData) => {
                                                        prevData.productsize.parametersMultiple[index].choices = e
                                                        return {
                                                            ...prevData
                                                        }
                                                    })
                                                }}
                                                labelledBy="Select" />
                                        </td>
                                        <td data-label="Default Selected">
                                            {['radio'].map((type, idx) => {
                                                return (
                                                    <div key={`default-${idx}`} className="formCheckbox formCheckbox--inline">
                                                        <Form.Check
                                                            checked={props.form?.productsize?.parametersMultiple[index]?.default == 1 ? true : false} //eslint-disable-line
                                                            onClick={() => {
                                                                // if (data.length === 1) {
                                                                props.setForm((prevData) => {
                                                                    prevData.productsize.parametersMultiple[index].default = 1
                                                                    return {
                                                                        ...prevData
                                                                    }
                                                                })
                                                            }}
                                                            onChange={() => { }}
                                                            name={`sizeSelected-${props.form?.productsize?.parametersMultiple[index]?.size}${idx}${small_id}`}
                                                            id={`yesCheck-${props.form?.productsize?.parametersMultiple[index]?.size}${idx}${small_id}`}
                                                            type={type}
                                                            label="Yes"
                                                        />
                                                        <Form.Check
                                                            checked={props.form?.productsize?.parametersMultiple[index]?.default == 0 ? true : false} //eslint-disable-line
                                                            onClick={() => {
                                                                props.setForm((prevData) => {
                                                                    prevData.productsize.parametersMultiple[index].default = 0
                                                                    return {
                                                                        ...prevData
                                                                    }
                                                                })
                                                            }}
                                                            onChange={() => { }}

                                                            name={`sizeSelected-${props.form?.productsize?.parametersMultiple[index]?.size}${idx}${small_id}`}
                                                            id={`yesCheck-${props.form?.productsize?.parametersMultiple[index]?.size}${idx}${small_id}`}
                                                            label="No"
                                                            type={type} />
                                                    </div>
                                                )
                                            })}
                                        </td>
                                        {
                                            props.type == "multiple" && <td><Button className="deleteButton" type="button"><i className="fa-solid fa-trash-can" //eslint-disable-line
                                                onClick={(event) => { props.productSizeCount > 1 && decrement(event, index) }}
                                            ></i></Button></td>
                                        }
                                    </tr>
                                    {!props.form?.productsize?.parametersMultiple[index]?.size && props.isProductSavedButtonClicked && <small style={smallStyle}>Size is required !</small>}
                                    {
                                        index == props.productSizeCount - 1 && props.type == "multiple" && //eslint-disable-line
                                        <tfoot>
                                            <tr key={index}>
                                                <td>
                                                    <Button type="button" className="commonButton commonButton--white  commonButton--small mt-2"
                                                        onClick={(event) => {
                                                            increment(event, index)
                                                        }}>
                                                        <span>Add</span> <i className="fa-solid fa-plus"></i>
                                                    </Button>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    }
                                </>
                            )
                        })
                    }

                </tbody>


            </Table>
        </div>
    )
}
const SingleAddItem = (props) => {
    // if (props.form.productsize.type == 'single' && props.formType == "Add") {
    //     props.setProductSizeCount(1)
    // }
    const branchArray = useSelector((state) => state.counter.branchArray)
    const unique_id = uuid();
    const small_id = unique_id.slice(0, 8)
    const history = useHistory()
    const currBranch = useSelector((state) => state.counter.currBranch)
    const [modifierList, setModifierList] = useState([])
    let initalmodifierList = []
    useEffect(() => {
        getAllModifier()
    }, [currBranch.value, branchArray])
    function titleCase(str) {
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        return splitStr.join(' ');
    }
    async function getAllModifier() {
        let branches = branchArray.map((item) => item.value)
        const header = {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            branchid: JSON.stringify(branches)
        };
        try {
            const response = await apiGetMethod(`${allApiUrl.view_all_modifier}`, "", header);
            if (response.status === 200) {
                let modifier = response.data.categories.Items.map((item) => {
                    return ({
                        label: titleCase(item.modifiername),
                        value: item.SK
                    })
                })
                setModifierList(modifier)
                initalmodifierList = modifier
            }
        } catch (err) {
            console.log("err", err);
        }
    }
    let smallStyle = {
        display: "block",
        fontSize: "13px",
        color: "#E02424"
    }
    return (
        <div className="choicesAddonsTable commonTableResponsive text-start">
            <Table className="basicTable">
                <thead>
                    <tr>
                        <th className="sizePrice">Size</th>
                        <th className="sizePrice">Price</th>
                        <th>Select Choices & Addons</th>
                        <th>Default Selected</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        [...Array(props.productSizeCount)].map((item, index) => {
                            return (
                                <>
                                    <tr key={`${index}`}>
                                        <td data-label="Size">
                                            <Form.Control type="text" placeholder="Size"
                                                className={`${!props.form?.productsize?.parameters[0]?.size && props.isProductSavedButtonClicked && "error"}`}
                                                value={props.form?.productsize?.parameters[0]?.size}
                                                onChange={(e) => {
                                                    if (e.target.value.length < 11) {
                                                        props.setForm((prevData) => {
                                                            prevData.productsize.parameters[0].size = e.target.value
                                                            return {
                                                                ...prevData
                                                            }
                                                        })
                                                        // props.setIsProductSavedButtonClicked(false)
                                                    }
                                                }}
                                            />

                                        </td>
                                        <td data-label="Price">
                                            <Form.Control
                                                type="text"
                                                placeholder="Price"
                                                className={`${!props.form?.productsize?.parameters[0]?.price && props.isProductSavedButtonClicked && "error"}`}
                                                value={props.form?.productsize?.parameters[0]?.price}
                                                onChange={(e) => {
                                                    if (e.target.value.length < 5) {
                                                        props.setForm((prevData) => {
                                                            const re = /[^0-9!@#$%^&*(),.?":{}|<>]/g;
                                                            prevData.productsize.parameters[0].price = e.target.value.replace(re, "")
                                                            return {
                                                                ...prevData
                                                            }
                                                        })
                                                    }
                                                }}
                                            /></td>
                                        <td data-label="Select Choices & Addons">
                                            <Select className="select2 multiSelect"
                                                isMulti={true}
                                                // menuIsOpen={true}
                                                isClearable={false}
                                                closeMenuOnSelect={false}
                                                hideSelectedOptions={false}
                                                options={modifierList}
                                                value={props.form?.productsize?.parameters[0]?.choices}
                                                onChange={(e) => {
                                                    props.setForm((prevData) => {
                                                        prevData.productsize.parameters[0].choices = e
                                                        return {
                                                            ...prevData
                                                        }
                                                    })
                                                }}
                                                labelledBy="Select" />
                                        </td>
                                        <td data-label="Default Selected">
                                            {['radio'].map((type, idx) => {
                                                return (
                                                    <div key={`default-${idx}`} className="formCheckbox formCheckbox--inline">
                                                        <Form.Check
                                                            checked={props.form?.productsize?.parameters[0]?.default == 1 ? true : false} //eslint-disable-line
                                                            onClick={() => {
                                                                props.setForm((prevData) => {
                                                                    prevData.productsize.parameters[0].default = 1
                                                                    return {
                                                                        ...prevData
                                                                    }
                                                                })
                                                            }}
                                                            onChange={() => { }}
                                                            name={`sizeSelected-${props.form?.productsize?.parameters[0]?.size}${idx}${small_id}`}
                                                            id={`yesCheck-${props.form?.productsize?.parameters[0]?.size}${idx}${small_id}`}
                                                            type={type}
                                                            label="Yes"
                                                        />
                                                        <Form.Check
                                                            checked={props.form?.productsize?.parameters[0]?.default == 0 ? true : false} //eslint-disable-line
                                                            onChange={() => { }}
                                                            onClick={() => {
                                                                props.setForm((prevData) => {
                                                                    prevData.productsize.parameters[0].default = 0
                                                                    return {
                                                                        ...prevData
                                                                    }
                                                                })
                                                            }}
                                                            name={`sizeSelected-${props.form?.productsize?.parameters[0]?.size}${idx}${small_id}`}
                                                            id={`yesCheck-${props.form?.productsize?.parameters[0]?.size}${idx}${small_id}`}
                                                            label="No"
                                                            type={type} />
                                                    </div>
                                                )
                                            })}
                                        </td>
                                    </tr>
                                    {!props.form?.productsize?.parameters[0]?.size && props.isProductSavedButtonClicked &&
                                        <small style={smallStyle}>Size is required !</small>}
                                </>
                            )
                        })
                    }

                </tbody>


            </Table>
        </div>
    )
}
export { ProductItem, AddProductItem }
export default AddNewProduct; 