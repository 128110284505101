import React, { useState } from "react";
import Select from 'react-select';
import { Button, Badge, Dropdown, Table, Container, Row, Col, Image, ListGroup, Form, FloatingLabel, InputGroup, Pagination } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';

//Header
import Header from '../common/header';
import Sidebar from '../common/sidebar';
import { EmployeeTable } from "../employees";
import { apiGetMethod, apiPostMethod, header } from "../../api/rest";
import toastMessages from "../toastMessages";
import { allApiUrl } from "../../api/apiRoute";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { smallStyle } from "../../features/helper";
import SidebarTwo from "../common/sidebarTwo";

//Icons
// import arrowUpIcon from '../shared/assets/icons/arrow-up.svg';
// import arrowRightIcon from '../shared/assets/icons/right-arrow.svg';

function AddNewEmployee() {
    const currBranch = useSelector((state) => state.counter.currBranch)
    const [loader, setLoader] = useState(false)
    const history = useHistory()
    const [allEmployeeList, setAllEmployeeList] = useState([])
    const [isEmployeeTableRefreshed, setisEmployeeTableRefreshed] = useState(false)
    //Sidebar Toggle
    const [active, setActive] = React.useState(false)
    const [form, setForm] = useState({
        fullname: '',
        pin: '',
        branch: [{ label: currBranch.label.toLowerCase(), value: currBranch.value }]
    })
    async function AddEmployeeHandler(e) {
        const header = {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            branchid: currBranch.value
        };
        e.preventDefault()
        setLoader(true);
        if (form.fullname && form.pin) {
            try {
                setForm(prevForm => {
                    return {
                        ...prevForm,
                        fullname: prevForm.fullname.trim(),
                        pin: prevForm.pin,
                    }
                })
                const response = await apiPostMethod(
                    allApiUrl.addEmployee,
                    form,
                    header
                );
                if (response.status === 200) {
                    toastMessages("success", "Employee Added Successfully.");
                    setForm({
                        fullname: '',
                        pin: '',
                        branch: [{ label: currBranch.label.toLowerCase(), value: currBranch.value }]
                    })
                    getAllEmployee()
                    setLoader(false);
                }
            } catch (err) {
                toastMessages("error", err.data.message);
                setLoader(false);
            }
        }
        else {
            toastMessages("error", "Please fill the required fields")
        }
    }
    // console.log('form', form)
    useEffect(() => {
        getAllEmployee()
        if (currBranch.value == "All") {
            history.push('/dashboard')
        }
    }, [isEmployeeTableRefreshed, currBranch.value])
    async function getAllEmployee() {
        const header = {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            branchid: currBranch.value
        };
        try {
            const response = await apiGetMethod(allApiUrl.view_all_employee, "", header);
            if (response.status === 200) {
                setAllEmployeeList(response.data.data.Items)
                setisEmployeeTableRefreshed(false)
                generatePasscodeHandler()
            }
        } catch (err) {
            toastMessages("error", err.data.message);
            if (err.data.message == "Session Expired.") {
                localStorage.clear();
                history.push('/login')
            }
            setisEmployeeTableRefreshed(false)
            console.log("err", err);
        }
    }
    async function generatePasscodeHandler() {
        const header = {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            branchid: currBranch.value
        };
        try {
            const response = await apiGetMethod(allApiUrl.generate_pincode, "", header);
            if (response.status === 200) {
                let pin = response.data.code + ''
                setForm({ ...form, pin, fullname: '' })
            }
        } catch (err) {
            console.log('err', err)
        }
    }
    let sidebarToggle = useSelector((state) => state.counter.sidebarToggle)
    const [sidebarType, setSidebarType] = useState('singleSidebar')
    let sidebarTypeRedux = useSelector((state) => state.counter.sidebarType)
    return (
        <React.Fragment>
            <Header active={sidebarToggle} />
            <div className="interFaceWrap">
                <Sidebar active={sidebarToggle}
                    activePage="employees"
                />
                <div className={`interFaceWrap__inner ${sidebarTypeRedux == false ? "doubleSidebar" : "singleSidebar"}`}>
                    <div className="commonOuter">
                        <div className="pageHeader">
                            <Container fluid className="px-0">
                                <Row className="align-items-center">
                                    <div className="col">
                                        <div className="headingText headingText--textLeft mb-0">
                                            <h1>Add Employee</h1>
                                        </div>
                                    </div>
                                    <div className="col-auto ms-auto onlyButtons">
                                        <div className="pageHeader__elements">
                                            <Button type="button" className="commonButton" onClick={AddEmployeeHandler}>Save</Button>
                                        </div>
                                    </div>
                                </Row>
                            </Container>
                        </div>
                        <EmployeeItem
                            setForm={setForm}
                            form={form}
                        />
                        <EmployeeTable
                            allEmployeeList={allEmployeeList}
                            setAllEmployeeList={setAllEmployeeList}
                            setisEmployeeTableRefreshed={setisEmployeeTableRefreshed}
                        />
                    </div>
                </div>
                <SidebarTwo activePage="" setSidebarType={setSidebarType} sidebarType={sidebarType} />
            </div>
        </React.Fragment>
    )
}
export default AddNewEmployee;

const EmployeeItem = ({
    setForm, form
}) => {
    let props = { setForm, form }
    function titleCase(str) {
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        return splitStr.join(' ');
    }
    return (
        <div className="selectEmployeeOpetions">
            <Row>
                <Col xxl={7} xl={8} lg={12}>
                    <div className="whiteBg">
                        <Form>
                            <Row>
                                <EmployeFields
                                    {...props}
                                />
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div>
                                        <sup style={{ color: 'red' }}>*</sup><small>Employees may have the same name, but their PINs must be different.</small>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Col>
                <Col xl={6} lg={4}></Col>
            </Row>
        </div>
    )
}
const EmployeFields = ({
    setForm,
    form,
    errorInput,
    setErrorInput,
    smallStyle
}) => {
    const [permissionListing, setPermissionListing] = useState([])
    const branchArray = useSelector((state) => state.counter.branchArray)
    let branches = branchArray.map((item) => item.value)
    const header = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        branchid: JSON.stringify(branches)
    };
    const getAllPermissions = async () => {
        console.log('----217-')
        try {
            console.log('----218-')
            const response = await apiGetMethod(allApiUrl.rolesAndPermissions, '', header)
            console.log('----221-')
            if (response.status == 200) {
                setPermissionListing(response.data.data)
                if (!form.permissiongranted) {
                    let permissiongranted = response.data.data.map((permissionItem) => {
                        return { key: permissionItem.label, value: 0 }
                    })
                    setForm({ ...form, permissiongranted })
                }
            }
        }
        catch (err) {
            console.log('err', err)
        }
    }
    useEffect(() => {
        console.log('--------229-----')
        getAllPermissions()
    }, [])
    console.log('========form=======', form)
    console.log('permissionListing', permissionListing)
    return (
        <>
            <Col md={6}>
                <Form.Group className="form-group">
                    <Form.Label>Employee Name<sup>*</sup></Form.Label>
                    <Form.Control
                        type="text"
                        placeholder=""
                        value={(form.fullname)}
                        className={`${errorInput.fullname ? "error" : ''}`}
                        onChange={(e) => {
                            const alphabetRegex = /^[a-zA-Z .]*$/;
                            if (e.target.value.length < 60) {
                                if (e.target.value.match(alphabetRegex)) {
                                    setErrorInput({ ...errorInput, fullname: false })
                                    setForm({
                                        ...form,
                                        fullname: e.target.value,
                                    });
                                }
                            }
                        }}
                    />
                    {errorInput.fullname && <small>Name is required!</small>}
                </Form.Group>
            </Col>
            <Col md={6}>
                <Form.Group className="form-group">
                    <Form.Label>Pin<sup>*</sup></Form.Label>
                    <Form.Control
                        type="text"
                        placeholder=""
                        className={`${errorInput.pin ? "error" : ''}`}
                        value={form.pin}
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={(evt) =>
                            evt.key === "e" && evt.preventDefault()
                        }
                        onChange={(e) => {
                            const re = /[^0-9]/g;
                            if (e.target.value.length < 5) {
                                setForm({ ...form, pin: e.target.value.replace(re, "") })
                                setErrorInput({ ...errorInput, pin: false })
                            }
                        }}
                    />
                    {errorInput.pin && <small style={smallStyle}>Pin is required!</small>}
                </Form.Group>
            </Col>
            <Col md={6}>
                <div className="commonCheckOptions">
                    <Form.Group className="form-group permissionOuter text_aligned_left">
                        <Form.Label>Permission's</Form.Label>
                        {permissionListing &&
                            permissionListing.length > 0 ?
                            permissionListing.map((item, index) => {
                                return (
                                    <div key={`inline-checkbox`} className="mb-3">
                                        <div  >
                                            <Form.Check
                                                inline
                                                checked={form.permissiongranted.find((perItem) => perItem.key == item.label).value == 1 ? true : false}
                                                onClick={(e) => {
                                                    setForm((prev) => {
                                                        let updatedPermission = form.permissiongranted.map((permissionItem) => {
                                                            if (permissionItem.key == item.label) {
                                                                return {
                                                                    ...permissionItem,
                                                                    value: e.target.checked == true ? 1 : 0
                                                                }
                                                            }
                                                            return permissionItem
                                                        })
                                                        prev.permissiongranted = updatedPermission
                                                        return { ...prev }
                                                    })
                                                }}
                                                label={item.permissionName}
                                                name={item.permissionName}
                                                type='checkbox'
                                                id={`inline-checkbox-${index}`}
                                            />
                                        </div>
                                    </div>
                                )
                            }) : ''
                        }
                    </Form.Group>
                </div>
            </Col>
        </>
    )
}

export { EmployeeItem, EmployeFields }
