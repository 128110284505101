import {
  Button,
  Container,
  Row,
  Col,

  Form,
} from "react-bootstrap";
import { Link } from "react-router-dom";
//SideBanner
import SideBanner from "../sideBanner";
import React, { useState, useEffect } from "react";
import { allApiUrl } from "../../../api/apiRoute";
import { useHistory } from "react-router-dom";
import { apiPostMethod } from "../../../api/rest";
import toastMessages from "../../toastMessages";
import CommonButton from "../../common-ui/Button";
import "react-phone-input-2/lib/bootstrap.css";
import PhoneInput from "react-phone-input-2";
import { countNumberInPhoneNumber, smallStyle } from "../../../features/helper";
function SignUp() {
  const [form, setForm] = useState({
    username: "",
    fullname: "",
    email: "",
    password: "",
    phone: "",
    countrycode: '1',
    checkTermAndCondition: false,
  });

  const [showPass, setShowPass] = useState(false);
  const [userNameValidation, setUserNameValidation] = useState([]);
  // console.log('34', form)
  const specialCharacters = /[!@#$%^&*(),.?":{}|<>]/;
  const [isValid, setIsValid] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);
  const [phoneValidatError, setPhoneValidError] = useState(false);
  const [invalidUserName, setInvalidUserName] = useState(false);
  const [invalidUserNameError, setInvalidUserNameError] = useState("");
  const [showRedText, setShowRedText] = useState(false);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(null);
  const [userNameAvailable, setUsernameAvailable] = useState(true)

  const history = useHistory();
  useEffect(() => {
    if (localStorage.getItem("isLoggedIn") === "true") {
      history.push("/dashboard");
    }
  }, []);
  async function onSubmit(e) {
    e.preventDefault();
    setLoader(true);
    try {
      if (
        isValid === true &&
        !userNameValidation &&
        isEmailValid &&
        isPhoneNumberValid &&
        !invalidUserName
      ) {
        setForm(prevForm => {
          return {
            ...prevForm,
            username: prevForm.username.trim(),
            email: prevForm.email.trim(),
            fullname: prevForm.fullname.trim(),
          }
        })
        const response = await apiPostMethod(allApiUrl.signUp, form, "");
        if (response.status === 200) {
          setLoader(false);
          toastMessages("success", "User Created Successfully");
          setShowRedText(false);
          history.push("/login");
        }
      }
      else if (isEmailValid === false) {
        setLoader(false);
        toastMessages("error", "Email is not valid.");
      }
      else if (isPhoneNumberValid === false) {
        setLoader(false);
        toastMessages("error", phoneValidatError);
      }
      else if (invalidUserName === true) {
        setLoader(false);
        toastMessages("error", invalidUserNameError);
      } else if (userNameValidation && userNameValidation.length > 0) {
        setLoader(false);
        toastMessages("error", "Please use special character after entering any text.");
      } else {
        setLoader(false);
        setShowRedText(true);
        toastMessages("error", "The password is not valid");
      }
    } catch (err) {
      setLoader(false);
      toastMessages("error", err.data.message);
      console.log("err", err);
    }
  }
  function handlePasswordChange(event) {
    setForm({ ...form, password: event.target.value });
    setIsValid(validatePassword(event.target.value));
  }
  function validatePassword(password) {
    const regex =
      /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*_#?&])([a-zA-Z0-9@$!%*_#?&]{8,})$/;
    return regex.test(password);
  }
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  const emailValidation = (e) => {
    if (e.target.value.length < 81) {
      setForm({ ...form, email: e.target.value });
      setError(null);
      if (isValidEmail(e.target.value)) {
        let check = e.target.value.split("@")[0];
        let isnum = /^\d+$/.test(check);

        if (isnum === true || containsOnlySpecialChars(check)) {
          setIsEmailValid(false);
          setError("The email address field must be a valid email");
        } else {
          setIsEmailValid(true);
          setError("email is valid");
        }
      } else {
        setIsEmailValid(false);
        setError("The email address field must be a valid email");
      }
    } else {
      setIsEmailValid(false);
      setError("Email cann't be greater than 80 chars.");
    }
  };
  function containsOnlySpecialChars(str) {
    return /^[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/.test(str); //eslint-disable-line
  }
  function userNameContainingOnlyDigitAndSpecial(e) {
    let isnum = /^\d+$/.test(e.target.value);
    if (containsOnlySpecialChars(e.target.value) || isnum) {
      setInvalidUserName(true);
      setInvalidUserNameError(
        "username cannot contain only digit or special character"
      );
    } else {
      setInvalidUserName(false);
    }
  }
  async function checkUserNameAvailabilty(e) {
    if (e.target.value) {
      try {
        const response = await apiPostMethod(allApiUrl.check_username, { username: e.target.value.trim() }, "");
        if (response.status === 200) {
          setUsernameAvailable(true)
        }
      } catch (err) {
        console.log('err', err)
        setUsernameAvailable(false)

      }
    }
  }
  const handleOnChange = (value, country) => {
    setForm({ ...form, phone: value });
    const result = countNumberInPhoneNumber(country.format, ".");
    if (value.length - country.dialCode.length == result - country.dialCode.length || (result - country.dialCode.length) > 10) {
      setIsPhoneNumberValid(true);
    } else {
      setIsPhoneNumberValid(false);
      setPhoneValidError(`The Phone Number should be of ${result - country.dialCode.length} Digit`);
    };
  }
  return (
    <React.Fragment>
      <section className="formWrapper">
        <Container fluid>
          <Row className="flex-row-reverse">
            <SideBanner />
            <Col xl={5} lg={6} className="formGrid signUpFormGrid">
              <div className="formOuter">
                <div className="formOuter__inner">
                  <h1>Sign Up</h1>
                  <Form onSubmit={onSubmit}>
                    <Row>
                      <Col lg={12}>
                        <Form.Group className="form-group">
                          <Form.Label>
                            Username<sup>*</sup>
                          </Form.Label>
                          <Form.Control
                            required
                            className={`formField ${!userNameAvailable && "error"}`}
                            type="text"
                            validate
                            value={form.username}
                            onChange={(e) => {
                              setUsernameAvailable(true)
                              setUserNameValidation(
                                e.target.value
                                  .charAt(0)
                                  .match(specialCharacters)
                              );
                              userNameContainingOnlyDigitAndSpecial(e);
                              const alphabetRegex = /^[a-zA-Z0-9@_.]*$/;
                              if (e.target.value.match(alphabetRegex)) {
                                setForm({
                                  ...form,
                                  username: e.target.value,
                                });
                              }
                              checkUserNameAvailabilty(e)
                            }}
                          />
                          {form.username !== "" &&
                            <small
                              style={{
                                color: `${userNameAvailable === true ? "green" : "red"}`,
                              }}
                            >
                              {`${userNameAvailable === true ? " Username is available." : "Username is already being taken."}`}
                            </small>
                          }
                        </Form.Group>
                        { }
                      </Col>
                      <Col lg={12}>
                        <Form.Group className="form-group">
                          <Form.Label>
                            Organization Name<sup>*</sup>
                          </Form.Label>
                          <Form.Control
                            required
                            className="formField"
                            type="text"
                            value={form.fullname}
                            onChange={(e) => {
                              const alphabetRegex = /^[a-zA-Z0-9 !@#$%^&*(),.?":{}|<>]*$/;
                              if (e.target.value.match(alphabetRegex)) {
                                setForm({
                                  ...form,
                                  fullname: e.target.value,
                                });
                              }
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={12}>
                        <Form.Group className="form-group">
                          <Form.Label>
                            Email<sup>*</sup>
                          </Form.Label>
                          <Form.Control
                            required
                            className={`formField ${form.email && !isEmailValid && "error"} notCapital`}
                            type="email"
                            value={form.email}
                            onChange={emailValidation}
                          />
                          {error &&
                            form.email !== "" &&
                            error !== "email is valid" && (
                              <small
                                style={{
                                  color: "red",
                                }}
                              >
                                {error}
                              </small>
                            )}
                        </Form.Group>
                      </Col>
                      <Col lg={12}>
                        <Form.Group className="form-group">
                          <Form.Label>
                            Phone Number<sup>*</sup>
                          </Form.Label>
                          <PhoneInput
                            country="us"
                            value={'1'}
                            onChange={handleOnChange}
                            enableSearch={true}
                            inputStyle={{ width: "100%" }}
                          />
                          {isPhoneNumberValid === false && form.phone && (
                            <small style={smallStyle}>
                              {phoneValidatError}
                            </small>
                          )}
                        </Form.Group>
                      </Col>
                      <Col lg={12}>
                        <Form.Group className="form-group">
                          <Form.Label>
                            Password<sup>*</sup>
                          </Form.Label>
                          <div className="passwordWrap">
                            <Form.Control
                              required
                              autoComplete="new-password"
                              className={`formField ${!isValid && showRedText && 'error'} notCapital`}
                              type={`${!showPass ? "password" : "text"}`}
                              value={form.password}
                              onChange={handlePasswordChange}
                            />
                            <Button className="showPassIcon" type="button">
                              <i
                                onClick={() => {
                                  setShowPass(!showPass)
                                }}
                                className={
                                  showPass ? "fas fa-eye" : "fas fa-eye-slash"
                                }
                              ></i>
                            </Button>
                          </div>
                          {!isValid && (
                            <small
                              style={{
                                display: "block",
                                fontSize: "13px",
                                color: `${showRedText && "#E02424"}`,
                              }}
                            >
                              The password has to be at least 8 characters
                              long, with at least 1 Uppercase letter, 1 Lowercase
                              letter, 1 Number and 1 Special Character.
                            </small>
                          )}
                        </Form.Group>
                      </Col>

                      <Col lg={12}>
                        <Form.Group className="form-group formCheckbox">
                          {["checkbox"].map((type) => (
                            <Form.Check
                              required
                              type={type}
                              id={`termAndCondition-${type}`}
                              label="By signing up, you agree to our Terms and Service and Privacy Policy"
                              checked={form.checkTermAndCondition}
                              onClick={() =>
                                setForm({
                                  ...form,
                                  checkTermAndCondition:
                                    !form.checkTermAndCondition,
                                })
                              }
                            />
                          ))}
                        </Form.Group>
                      </Col>
                    </Row>

                    <CommonButton
                      buttonName="Sign Up"
                      type="fullWidth"
                      loader={loader}
                    />
                    <span className="bottomText">
                      Already have an account?{" "}
                      <Link className="linkText" to="/login">
                        Login
                      </Link>
                    </span>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment >
  );
}
export default SignUp;
