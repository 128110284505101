// ----------------------------------------------------------Auth-------------------------------------------------------------------

const signUp = "/signup";
const login = "/login";
const forgot_password = "/forgot-password";
const change_password = "/change-password";
const update_profile = "/update-profile";
const check_username = "/check-username";
const profile = "/profile";
const generate_pincode = "/generate-passcode";

// ----------------------------------------------------------Pages-------------------------------------------------------------------
//Categories
const addCategories = "/product/add-category";
const view_all_categories = "/product/view-all-categories";
const view_category = "/product/view-category";
const delete_category = "/product/delete-category";
const update_category = "/product/update-category";
const search_category = "/product/search-category";
//Branches
const addBranch = "/admin/add-branch";
const view_all_branches = "/admin/view-branches";
const viewBranch = "/admin/branch";
const delete_branch = "/admin/delete-branch";
const update_branch = "/admin/update-branch";
const search_branch = "/admin/search-branch";
const active_branches = "/admin/active-branches";
//Users
const addUser = "/admin/add-user";
const view_all_users = "/admin/view-users";
const delete_user = "/admin/delete-user";
const view_user = "/admin/view-user";
const update_user = "/admin/update-user";
const search_user = "/admin/search-user";
const allUserAndEmployee = "/admin/all-user";
//Employee
const addEmployee = "/admin/add-employee";
const view_all_employee = "/admin/view-employee";
const delete_employee = "/admin/delete-employee";
const update_employee = "/admin/update-employee";
const view_employee = "/admin/view-employee";
const search_employee = "/admin/search-employee";
//Modifiers
const addModifier = "/product/add-modifier";
const view_all_modifier = "/product/view-all-modifiers";
const view_modifier = "/product/view-modifier";
const delete_modifier = "/product/delete-modifier";
const update_modifier = "/product/update-modifier";
const search_modifier = "/product/search-modifier";
//Products
const addProduct = "/product/add";
const view_all_product = "/product/view";
const update_product = "/product/update";
const delete_product = "/product/delete";
const search_product = "/product/search-product";
const listingProduct = "/product/list-products";
//Table Management
const addTableLayout = "/settings/layout";
const viewLayout = "/settings/view-layout";
const updateLayout = "/settings/update-layout";
//settings
const viewSetting = "/settings/view";
const updateSetting = "/settings/update-settings";
const configureSettings = "/settings/configure-settings";
// const updateSettingPrevious = "/settings/update"; //for temporary use
//Orders
const createOrder = "/order/online-order";
const createOrder_dine_in = "/order/create-order";
const viewOrder = "/order/view-order";
const search_order = "/order/search-order";
const receipt = "/order/view-order-receipt";
//Reports
const viewRecords = "/order/view-reports";
const viewTopOrders = "/order/view-top-orders";
const viewSearchedProductAndCategory = "/order/search-orderby";
//Dashboard
const dashboardSalesGraph = "/order/dashboard-graph";
const viewTopProducts = "/order/top-product";
//Update role api
const updateRoleLevel = "/admin/update-role-level";
//Transactions
const transaction = "/order/transactions";
const keys = "/settings/get-branch-details";
const view_all_modifier_digital = "/product/list-all-modifiers-digital-menu";
const linkCreate = "/admin/create-link";
//Developer
const createOrganization = "/developer/create-organization";
const getAllOrganization = "/developer/get-organization-list";
const activeOrganization = "/developer/active-organization";
const selectOrganization = "/developer/select-organization";
const searchOrganization = "/developer/search-organization";
const getIndividualOrganization = "/developer/organization";
const updateOrganization = "/developer/update-organization";

//Refund
const refundOrder = "/order/update-order";
const splitRefund = "/order/split-order-refund";
//Settlement
const settlePayment = "/settings/settle-payment";
const settlementList = "/settings/settlement";
const getNotificationSound = "/settings/get-notification-sound";

//website builder
const updateWebsiteBuilder = "/settings/update-website-builder";
const getWebsiteTemplate = "/settings/get-website-builder";
const getSubdomainDetail = "/settings/get-subdomain-details";

//dns
const createSubdomain = "/settings/create-domain-assocaited";
const getAllRegistratedDomainDetail = "/settings/get-all-registrated-detail";
const getParticularDomainDetail = "settings/get-particular-domain-detail";
const deleteSubdomain = "/settings/delete-particular-domain-detail";

const rolesAndPermissions = "/admin/view-permissions";
const paymentTypes = "/admin/view-payment-types";
const orderTypes = "/admin/view-order-types";

export const allApiUrl = {
  deleteSubdomain,
  orderTypes,
  paymentTypes,
  rolesAndPermissions,
  createSubdomain,
  getAllRegistratedDomainDetail,
  getParticularDomainDetail,
  receipt,
  createOrder_dine_in,
  getSubdomainDetail,
  updateWebsiteBuilder,
  activeOrganization,
  getWebsiteTemplate,
  splitRefund,
  refundOrder,
  createOrganization,
  getAllOrganization,
  settlePayment,
  settlementList,
  selectOrganization,
  searchOrganization,
  updateOrganization,
  getIndividualOrganization,
  viewOrder,
  transaction,
  viewSetting,
  keys,
  viewTopOrders,
  viewTopProducts,
  view_all_modifier_digital,
  search_order,
  viewSearchedProductAndCategory,
  createOrder,
  viewRecords,
  configureSettings,
  listingProduct,
  linkCreate,
  updateSetting,
  addTableLayout,
  updateRoleLevel,
  viewLayout,
  updateLayout,
  signUp,
  search_product,
  view_all_product,
  delete_product,
  addModifier,
  view_all_modifier,
  update_product,
  addProduct,
  view_modifier,
  delete_modifier,
  update_modifier,
  search_modifier,
  login,
  forgot_password,
  active_branches,
  update_profile,
  search_user,
  change_password,
  search_employee,
  profile,
  search_category,
  check_username,
  addCategories,
  view_all_categories,
  delete_category,
  update_category,
  addBranch,
  view_all_branches,
  viewBranch,
  delete_branch,
  update_branch,
  addUser,
  view_all_users,
  delete_user,
  view_user,
  update_user,
  addEmployee,
  view_all_employee,
  delete_employee,
  update_employee,
  view_employee,
  generate_pincode,
  view_category,
  search_branch,
  allUserAndEmployee,
  dashboardSalesGraph,
  getNotificationSound,
};
